import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { loggedInUserUriState } from "@src/state/auth.state";
import { useRouter } from "next/router";

function useIsAdditionalDepotOnboarding() {
  const router = useRouter();

  const [, loggedInUserUri] = useAsyncSelector(loggedInUserUriState);

  const isAdditionalOnboarding =
    router.query.depotType === "additional" && !!loggedInUserUri;

  return { isAdditionalOnboarding, loggedInUserUri };
}

export default useIsAdditionalDepotOnboarding;
