import { useContext } from "react";
import styled from "styled-components";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import { ThemeContext } from "@pages/_app";

const StyledMenuComponent = (theme: AppTheme) => styled.div<{ open: boolean }>`
  top: 0;
  right: 0;
  height: 150vh;
  position: fixed;
  background-color: ${theme.palette.secondLayerBackgroundColor};
  z-index: 11;

  display: flex;
  flex-direction: row;

  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  width: 100%;
  justify-content: flex-end;

  .wrap-main-logo {
    left: 0;
    position: absolute;
    width: 50%;

    @media (max-width: 562px) {
      width: 45%;
    }

    @media (max-width: 510px) {
      width: 40%;
    }

    @media (max-width: 470px) {
      width: 34%;
    }
  }
`;

export const StyledNav = styled.nav`
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  min-width: 280px;
  z-index: 1000;
  @media (max-width: 433px) {
    padding-top: 60px;
  }
  @media (max-width: 400px) {
    width: 80%;
  }
  @media (max-width: 330px) {
    width: 100%;
  }
`;

export const StyledLinkComponent = (theme: AppTheme) => styled.a`
  padding: 0.8rem 2rem;
  display: block;
  /* font-size: 2rem; */
  color: white;
  border-bottom: 1px solid #999999;
  /* text-decoration: none; */

  :hover {
    color: ${theme.palette.primary};
  }
`;

export const StyledMenu: React.FunctionComponent<any> = props => {
  const { theme } = useContext(ThemeContext);

  const RenderComponent = StyledMenuComponent(theme);

  return <RenderComponent {...props} />;
};

export const StyledLink: React.FunctionComponent<any> = props => {
  const { theme } = useContext(ThemeContext);

  const RenderComponent = StyledLinkComponent(theme);

  return <RenderComponent {...props} />;
};
