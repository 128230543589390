import { Form, notification } from "antd";
import { useEffect } from "react";

import { useApiErrorHandler } from "@hooks/error-handling/useApiErrorHandler";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { assertNotEmpty } from "smavesto.core/lib/utils/typeguards/assertNotEmpty";
import { userUriState } from "@states/auth.state";
import {
  custodyState,
  useRefreshCustodySecondParent
} from "@states/custody.state";
import { bundledLoadingState } from "@utils/recoil/bundledLoadingState";
import type CustodyDto from "smavesto.core/lib/types/dto/user/Custody";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { useRefreshOnboarding } from "@src/state/onboarding.state";
import { profileState } from "@src/state/profile.state";

const useEditCustodyModalForm = () => {
  const [form] = Form.useForm();

  const [handleApiError] = useApiErrorHandler();

  const [userUriLoadingState, userUri] = useAsyncSelector(
    userUriState("activeOnboardingOwner")
  );

  const inviteSecondParentToOnboarding = async () => {
    assertNotEmpty(userUri);

    return smavestoCore.services.underage.inviteSecondParentToOnboarding(
      userUri
    );
  };

  const [custodySecondParentLoadingState, custodySecondParent] =
    useAsyncSelector(custodyState);
  const bundledLoading = bundledLoadingState([
    userUriLoadingState,
    custodySecondParentLoadingState
  ]);

  const isLoading = bundledLoading === "loading";

  const refreshOnboarding = useRefreshOnboarding("currentDepotOwner");

  const [, profile] = useAsyncSelector(profileState("loggedInUser"));

  const refreshCustody = useRefreshCustodySecondParent();

  useEffect(() => {
    form.setFieldsValue({
      ...custodySecondParent
    });
  }, [custodySecondParent, form]);

  const onFinish = async (values: any): Promise<boolean> => {
    if (!userUri) throw new Error("Illegal State: userUri ist required.");

    if (
      profile?.email?.toLowerCase() === values.parentUser2Email.toLowerCase()
    ) {
      form.setFields([
        {
          name: ["parentUser2Email"],
          errors: [
            "Bitte geben Sie die E-Mailadresse vom weiteren gesetzlichen Vertreter an."
          ]
        }
      ]);
      return false;
    }

    if (!values.zustimmenGesetzlicheVertreter) {
      notification.warning({
        message: `Bitte bestätigen Sie, dass Sie den weiteren gesetzlichen Vertreter über die hier von Ihnen über ihn angegebene Daten informiert haben und er damit einverstanden ist im Anschluss eine E-Mail von Smavesto zu erhalten.
      `,
        placement: "top",
        duration: 10
      });
      return false;
    }

    const custodyBody: CustodyDto = {
      custody: "JOINT_CUSTODY",
      parentUser2GenderType: values.parentUser2GenderType,
      parentUser2FirstName: values.parentUser2FirstName,
      parentUser2LastName: values.parentUser2LastName,
      parentUser2Email: values.parentUser2Email
    };

    const updateCustodyResult = await smavestoCore.services.user.updateCustody(
      userUri,
      custodyBody
    );

    if (isApiErrorResponse(updateCustodyResult)) {
      handleApiError(updateCustodyResult, "modal", "error");
      return false;
    }

    const inviteSecondParentResult = await inviteSecondParentToOnboarding();

    if (isApiErrorResponse(inviteSecondParentResult)) {
      handleApiError(inviteSecondParentResult, "modal", "error");
      return false;
    }
    notification.success({
      message: "Die E-Mail wurde erfolgreich gesendet."
    });

    refreshCustody();
    refreshOnboarding();

    return true;
  };

  const onReset = () => {
    form.resetFields();
  };

  return {
    form,
    onFinish,
    onReset,
    isLoading
  };
};

export default useEditCustodyModalForm;
