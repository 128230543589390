import { ThemeContext } from "@pages/_app";
import React, { useContext } from "react";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import type { AppThemeSetting } from "smavesto.core/lib/static/themes/AppThemeSetting";
import styled from "styled-components";

const StyledEntityPanelComponent = (
  theme: AppTheme,
  appModeTheme?: AppThemeSetting
) => styled.div`
  position: relative;
  border: 2px solid ${theme.palette.primary};
  padding: 1rem;
  background: ${appModeTheme?.secondLayerBackgroundColor ||
  theme.palette.secondLayerBackgroundColor};
  border-radius: 20px;
  width: 400px;

  .value-top {
    font-size: 0.8em;
    display: block;
    line-height: 1em;
    color: ${theme.palette.primary};
  }

  .value {
    color: ${theme.palette.primary};
    font-size: 1.4em;
    font-weight: bold;
  }

  .unit {
    font-size: 0.6em;
  }

  .label {
    font-weight: 500;
  }

  h2 {
    margin: 0;
    font-size: 1.4em;
    line-height: 1.2em;
  }

  .icon-button {
    position: absolute;
    font-size: 48px;
    right: 10px;
    bottom: 10px;
    height: 60px;
    width: 60px;
    display: block;
    transition: opacity 0.4s;
  }

  .icon-button:hover {
    opacity: 0.8;
  }
`;

export const StyledEntityPanel: React.FunctionComponent<any> = props => {
  const { appModeTheme, theme } = useContext(ThemeContext);

  const RenderComponent = StyledEntityPanelComponent(theme, appModeTheme);

  return <RenderComponent {...props} />;
};
