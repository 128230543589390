import { useState } from "react";

/* This hook can be used to pass a method and monitor its loading status */
const useLoadingState = (): {
  isLoading: boolean;
  performMethod: (method: () => Promise<void>) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const performMethod = async (method: () => Promise<void>) => {
    setIsLoading(true);

    await method();

    setIsLoading(false);
  };

  return { isLoading, performMethod };
};

export default useLoadingState;
