import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useContext } from "react";

import { ThemeContext } from "@pages/_app";
import type { ICompBaseProps } from "@src/interfaces/jsx.interface";

interface SimpleMenuButtonProps extends ICompBaseProps {
  title: string;
  order: string;
  complete?: boolean;
  disabled?: boolean;
  hideOrderCircle?: boolean;
  size?: "small" | "large";
}

export const SimpleMenuButton: React.FC<SimpleMenuButtonProps> = ({
  complete,
  disabled,
  order,
  title,
  hideOrderCircle,
  size,
  ...props
}) => {
  const { theme } = useContext(ThemeContext);

  const buttonStyles = hideOrderCircle
    ? {
        borderWidth: 4,
        borderColor: "#002d2f",
        ...props.style
      }
    : undefined;

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        minWidth: size === "large" ? 260 : 170,
        height: size === "large" ? 58 : 38,
        opacity: disabled ? 0.8 : 1,
        width: "fit-content"
      }}
    >
      {!hideOrderCircle && (
        <Button
          icon={
            complete ? (
              <CheckOutlined
                style={{ color: theme.palette.primaryTextColor }}
              />
            ) : null
          }
          type="primary"
          shape="circle"
          style={{
            height: size === "large" ? 58 : 38,
            width: size === "large" ? 58 : 38,
            position: "absolute",
            left: 0,
            zIndex: 2,
            fontWeight: "bold",
            borderWidth: 4,
            color: theme.palette.primaryTextColor,
            background: theme.palette.primary,
            borderColor: "#002d2f",
            ...props.style
          }}
          disabled={disabled}
        >
          {!complete && order}
        </Button>
      )}
      <Button
        type="primary"
        style={{
          borderRadius: 19,
          width: size === "large" ? (hideOrderCircle ? 220 : 260) : "100%",
          textAlign: "start",
          color: theme.palette.primaryTextColor,
          fontWeight: "bold",
          position: "absolute",
          height: size === "large" ? 48 : 28,
          background: theme.palette.primary,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          left: hideOrderCircle ? 50 : 10,
          top: 4,
          fontSize: size === "large" ? 13 : 8,
          paddingLeft: size === "large" ? (hideOrderCircle ? 20 : 55) : 38,
          boxShadow: "3px 3px 3px rgba(0,0,0,0.2)",
          ...buttonStyles
        }}
        disabled={disabled}
      >
        <span style={{ whiteSpace: "nowrap" }}>{title}</span>
      </Button>
    </div>
  );
};
