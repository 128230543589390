import { Col, Row } from "antd";
import type { Gutter } from "antd/lib/grid/row";
import type { FC, ReactNode } from "react";

type Props = {
  gutter?: Gutter | [Gutter, Gutter];
  justify?: "start" | "end" | "center" | "space-around" | "space-between";
  spans: { md: number; xs?: number }[];
  children: ReactNode[];
};

export const FormGroup: FC<Props> = ({
  gutter = { xs: 8, sm: 16, md: 24, lg: 32 },
  justify,
  spans,
  children
}) => (
  <Row gutter={gutter} justify={justify}>
    {children.map((child, i) => (
      <Col md={{ span: spans[i]?.md }} xs={{ span: spans[i]?.xs ?? 24 }}>
        {child}
      </Col>
    ))}
  </Row>
);
