import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import { lightModeState, localLightModeState } from "@src/state/all.states";
import { loggedInUserUriState } from "@src/state/auth.state";
import { setCookie } from "@src/utils/cookies";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { Switch } from "antd";
import { useContext } from "react";
import { useSetRecoilState } from "recoil";
import LightModeIcon from "../custom-icons/LightModeIcon";
import MoonIcon from "../custom-icons/MoonIcon";

export const LightModeSwitch: React.FunctionComponent = () => {
  const [, value] = useAsyncSelector(lightModeState);

  const setLocalLightMode = useSetRecoilState(localLightModeState);

  const { theme } = useContext(ThemeContext);

  const hasLightMode = theme.lightMode?.colorSchema === "light";

  const [, userUri] = useAsyncSelector(loggedInUserUriState);

  const onChangeLightMode = async (checked: boolean) => {
    setLocalLightMode(checked);

    /* save lightmode option as cookie */
    setCookie("lightmode", checked ? "on" : "off", 365);

    /* save lightmode option as keyvalue */

    if (userUri)
      await smavestoCore.services.keyValueStore.setValue(
        userUri,
        "lightmode",
        checked ? "on" : "off"
      );
  };

  if (!hasLightMode) return undefined;

  return (
    <Switch
      checkedChildren={<LightModeIcon fill="white" />}
      unCheckedChildren={<MoonIcon />}
      checked={value}
      onChange={onChangeLightMode}
      style={{ marginRight: "1rem" }}
    />
  );
};

export default LightModeSwitch;
