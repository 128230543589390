import { selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { userUriState } from "@states/auth.state";
import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import { getRequestState, useRefreshState } from "./request.state";
import { depotForUserUriState } from "./depot.state";

export const cancellationDateState = selectorFamily({
  key: "cancellationDate",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("cancellationDate", userUri));

      if (!userUri) return undefined;

      return get(cancellationDateStateForUserUri(userUri));
    }
});

export const cancellationDateStateForUserUri = selectorFamily({
  key: "cancellationDateStateForUserUri",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("cancellationDate", userUri));

      const depotForUserUri = get(depotForUserUriState(userUri));

      const cancellationDateResponse =
        await smavestoCore.services.user.getCancellationDate(userUri);

      if (isApiErrorResponse(cancellationDateResponse)) {
        return undefined;
      }

      return cancellationDateResponse || depotForUserUri?.cancellationDate;
    }
});

export const useRefreshCancellationDateInfo = (type: StateEnvironmentUser) =>
  useRefreshState("cancellationDate", type);
