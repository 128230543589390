import { Collapse } from "antd";
import styled from "styled-components";

import theme from "@styles/theme";

export const SCloseButton = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  span {
    color: ${theme.primary};
    transition: all 0.3s linear;
    transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
  }
`;

export const SInfoCollapseBox = styled(Collapse)`
  margin: 28px 0;
  border: 2px solid ${theme.primary};
  border-radius: 35px;
  color: ${theme.primary};
  padding: 10px 5px;
  position: relative;
  background: transparent;

  .header {
    display: flex;

    .icon {
      width: 40px;
      height: 40px;
    }
    .box-title {
      color: ${theme.primary};
      text-align: center;
      font-size: 18px;
      margin: auto;
      margin-bottom: 1rem;
    }
  }

  .content {
    color: ${theme.primary};
    text-align: center;
    font-size: 14px;

    a {
      font-size: 14px;
      text-decoration: underline;
    }

    a:hover {
      color: inherit;
    }
  }

  && .ant-collapse-header {
    padding: 4px 12px;
  }

  && .ant-collapse-header-text {
    width: 100%;
  }

  && .ant-collapse-content {
    background-color: transparent;
    border-top: none;

    .ant-collapse-content-box {
      padding: 8px;
      padding-top: 0px;
    }
  }

  && .ant-collapse-item {
    border-bottom: none;
  }
`;
