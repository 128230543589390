import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";
import OuterHint from "./OuterHint";

interface WaitingForFirstTransactionDepotMessageProps {
  isDebitOrder: boolean;
  isJuniorDepot: boolean;
  transactionAmount: number;
}

const WaitingForFirstTransactionDepotMessage: React.FunctionComponent<
  WaitingForFirstTransactionDepotMessageProps
> = ({ isDebitOrder, isJuniorDepot, transactionAmount }) => {
  if (isDebitOrder)
    return (
      <OuterHint title="Depothinweis">
        Wir veranlassen die Lastschrift {isJuniorDepot ? "der" : "Ihrer"}{" "}
        gewünschten Starteinzahlung in Höhe von{" "}
        {parseNumberToCurrencyString(transactionAmount)} für Sie. Sie brauchen
        sich um nichts mehr zu kümmern.
      </OuterHint>
    );

  return (
    <OuterHint title="Depothinweis">
      Damit Sie schnellstmöglich mit {isJuniorDepot ? "dem" : "Ihrem"} Depot
      durchstarten können, vergessen Sie bitte nicht,{" "}
      {isJuniorDepot ? "den" : "Ihren"} Anlagebetrag zu überweisen.
      <br />
      Die nötigen Überweisungsinformationen finden Sie im Bereich Dokumente. Es
      kann einige Tage dauern, bis der eingezahlte Betrag im Depot angezeigt
      wird.
    </OuterHint>
  );
};

export default WaitingForFirstTransactionDepotMessage;
