import { ThemeContext } from "@pages/_app";
import { SActionButtonsWrapper } from "@src/styled-components/form/SButtons";

import { Button, Modal } from "antd";
import { useContext, useState } from "react";

export type CustomModalAction = {
  label: string;
  action: () => void;
  type: "reset" | "submit";
};

type CustomModalContent = {
  title: string;
  content: string;
  actions: CustomModalAction[];
};

function useCustomModal() {
  const { theme } = useContext(ThemeContext);

  const [modalContent, setModalContent] = useState<CustomModalContent>();

  const contextHolder = modalContent ? (
    <Modal
      width={900}
      visible
      closable
      title={modalContent.title}
      footer={
        <SActionButtonsWrapper>
          {modalContent.actions.map(action => (
            <Button
              size="small"
              type={action.type === "submit" ? "primary" : "default"}
              style={{
                height: 40,
                color:
                  action.type === "submit"
                    ? theme.palette.primaryTextColor
                    : "black"
              }}
              onClick={action.action}
            >
              {action.label}
            </Button>
          ))}
        </SActionButtonsWrapper>
      }
      onCancel={() => setModalContent(undefined)}
    >
      {modalContent.content}
    </Modal>
  ) : null;

  function openModal(modalContent: CustomModalContent) {
    setModalContent(modalContent);
  }

  return { contextHolder, openModal, theme };
}

export default useCustomModal;
