import { ThemeContext } from "@src/pages/_app";
import { Col, Row } from "antd";
import { useContext } from "react";

interface LockedDepotMessage {
  icon: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
}

export const LockedDepotMessage: React.FunctionComponent<
  LockedDepotMessage
> = ({ icon, children, actions }) => {
  const { appModeTheme } = useContext(ThemeContext);

  return (
    <div
      style={{
        maxWidth: 650,
        padding: "1rem",
        margin: "1rem auto",
        display: "flex",
        alignItems: "center",
        background: appModeTheme?.modalBackgroundColor,
        color: appModeTheme?.secondLayerTextColor
      }}
    >
      <Row>
        <Col md={6} xs={24}>
          <div style={{ margin: "1rem" }}>{icon}</div>
        </Col>
        <Col md={18} xs={24}>
          <div style={{ margin: "1rem" }}>
            {children}
            {actions}
          </div>
        </Col>
      </Row>
    </div>
  );
};
