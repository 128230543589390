import { ThemeContext } from "@src/pages/_app";
import { isArray } from "lodash";
import { useContext } from "react";

interface SesondLayerWrapperProps {
  disabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  paddingTop?: boolean;
}

const SesondLayerWrapper: React.FunctionComponent<SesondLayerWrapperProps> = ({
  children,
  disabled,
  paddingTop
}) => {
  const { appModeTheme, theme } = useContext(ThemeContext);

  if (disabled || !children || (isArray(children) && children.length === 0))
    return children;

  return (
    <div
      className={
        appModeTheme?.colorSchema === "light"
          ? "lightmode-active"
          : "darkmode-active"
      }
      style={{
        transition: "none !important",
        borderRadius: 30,
        background: appModeTheme?.secondLayerBaseBackgroundColor,
        color: appModeTheme?.secondLayerTextColor,
        paddingTop: paddingTop ? 20 : undefined,
        paddingBottom: paddingTop ? 20 : undefined,
        colorScheme: appModeTheme?.colorSchema
      }}
    >
      {children}
    </div>
  );
};

export default SesondLayerWrapper;
