import type { ILayoutBaseProps } from "@src/interfaces/jsx.interface";

import { useSecureLayout } from "./useSecureLayout";

export interface SecureLayoutProps extends ILayoutBaseProps {
  disableHeader?: boolean;
  disableFooter?: boolean;
  hideDepotNavigation?: boolean;
}

const NoLayout: React.FunctionComponent<SecureLayoutProps> = props => {
  const [accessToDashboard] = useSecureLayout();

  if (!accessToDashboard) return null;

  return (
    <props.mainComp router={props.routeProps} pageProps={props.pageProps} />
  );
};

export default NoLayout;
