const firebaseConfig = {
  apiKey: "AIzaSyBdfd62Ab_KIbay46Fqx7E0VuYt1vOI4fM",
  authDomain: "smavesto-728b0.firebaseapp.com",
  projectId: "smavesto-728b0",
  storageBucket: "smavesto-728b0.appspot.com",
  messagingSenderId: "282671671415",
  appId: "1:282671671415:web:44cbfab0f6e5ba78c0fe72"
};

export default firebaseConfig;
