/* eslint-disable react/no-danger */
import { Checkbox, Col, Row } from "antd";
import React from "react";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";

import PopoverInfo from "../../popover-info/PopoverInfo";
import { StyledCheckbox } from "./Checkbox.styled";

interface IProps extends ICompBaseProps {
  title?: string;
  addition?: string;
  required?: boolean;
  value?: any;
  onChange?: any;
  option: any;
  info?: any;
}

const CheckboxComponent: React.FC<IProps> = props => {
  const { title, required, option, addition } = props;

  return (
    <StyledCheckbox className="component-wrapper">
      <h3 className="title">
        {`${title || ""} `}
        {title && required ? " * " : ""}
      </h3>
      <Row key={option.id} className="checkbox">
        <Col span={24}>
          {option.info ? (
            <div className="wrap-checkbox-option">
              <Checkbox {...props}>
                <div className="option-label">
                  {option.label} {!title && required ? " *" : ""}
                </div>
              </Checkbox>
              <div className="wrap-icon-info">
                <PopoverInfo text={option.info} title={option.label} />
              </div>
            </div>
          ) : (
            <Checkbox {...props}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${option.label} ${!title && required ? " *" : ""}`
                }}
              />
            </Checkbox>
          )}
        </Col>
      </Row>
      <div className="addition">{addition}</div>
    </StyledCheckbox>
  );
};

export default CheckboxComponent;
