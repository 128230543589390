import { useContext } from "react";

import { ThemeContext } from "@pages/_app";
import Tips from "../../../assets/tips-icon_svg.svg";
import InfoCollapseBox from "../dashboard/InfoCollapseBox";

interface OuterHintProps {
  children: React.ReactNode;
  title: string;
}

const OuterHint: React.FunctionComponent<OuterHintProps> = ({
  children,
  title
}) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  return (
    <div
      style={{
        border: `2px solid ${theme.palette.primary}`,
        borderRadius: 35,
        padding: "20px 25px",
        margin: "1rem",
        background: appModeTheme?.secondLayerBaseBackgroundColor,
        color: appModeTheme?.secondLayerTextColor
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginBottom: 10, marginRight: "1rem" }}>
          <Tips fill={theme.palette.primary} height={40} alt="icon-tips" />
        </div>
        <div style={{ fontSize: 18, fontWeight: 400 }}>{title}</div>
      </div>
      <div className="content" style={{ maxWidth: 600, margin: "auto" }}>
        {children}
      </div>
    </div>
  );

  return (
    <InfoCollapseBox style={{ margin: "1rem auto" }} title={title}>
      {children}
    </InfoCollapseBox>
  );
};

export default OuterHint;
