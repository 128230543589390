import { useRouter } from "next/router";

function useIsOnboarding(): boolean {
  const router = useRouter();

  return (
    router.pathname.indexOf("registration") > -1 ||
    router.pathname.indexOf("onboarding") > -1
  );
}

export default useIsOnboarding;
