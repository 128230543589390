import { ThemeContext } from "@src/pages/_app";
import { openIndependentInfoModal } from "@src/theme/modal/openThemedModal";
import { setCookie } from "@utils/cookies";
import { Button, Form, Input } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { hashSync } from "bcrypt-ts";
import { useRouter } from "next/router";
import { useContext } from "react";

interface TestUserAuthenticationFormProps {
  username: string;
  password: string;
}

const TestUserAuthenticationForm: React.FunctionComponent<
  TestUserAuthenticationFormProps
> = ({ username, password }) => {
  const router = useRouter();
  const { theme } = useContext(ThemeContext);

  const performLogin = (formValues: any) => {
    if (formValues.username === username && formValues.password === password) {
      setCookie(
        "testuser.authentification",
        hashSync(`${username}:${password}`),
        365
      );
      router.push("/login");
    } else {
      openIndependentInfoModal(
        {
          title: "Fehler",
          content: "Die eingegebenen Zugangsdaten sind nicht korrekt.",
          cancelText: "OK"
        },
        theme
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <div
        style={{
          width: 400,
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
          background: "#002021",
          border: "1px solid white",
          color: "white"
        }}
      >
        <div style={{ marginBottom: "2rem" }}>
          Diese Seite ist nicht öffentlich zugänglich.
        </div>

        <div
          style={{
            width: 300,
            display: "flex",
            flexDirection: "column",
            margin: "auto"
          }}
        >
          <Form onFinish={performLogin}>
            <FormItem name="username">
              <Input name="username" placeholder="Benutzername" />
            </FormItem>
            <FormItem name="password">
              <Input name="password" type="password" placeholder="Passwort" />
            </FormItem>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Anmelden
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TestUserAuthenticationForm;
