import { atom, selector } from "recoil";

import { expiresState, loggedInUserUriState } from "./auth.state";
import { bankDetailsState } from "./bank-details.state";
import {
  currentDepotInvestmentSettingState,
  currentDepotOnboardingState,
  currentDepotProfileState,
  currentDepotRiskYieldState
} from "./current.depot.state";
import {
  activeOnboardingDepotState,
  activeOnboardingState,
  depotsState
} from "./depot.state";
import { financialPositionState } from "./financial-position.state";
import { giftCodeActivationState, giftCodeNotUnset } from "./giftcode.state";
import { knowledgeState } from "./knowledge.state";

export type AppModeSerializeType = "real" | "underage" | "test";

export const appModeState = atom<AppModeSerializeType>({
  key: "appMode",
  default: "real"
});

export const offlineState = atom({
  key: "offline",
  default: false
});

export const loadingState = atom({
  key: "loading",
  default: false
});

export const userGlobalState = selector({
  key: "userState",
  get: ({ get }) => {
    const offline = get(offlineState);

    const loading = get(loadingState);

    const userUri = get(loggedInUserUriState);

    const expires = get(expiresState);

    const activeOnboarding = get(activeOnboardingState);

    const activeOnboardingDepot = get(activeOnboardingDepotState);

    const riskYield = get(currentDepotRiskYieldState);

    const giftcode = get(giftCodeNotUnset("currentDepotOwner"));

    const gitcodeActivation = get(giftCodeActivationState("currentDepotOwner"));

    const investmentSettings = get(currentDepotInvestmentSettingState);

    const profile = get(currentDepotProfileState);

    const financialPosition = get(financialPositionState("loggedInUser"));

    const knowledge = get(knowledgeState("loggedInUser"));

    const bankDetails = get(bankDetailsState("loggedInUser"));

    const depots = get(depotsState);

    const onboarding = get(currentDepotOnboardingState);

    return {
      global: { offline, loading },
      onboarding,
      user: {
        activeOnboarding: {
          onboardingData: activeOnboarding,
          depot: activeOnboardingDepot
        },
        auth: { userUri, expires },
        riskYield,
        investmentSettings,
        profile,
        financialPosition,
        knowledge,
        bankDetails,
        depots,
        giftcode: {
          code: giftcode,
          activation: gitcodeActivation
        }
      }
    };
  }
});
