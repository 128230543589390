import { useRouter } from "next/router";
import { useEffect } from "react";

import useThemedModal from "@src/theme/hook/useThemedModal";
import { globalErrorState } from "@states/common.state";
import { Tag } from "antd";
import { useRecoilState } from "recoil";

export const ErrorInfoModal = () => {
  const { contextHolder, openModal } = useThemedModal(false);
  const { push } = useRouter();

  const [globalError, setGlobalError] = useRecoilState(globalErrorState);

  const userHelpText = "Bitte probieren Sie es erneut.";

  const isWebappDev = process.env.NEXT_PUBLIC_USE_DEBUG === "true";

  useEffect(() => {
    if (globalError) {
      openModal("info", {
        okText: "Schließen",
        onOk: () => setGlobalError(undefined),
        title: globalError.title ?? "Es ist ein Fehler aufgetreten.",
        width: 600,
        content: (
          <div>
            <br />
            <p>
              <strong>{globalError.bodyText ?? userHelpText}</strong>
            </p>

            {globalError.sentryErrorCode && (
              <p>
                <small>Referenz: {globalError.sentryErrorCode}</small>
              </p>
            )}

            {isWebappDev &&
              !!globalError.apiErrorResponse &&
              globalError.apiErrorResponse.data === "Unexpected Error!" && (
                <>
                  <Tag>Info für Beta-Tester (Produktiv nicht sichtbar)</Tag>
                  <br />
                  <br />
                  Das Backend hat den Fehler Unexpected Error! zurückgegeben.
                  Dabei handelt es sich um einen unbehandelten Fehler im
                  Backend. Für die Behebung dieses Fehlers ist die DSER
                  verantwortlich.
                </>
              )}
          </div>
        )
      });
    }
  }, [globalError, setGlobalError, push]);

  return contextHolder;
};
