import type { SelectProps } from "antd";
import { Checkbox, DatePicker, Form, Radio, Select, Slider } from "antd";
import styled from "styled-components";

import { ThemeContext } from "@src/pages/_app";
import theme from "@styles/theme";
import type { FC } from "react";
import { useContext } from "react";
import type { AppTheme } from "smavesto.core";

export const SFormItem = styled(Form.Item)`
  margin: 15px 0;

  && .ant-form-item-label {
    max-width: 100%;

    label {
      flex-wrap: wrap;
      color: inherit;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .ant-form-item-control-input-content {
  }

  .ant-form-item-control {
    width: 100%;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
`;

export const SFormListItemWrapper = styled.div`
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;

  && .minus-icon {
    font-size: 30px;
    color: ${theme.primary};
    margin: auto;
    margin-top: 60px;
    margin-left: 20px;
  }
`;

export const SRadio = styled(Radio)`
  color: inherit;

  && span {
    font-size: 16px;
  }

  .ant-radio-disabled + span {
    color: #bbc1c3a6;
    cursor: not-allowed;
  }

  .ant-radio-disabled .ant-radio-inner:after {
    background: black;
  }

  && .ant-radio-inner {
    background-color: transparent !important;
  }
`;

export const StyledPaginationSelect = styled(Select)`
  &&.ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  && .ant-select-arrow {
    color: black;
    cursor: pointer;
  }
`;

const StyledSelect = styled(Select)`
  display: flex;
  align-items: center;
  height: 58px;

  border-radius: 50px;

  .ant-select-selector {
    padding: 0 25px !important;
  }

  &&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
    border: none !important;
  }

  &&.ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  && .ant-select-arrow {
    color: ${theme.primary};
    transform: scale(1.5);
    margin-right: 12px;
    cursor: pointer;
  }

  && .ant-select-selection-item {
    color: inherit;
    white-space: break-spaces;
  }
`;

export const SSelect = (props: any) => {
  const { inputCssProperties } = useContext(ThemeContext);

  return (
    <StyledSelect<FC<SelectProps>> style={inputCssProperties} {...props} />
  );
};

export const SCheckboxComponent = (theme: AppTheme) => styled(Checkbox)`
  color: inherit;

  .ant-checkbox-inner:after {
    border-color: ${theme.palette.primaryTextColor};
  }

  span:last-child {
    font-size: 16px;
    color: inherit;
  }
`;

export const SCheckbox = (props: any) => {
  const { theme } = useContext(ThemeContext);

  const RenderComponent = SCheckboxComponent(theme);

  return <RenderComponent {...props} />;
};

export const SDatePicker = styled(DatePicker)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 58px;
  padding-left: 25px;
  border: none;
  box-shadow: none;
  border-radius: 50px;

  &&.ant-picker-status-error {
    background-color: transparent !important;
    border-color: transparent !important;
    border: none;
    box-shadow: none;
  }

  && .ant-picker-input {
    input {
      color: inherit;
    }
  }

  && span {
    background: transparent;
    color: inherit;
  }
`;

export const SSlider = styled(Slider)<{
  margin?: string;
  markTextWidth?: string;
}>`
  color: inherit;
  max-width: 100%;
  box-sizing: border-box;

  && {
    margin: ${({ margin = "10px 38px 28px 38px" }) => margin};
  }

  && .ant-slider-handle {
    border-width: 1px;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    border-color: ${theme.primary};
  }

  && .ant-slider-dot {
    top: 0px;
    border: none;
    width: 1px;
  }

  && .ant-slider-dot-active {
    background: ${theme.primary};
  }

  && .ant-slider-rail {
    height: 2px;
    background: rgba(120, 120, 120, 1);
  }

  && .ant-slider-track {
    height: 2px;
    background-color: ${theme.primary};
  }

  .ant-slider-mark {
    height: 30px;
    width: 100%;
  }

  @media (max-width: 965px) {
    .ant-slider-mark {
      overflow: hidden;
    }
  }

  && .ant-slider-mark-text {
    min-width: ${({ markTextWidth = "100px" }) => markTextWidth};
    color: inherit;

    @media (max-width: 840px) {
      font-size: 13px;
    }

    @media (max-width: 810px) {
      font-size: 12px;
    }

    @media (max-width: 760px) {
      font-size: 11px;
    }

    @media (max-width: 680px) {
      font-size: 10px;
    }

    @media (max-width: 610px) {
      font-size: 8px;
    }
  }
`;
