import {
  collection,
  getDocs,
  getFirestore,
  query,
  where
} from "firebase/firestore/lite";
import moment from "moment";
import fireBaseApp from "./firebase.app";
import smavestoCore from "./SmavestoCoreClient";

export type PlannedMaintenance = {
  startTimestamp: number;
  endTimestamp: number;
  platform?: ("web" | "app")[];
  envType: "prod" | "staging" | "development";
  isActive: boolean;
  reason: string;
};

export class FirebaseService {
  /* Loads all planned maintenance modes in the future from firebase */
  async getPlannedMaintenaceModes(): Promise<PlannedMaintenance[]> {
    const currentTimestamp = moment().unix();
    const database = getFirestore(fireBaseApp);
    const maintenanceStore = collection(database, "maintenance");

    const q = query(
      maintenanceStore,
      where("endTimestamp", ">=", currentTimestamp)
    );

    const querySnapshot = await getDocs(q);

    const plannedItems: PlannedMaintenance[] = [];

    /* Load all maintenance objects */
    querySnapshot.forEach(doc => {
      const docItem = doc.data() as PlannedMaintenance;
      plannedItems.push(docItem);
    });

    /* Filter all active elements for the current environment type */
    const ret = plannedItems.filter(
      item =>
        item.isActive &&
        item.envType === this.fireBaseEnvironmentDescription &&
        (!item.platform || item.platform?.includes(this.fireBaseInstanceName))
    );

    smavestoCore.debugLog(
      "info",
      `found ${ret.length} planned maintenance modes for this instance.`
    );

    return ret;
  }

  get fireBaseEnvironmentDescription(): "prod" | "staging" | "development" {
    if (smavestoCore.environment === "ap") return "prod";
    if (smavestoCore.environment === "ad") return "development";
    if (smavestoCore.environment === "as") return "staging";
    throw new Error("Unknown clientID");
  }

  get fireBaseInstanceName(): "web" {
    return "web";
  }
}
export const fireBaseService = new FirebaseService();
