import { Select } from "antd";
import { useRecoilState } from "recoil";

import { currentDepotOnboardingState } from "@states/current.depot.state";
import {
  currentMassOrderIDState,
  massOrdersState
} from "@states/simulate.state";
import { useAsyncSelector } from "@hooks/useAsyncSelector";

export function MassOrderSelect() {
  const [, onboarding] = useAsyncSelector(currentDepotOnboardingState);

  const [, massOrders] = useAsyncSelector(massOrdersState);

  const [currentMassOrderID, setCurrentMassOrderID] = useRecoilState(
    currentMassOrderIDState
  );

  const onMassOrderIDChange = (value: string) => setCurrentMassOrderID(value);

  return (
    <Select
      onChange={onMassOrderIDChange}
      style={{ width: "100%", marginBottom: 12 }}
      disabled={!onboarding?.liveDepot}
      value={currentMassOrderID}
    >
      {massOrders?.map(m => (
        <Select.Option key={m.id} value={m.id}>
          {`${m.process}  (${m.status})`}
        </Select.Option>
      ))}
    </Select>
  );
}
