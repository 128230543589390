import type { MaintenaceModeDetails } from "@src/utils/maintenance/getMaintenanceModeDetails";
import { getMaintenanceModeDetails } from "@src/utils/maintenance/getMaintenanceModeDetails";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const useIsMaintenanceMode = (
  returnToDashBoardWhenOver?: boolean
): { maintenanceModeDetails: MaintenaceModeDetails | undefined } => {
  const [maintenanceModeDetails, setMaintenanceModeDetails] = useState<
    MaintenaceModeDetails | undefined
  >(undefined);

  const router = useRouter();

  useEffect(() => {
    getMaintenanceModeDetails().then(details => processDetails(details));
  }, []);

  const processDetails = (details: MaintenaceModeDetails) => {
    if (details.isCurrentlyActive === true) {
      router.push("/maintenance");
    } else if (
      returnToDashBoardWhenOver &&
      details.isCurrentlyActive === false
    ) {
      router.push("/dashboard/depot");
    } else if (details.isCurrentlyActive === false) {
      setMaintenanceModeDetails(details);
    } else {
      // still loading
    }
  };

  return { maintenanceModeDetails };
};

export default useIsMaintenanceMode;
