import { atom, selector } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { getAppThemeForSupplierId } from "smavesto.core/lib/business-logic/supplier/getThemeForSupplierID";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";
import { userUriState } from "./auth.state";
import { currentDepotState } from "./current.depot.state";
import { getRequestState } from "./request.state";
import { customerSupplierFromOriginOrConfigSelector } from "./supplier.state";
import { activeOnboardingDepotState } from "./depot.state";

export const cachedThemeState = atom<AppTheme>({
  key: "cachedThemeState",
  default: defaultTheme
});

export const themeCurrentDepotSelector = selector({
  key: "themeCurrentDepotSelector",
  get: async ({ get }) => {
    get(getRequestState("theme"));

    const currentDepot = get(currentDepotState);

    if (!currentDepot) return undefined;

    const userUri = get(userUriState("currentDepotOwner"));

    if (!userUri) return defaultTheme;

    const supplierData = await get(
      customerSupplierFromOriginOrConfigSelector("currentDepotOwner")
    );

    return supplierData?.supplierID
      ? getAppThemeForSupplierId(
          supplierData?.supplierID,
          smavestoCore,
          currentDepot
        )
      : defaultTheme;
  }
});

export const themeOnboardingOwnerSelector = selector({
  key: "themeOnboardingOwnerSelector",
  get: async ({ get }) => {
    get(getRequestState("theme"));

    const currentDepot = get(activeOnboardingDepotState);

    if (!currentDepot) return undefined;

    const userUri = get(userUriState("activeOnboardingOwner"));

    if (!userUri) return defaultTheme;

    const supplierData = await get(
      customerSupplierFromOriginOrConfigSelector("activeOnboardingOwner")
    );

    return supplierData?.supplierID
      ? getAppThemeForSupplierId(
          supplierData?.supplierID,
          smavestoCore,
          currentDepot
        )
      : defaultTheme;
  }
});
