import { atom } from "recoil";

export const receivePreContractualInfoState = atom<boolean | void>({
  key: "ReceivePreContractualInfo",
  default: undefined
});

export const receiveChildPreContractualInfoState = atom<boolean | void>({
  key: "ReceiveChildPreContractualInfo",
  default: undefined
});

export const summaryHistoryState = atom<string>({
  key: "SummaryHistoryState",
  default: ""
});
