export const CANCELLATION_INFO = {
  title: `Sie haben Ihr Konto bereits am __date__ gekündigt.`,
  text: "Ihre Kündigung ist eingegangen und wird nun bearbeitet.<br />Der Verkauf Ihrer Wertpapiere und die Überweisung auf Ihr Referenzkonto erfolgt in Kürze.",
  tooltipTitle: "Ihr Konto ist geschlossen!"
};

export const CANCELLATION_INFO_UNDERAGE = {
  title: `Sie haben das Junior-Depot von __firstname__ __lastname__ bereits am __date__ gekündigt.`,
  text: "Die Kündigung ist eingegangen und wird nun bearbeitet.<br />Der Verkauf der Wertpapiere Ihres Kindes und die Überweisung auf das Referenzkonto erfolgt in Kürze.",
  tooltipTitle: "Ihr Konto ist geschlossen!"
};
