import type DepotDto from "smavesto.core/lib/types/dto/depot/DepotDto";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import isTestDepot from "smavesto.core/lib/utils/typeguards/isTestDepot";

export default function getTypeLabelForDepot(depot: DepotDto) {
  if (isJuniorDepot(depot)) return `Juniordepot`;

  if (isTestDepot(depot)) return "Ihr Depot";

  return `Ihr Depot`;
}
