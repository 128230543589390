import { Button, Col, Row } from "antd";
import { RiRefreshLine } from "react-icons/ri";

import * as Sentry from "@sentry/react";

import { LoginOutlined, MailFilled, WarningTwoTone } from "@ant-design/icons";
import { useSafeLogout } from "@src/page-components/onboarding/hooks";
import { SDashboardCol } from "@src/styled-components/SDashboardCol";
import { SButton } from "@src/styled-components/form/SButtons";
import theme from "@src/styles/theme";
import moment from "moment";
import type { ReactNode } from "react";
import { useState } from "react";
import { useErrorBoundary, withErrorBoundary } from "react-use-error-boundary";
import styles from "./styles.module.less";

type ErrorBoundaryProps = { err?: Error | null; children?: ReactNode };

const ErrorBoundary: React.FunctionComponent<ErrorBoundaryProps> =
  withErrorBoundary(({ children }) => {
    const [error] = useErrorBoundary(error => Sentry.captureException(error));

    const [isExpanded, setExpanded] = useState<boolean>(false);

    const readableErrorCode = JSON.stringify({
      timestamp: moment().format(),
      message: (error as any)?.message.toString(),
      stack: (error as any)?.stack.toString()
    });

    const encryptedErrorCode = readableErrorCode;

    const logout = useSafeLogout();

    if (error) {
      return (
        <div
          className={styles["error-boundary-comp-wrapper"]}
          style={{ margin: "1rem", textAlign: "center", color: "inherit" }}
        >
          <SDashboardCol>
            <div>
              <WarningTwoTone
                style={{ fontSize: 42, marginBottom: "2rem" }}
                twoToneColor={theme.primary}
              />

              <h1>Entschuldigung für die Unannehmlichkeiten</h1>
              <h2>
                Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später
                erneut.
              </h2>

              <Row style={{ marginTop: "2rem", justifyContent: "center" }}>
                <Col>
                  <SButton
                    type="primary"
                    size="large"
                    style={{ margin: ".5rem" }}
                    icon={<RiRefreshLine />}
                    onClick={() => window.location.reload()}
                  >
                    Seite neu laden
                  </SButton>
                </Col>
                <Col>
                  <SButton
                    type="primary"
                    size="large"
                    style={{ margin: ".5rem" }}
                    icon={<MailFilled />}
                    onClick={() =>
                      window.open("https://www.smavesto.de/kontakt")
                    }
                  >
                    Kontaktieren Sie uns
                  </SButton>
                </Col>
                <Col>
                  <SButton
                    type="primary"
                    size="large"
                    style={{ margin: ".5rem" }}
                    icon={<LoginOutlined />}
                    onClick={logout}
                  >
                    Abmelden
                  </SButton>
                </Col>
              </Row>

              <div style={{ marginTop: 50 }}>
                <Button
                  style={{ marginBottom: 30 }}
                  onClick={() => setExpanded(!isExpanded)}
                >
                  Fehlerdetails {isExpanded ? "einklappen" : "ausklappen"}
                </Button>

                {isExpanded && (
                  <p>
                    Der nachfolgende Zeichenblock könnte uns dabei helfen das
                    Problem zu identifizieren:
                    <br /> <br />
                    <div
                      style={{
                        width: 900,
                        fontSize: 10,
                        whiteSpace: "pre-wrap",
                        lineBreak: "anywhere"
                      }}
                    >
                      <code>{encryptedErrorCode}</code>
                    </div>
                  </p>
                )}
              </div>
            </div>
          </SDashboardCol>
        </div>
      );
    }

    return <div>{children}</div>;
  });

export default ErrorBoundary;
