import { Col } from "antd";

import { ThemeContext } from "@src/pages/_app";
import { BREAK_POINTS } from "@theme/deviceBreakpoints";
import { useContext } from "react";
import styled from "styled-components";

export const SDashboardColComponent = styled(Col)<{
  margin?: string;
  background?: string;
  color?: string;
}>`
  padding: 10px 18px;
  margin: ${({ margin = "0px" }) => margin};
  background: ${({
    background = "background-color: rgba(255, 255, 255, 0.02)"
  }) => background};
  color: ${({ color = "white" }) => color};
  border-radius: 12px;

  @media (min-width: ${BREAK_POINTS.sm}px) {
    padding: 40px 50px;
  }
`;

export const SDashboardCol = (props: any) => {
  const { appModeTheme } = useContext(ThemeContext);

  return (
    <SDashboardColComponent
      {...props}
      color={appModeTheme?.secondLayerTextColor}
      background={appModeTheme?.secondLayerColBackgroundColor}
    />
  );
};
