import { useEffect, useMemo, useState } from "react";
import type { RecoilValueReadOnly } from "recoil";
import { useRecoilValueLoadable } from "recoil";

export type RecoilLoadingState = "hasError" | "loading" | "hasValue";

export const useAsyncSelector = <T>(
  selector: RecoilValueReadOnly<T>,
  callback?: (res: T) => void
): [RecoilLoadingState, T | undefined, string | undefined] => {
  const recoilValueLoadable = useRecoilValueLoadable(selector);

  const { state, contents } = recoilValueLoadable;

  const [lastValue, setLastValue] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (callback && state === "hasValue") callback(contents);
  }, [state, contents, callback]);

  const ret = useMemo<
    [RecoilLoadingState, T | undefined, string | undefined]
  >(() => {
    /* persist the last value */
    if (state === "hasValue") setLastValue(contents as T);
    if (state === "hasError") setLastValue(undefined);
    return [
      state,
      contents instanceof Promise ? lastValue : (contents as T),
      undefined
    ];
  }, [contents, state]);

  return ret;
};
