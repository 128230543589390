import { Form } from "antd";
import styled from "styled-components";

export const SForm = styled(Form)`
  padding: 0 48px;

  .field__addition {
    font-size: 12px;
    color: inherit;
    margin-top: 5px;
  }

  && .required-field-info {
    color: inherit;
    margin-bottom: 20px;
  }

  && .file-upload-container {
    margin-bottom: 24px;
  }

  && .allowed-file-type-info {
    color: inherit;
    margin-top: 4px;
    font-size: 12px;
  }

  @media (max-width: 960px) {
    padding: 30px 30px 0 30px;
  }

  @media (max-width: 391px) {
    padding: 30px 10px 0 10px;
  }
`;
