import type InboxDto from "smavesto.core/lib/types/dto/inbox/InboxDto";

export const getCategoryIndex = (fileName: string) => {
  if (
    fileName.includes("risikoprofilierung") ||
    fileName.includes("stammdaten") ||
    fileName.includes("exantekosten") ||
    fileName.includes("gebühr sepa mandat") ||
    fileName.includes("sparplan sepa mandat") ||
    fileName.includes("sepa") ||
    fileName.includes("anlagerichtlinien") ||
    fileName.includes("informationsbogen") ||
    fileName.includes("kontoeröffnungsantrag") ||
    fileName.includes("vermögensverwaltungsvertrag") ||
    fileName.includes("vollmacht")
  )
    return 1;

  if (
    fileName.includes("anschreiben") ||
    fileName.includes("eröffnungsantrag") ||
    fileName.includes("kontokorrent-taeglich") ||
    fileName.includes("avisierung") ||
    fileName.includes("avis") ||
    fileName.includes("devisen") ||
    fileName.includes("depotauszug-planmaessig") ||
    fileName.includes("depotauszug-ausser") ||
    fileName.includes("abwi") ||
    fileName.includes("voravis") ||
    fileName.includes("geldhandel") ||
    fileName.includes("hauptversammlung") ||
    fileName.includes("kreditgeb") ||
    fileName.includes("taeglicher-kontoauszug") ||
    fileName.includes("monatlicher-kontoauszug") ||
    fileName.includes("kredit") ||
    fileName.includes("kontovormerkungen") ||
    fileName.includes("lgst") ||
    fileName.includes("mahnung") ||
    fileName.includes("auftragsbest") ||
    fileName.includes("abwicklung-kredit") ||
    fileName.includes("swap") ||
    fileName.includes("saldenm") ||
    fileName.includes("saldenb") ||
    fileName.includes("margin") ||
    fileName.includes("wertpapierabrechnung") ||
    fileName.includes("wertpapier-ereignisse") ||
    fileName.includes("lieferung") ||
    fileName.includes("derivate") ||
    fileName.includes("zinssatzaenderung") ||
    fileName.includes("abrechnung") ||
    fileName.includes("expost") ||
    fileName.includes("wertpapiersystem-depotgebuehr") ||
    fileName.includes("gebuehrenreport-stammnummer")
  )
    return 2;

  if (
    fileName.includes("anschaffung") ||
    fileName.includes("veraeusserung") ||
    fileName.includes("betriebsstaetten") ||
    fileName.includes("ertraegnisaufstellung") ||
    fileName.includes("estb") ||
    fileName.includes("jahressteuer") ||
    fileName.includes("topfanpassung") ||
    fileName.includes("topfsaldo") ||
    fileName.includes("topfausgleich") ||
    fileName.includes("manuell") ||
    fileName.includes("jstb-1") ||
    fileName.includes("jstb-3") ||
    fileName.includes("freistellungsauftrag")
  )
    return 3;

  if (
    fileName.includes("finanzstatus") ||
    fileName.includes("performancereport") ||
    fileName.includes("quartalsaufstellung") ||
    fileName.includes("vermögensübersicht") ||
    fileName.includes("jahresdepotauszug") ||
    fileName.includes("performance-report")
  )
    return 4;

  return 5;
};
export const getCategories = (
  inbox: InboxDto[] = [],
  inboxToConfirm: InboxDto[] = []
) =>
  inbox.reduce(
    (acc, item, i) => {
      const fileName =
        (item.attachments.length > 0 &&
          item.attachments[0].fileName.toLowerCase()) ||
        "";
      const categoryIndex = getCategoryIndex(fileName);

      acc[0] = {
        ...acc[0],
        files: [...acc[0].files, item],
        count: acc[0].count + 1
      };

      acc[categoryIndex] = {
        ...acc[categoryIndex],
        files: [...acc[categoryIndex].files, item],
        count: acc[categoryIndex].count + 1
      };

      if (i === inbox.length - 1 && inboxToConfirm.length > 0) {
        acc[6] = {
          ...acc[6],
          files: inboxToConfirm,
          count: inboxToConfirm.length
        };
      }

      return acc;
    },
    [
      { title: "Alle", count: 0, files: [] as InboxDto[] },
      { title: "Vertrag", count: 0, files: [] as InboxDto[] },
      { title: "Konto/Depot", count: 0, files: [] as InboxDto[] },
      { title: "Steuern", count: 0, files: [] as InboxDto[] },
      { title: "Reporting", count: 0, files: [] as InboxDto[] },
      { title: "Nachrichten", count: 0, files: [] as InboxDto[] },
      { title: "Zustimmungspflichtige", count: 0, files: [] as InboxDto[] }
    ]
  );
