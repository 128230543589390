import { ThemeContext } from "@pages/_app";
import type { ButtonProps } from "antd";
import { Button } from "antd";
import React, { useContext } from "react";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import type { AppThemeSetting } from "smavesto.core/lib/static/themes/AppThemeSetting";
import styled from "styled-components";

const SButtonComponent = (
  theme: AppTheme,
  appModeTheme?: AppThemeSetting
) => styled(Button)`
  display: flex;
  position: relative;
  border-radius: 30px;
  padding: 6px 40px;

  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  height: auto;

  span {
    color: ${({ disabled }) =>
      disabled
        ? theme.palette.disabledTextColor
        : appModeTheme?.colorSchema === "light"
        ? "inherit"
        : theme.palette.primaryTextColor};
  }

  .ant-btn-loading-icon {
    position: absolute !important;
    left: 10px;
    font-size: 24px;
  }

  transition: all 0.5s !important;
  font-weight: 500;

  &&.reset {
    color: inherit;
    background: transparent !important;
    border: 2px solid inherit;
    box-shadow: inset 0px 0px 0px inherit;

    span {
      color: inherit;
      transition: all 0s !important;
    }
  }

  &&.reset:hover {
    box-shadow: inset 0px -80px 0px white;

    span {
      color: black;
    }
  }

  &&.link {
    span {
      color: inherit;
      transition: color 0.5s !important;
      text-decoration: underline;
    }
  }

  &&.link:hover {
    span {
      color: ${theme.palette.primary};
    }
  }

  &&.text {
    padding: 0;

    span {
      color: inherit;
      transition: all 0.5s !important;
      text-decoration: underline;
      font-weight: normal;
    }
  }

  &&.text:hover {
    span {
      text-decoration: none;
    }
  }

  &&.add-button {
    justify-content: start;
    font-size: 16px;
    padding: 4px 0px;
    margin: 30px 0;
    color: inherit;

    .anticon {
      color: inherit;
    }

    span:last-child {
      font-weight: normal;
      color: inherit;
      text-decoration: underline;
    }
  }
`;

export const SButton: React.FunctionComponent<ButtonProps> = props => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const RenderComponent = SButtonComponent(theme, appModeTheme);

  return <RenderComponent {...props} />;
};

export const SActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin: 20px 0;

  button {
    margin: 10px;
    margin-right: 0px;
  }
`;

export const SActionButtonsWrapperMobile = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 20px 0;

  button {
    margin: 10px;
    margin-right: 0px;
  }
`;
