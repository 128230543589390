import type { FormItemProps } from "antd";
import React from "react";

import { SFormItem } from "@styled-components/form/SFormItems";

import PopoverInfo from "../../popover-info/PopoverInfo";

export interface FormItemWithInfoProps extends FormItemProps {
  info?: {
    title: string;
    text: string;
  };
}

const FormItemWithInfo: React.FunctionComponent<FormItemWithInfoProps> = ({
  label,
  info,
  children,
  ...formItemProps
}) => (
  <SFormItem
    {...formItemProps}
    label={info ? <PopoverInfo {...info}>{label}</PopoverInfo> : label}
  >
    {children}
  </SFormItem>
);

export default FormItemWithInfo;
