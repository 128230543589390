import { Col, Form, Modal } from "antd";

import CheckboxComponent from "@components/form/inputs/checkboxes/CheckboxComponent";
import { FormSectionWrapper } from "@components/form/inputs/form-fields/FormSectionWrapper";
import { RadioGroup } from "@components/form/inputs/form-fields/RadioGroup";
import CustomModalContent from "@components/layout/modal/CustomModalContent";
import useLoadingState from "@hooks/state-utils/useLoadingState";
import {
  OPTION_CHECKBOX_EMAIL,
  RADIO_GENDERS,
  RULES
} from "@page-components/onboarding-underage/CustodyInfo/constants";
import { CustodyUsername } from "@page-components/onboarding-underage/CustodyInfo/username";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";
import { SForm } from "@styled-components/form/SForm";

import { SFormItem } from "@src/styled-components/form/SFormItems";
import { SInput } from "@src/styled-components/form/SInputs";
import useEditCustodyModalForm from "../hooks/useEditCustodyModalForm";

interface EditCustodyModalForm {
  isOpen: boolean;
  onCancel: () => void;
}

const EditCustodyModalForm: React.FunctionComponent<EditCustodyModalForm> = ({
  isOpen,
  onCancel
}) => {
  const { form, onFinish } = useEditCustodyModalForm();

  const { isLoading, performMethod } = useLoadingState();

  const onFinishForm = async (values: any) => {
    performMethod(async () => {
      const finishResult = await onFinish(values);
      if (finishResult === true) onCancel();
    });
  };

  return (
    <Modal
      width="100%"
      style={{ maxWidth: 900, boxSizing: "border-box" }}
      centered
      visible={isOpen}
      modalRender={() => (
        <CustomModalContent>
          <SForm
            form={form}
            layout="vertical"
            onFinish={onFinishForm}
            scrollToFirstError={{ behavior: "smooth" }}
          >
            <FormSectionWrapper>
              <RadioGroup
                name="parentUser2GenderType"
                rules={RULES.gender}
                items={RADIO_GENDERS}
                label="Anrede des weiteren gesetzlichen Vertreters*"
              />
              <CustodyUsername
                presetFirstName={form.getFieldValue("parentUser2FirstName")}
                presetLastName={form.getFieldValue("parentUser2LastName")}
              />

              <Col md={12} xs={24}>
                <SFormItem
                  name="parentUser2Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message:
                        "Bitte geben Sie eine gültige E-Mail-Adresse ein."
                    }
                  ]}
                >
                  <SInput
                    title="E-Mail-Adresse des weiteren gesetzlichen Vertreters"
                    type="e-mail"
                    required
                  />
                </SFormItem>
              </Col>

              <Form.Item
                name="zustimmenGesetzlicheVertreter"
                valuePropName="checked"
                initialValue={false}
                rules={RULES.zustimmenGesetzlicheVertreter}
              >
                <CheckboxComponent option={OPTION_CHECKBOX_EMAIL} />
              </Form.Item>

              <SActionButtonsWrapper>
                <SButton
                  className="reset"
                  onClick={onCancel}
                  data-testid="close-edit-custody-modal"
                >
                  Zurück
                </SButton>

                <SButton
                  htmlType="submit"
                  className="reset"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  E-Mail versenden
                </SButton>
              </SActionButtonsWrapper>
            </FormSectionWrapper>
          </SForm>
        </CustomModalContent>
      )}
    />
  );
};

export default EditCustodyModalForm;
