import getActiveStep from "@src/workflows/engine/methods/getActiveStep";
import underageOnboardingStepsFirstGuardian from "@src/workflows/onboarding-underage/workflow-paths/underageOnboardingStepsFirstGuardian";
import underageOnboardingStepsSecondGuardian from "@src/workflows/onboarding-underage/workflow-paths/underageOnboardingStepsSecondGuardian";

const allSteps = [
  "ANLAGEWUNSCH",
  "PREFERECENCES",
  "ZUGANGSDATEN",
  "PERSOENLICHE_ANGABEN",
  "FINANZIELLE_LAGE",
  "KENNTNISSE",
  "BANKVERBINDUNG"
];

export const getNextStepToOnboarding = (userSteps: Array<string>) => {
  const openSteps = allSteps.filter(x => !userSteps.includes(x));

  // Routing Order
  if (openSteps.includes("ZUGANGSDATEN")) {
    return "/registration";
  }

  if (openSteps.includes("PERSOENLICHE_ANGABEN")) {
    return "/onboarding/personal-data";
  }

  if (openSteps.includes("ANLAGEWUNSCH")) {
    return "/onboarding/investment";
  }

  if (openSteps.includes("FINANZIELLE_LAGE")) {
    return "/onboarding/financial-situation";
  }

  if (openSteps.includes("PREFERECENCES")) {
    return "/onboarding/preferences";
  }

  if (openSteps.includes("KENNTNISSE")) {
    return "/onboarding/knowledge";
  }

  if (openSteps.includes("BANKVERBINDUNG")) {
    return "/onboarding/bank-details";
  }

  return "/onboarding/summary";
};

export const getNextStepToUnderageOnboarding = (
  userSteps: Array<string>,
  userIsSecondGuardian?: boolean,
  isInActiveOnboardingState?: boolean
) => {
  const allSteps = userIsSecondGuardian
    ? underageOnboardingStepsSecondGuardian
    : underageOnboardingStepsFirstGuardian(isInActiveOnboardingState || false);

  return getActiveStep(allSteps, userSteps)?.to;
};
