import { Divider } from "antd";
import type { FC } from "react";
import styled from "styled-components";

import type { PropsWithChildren } from "@src/interfaces/jsx.interface";
import type { PopoverInfoProps } from "../../popover-info/PopoverInfo";
import PopoverInfo from "../../popover-info/PopoverInfo";

type Props = {
  title?: string;
  dividerColor?: string;
  tag?: "h1" | "h2" | "none";
  tooltip?: PopoverInfoProps;
} & PropsWithChildren;

export const FormSectionWrapper: FC<Props> = ({
  title,
  dividerColor = "#1b4040",
  tag = "h2",
  children,
  tooltip
}) => (
  <>
    {tag === "none" ? null : tag === "h2" ? (
      <SH2>
        {title} {tooltip && <PopoverInfo {...tooltip} />}
      </SH2>
    ) : (
      <SH1>
        {title}
        {tooltip && <PopoverInfo {...tooltip} />}
      </SH1>
    )}

    {children}
    <Divider style={{ borderColor: dividerColor }} />
  </>
);

export const SH2 = styled.h2`
  font-size: 20px;
  font-weight: 600;
`;

export const SH1 = styled.h2`
  font-size: 32px;
`;
