import { atom } from "recoil";

import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

import type { InferValueType } from "@page-components/onboarding/SummaryConfirm/localState";

export interface StrategyRiskState
  extends Partial<Pick<FinancialPositionDto, "strategy">> {
  strategyDBValue?: FinancialPositionDto["strategy"];
  riskDBValue?: number;
  risk?: number;
}

export const strategyRiskState = atom<StrategyRiskState>({
  key: "StrategyRisk",
  default: {
    strategyDBValue: undefined,
    riskDBValue: undefined,
    strategy: undefined,
    risk: 15
  }
});

const ActionTypes = {
  INIT_RISK: "INIT_RISK" as const,
  INIT_STRATEGY: "INIT_STRATEGY" as const,
  CHANGE_RISK: "CHANGE_RISK" as const,
  CHANGE_STRATEGY: "CHANGE_STRATEGY" as const,
  RESET: "RESET" as const
};

export const Actions = {
  initializeRisk: (riskDBValue: number) => ({
    type: ActionTypes.INIT_RISK,
    payload: { riskDBValue }
  }),

  initializeStrategy: (strategyDBValue: FinancialPositionDto["strategy"]) => ({
    type: ActionTypes.INIT_STRATEGY,
    payload: { strategyDBValue }
  }),
  changeRisk: (risk: number) => ({
    type: ActionTypes.CHANGE_RISK,
    payload: { risk }
  }),

  changeStrategy: (strategy: FinancialPositionDto["strategy"]) => ({
    type: ActionTypes.CHANGE_STRATEGY,
    payload: { strategy }
  }),

  reset: () => ({
    type: ActionTypes.RESET
  })
};

type Action = ReturnType<InferValueType<typeof Actions>>;

export const strategyRiskReducer =
  (action: Action) => (state: StrategyRiskState) => {
    switch (action.type) {
      case ActionTypes.INIT_RISK:
        return {
          ...state,
          ...action.payload,
          risk: action.payload.riskDBValue
        };

      case ActionTypes.INIT_STRATEGY:
        return {
          ...state,
          strategyDBValue: state.strategyDBValue
            ? state.strategyDBValue
            : action.payload.strategyDBValue,
          strategy: action.payload.strategyDBValue
        };

      case ActionTypes.CHANGE_RISK:
        return { ...state, ...action.payload };

      case ActionTypes.CHANGE_STRATEGY:
        return { ...state, ...action.payload };

      case ActionTypes.RESET:
        return {
          ...state,
          risk: state.riskDBValue,
          strategy: state.strategyDBValue
        };
      default:
        throw new Error("Unknown action");
    }
  };

export type StrategyRiskErrors = {
  strategy: { range: string };
  risk: { range: string };
  apiError: string;
};

export const strategyRiskErrorsState = atom<StrategyRiskErrors>({
  key: "StrategyRiskErrors",
  default: { strategy: { range: "" }, risk: { range: "" }, apiError: "" }
});
