export type RecoilLoadingState = "hasValue" | "loading" | "hasError";

export type RecoilLoadingStateDetails = {
  state: RecoilLoadingState;
  loadingProgress: number;
  statesLoaded: number;
  statesLoading: number;
  states: { key: string; value: RecoilLoadingState }[];
};

export function bundledLoadingState(
  states: RecoilLoadingState[]
): RecoilLoadingState {
  if (states.some(s => s === "loading")) return "loading";
  if (states.some(s => s === "hasError")) return "hasError";
  if (states.every(s => s === "hasValue")) return "hasValue";

  return "loading";
}

export function detailedBundledLoadingState(
  states: RecoilLoadingState[]
): RecoilLoadingStateDetails {
  const state = bundledLoadingState(states);
  const loadingStates = states.filter(state => state === "loading");
  const loadedStates = states.filter(state => state === "hasValue");

  const progress = Math.round((100 / states.length) * loadedStates.length);

  return {
    state,
    loadingProgress: progress,
    statesLoading: loadingStates.length,
    statesLoaded: loadedStates.length,
    states: states.map((o, index) => ({
      key: Object.keys(states)[index],
      value: o
    }))
  };
}
