import { SP } from "@styled-components/STags";
import isUnderageDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import moment from "moment";
import type DepotDto from "smavesto.core/lib/types/dto/depot/DepotDto";
import OuterHint from "./OuterHint";

interface CompletedDepotCancellationHintProps {
  depot: DepotDto;
  cancellationDate: string;
}

const CompletedDepotCancellationHint: React.FunctionComponent<
  CompletedDepotCancellationHintProps
> = ({ depot, cancellationDate }) => {
  const isUnderage = isUnderageDepot(depot);
  const isJuristic = depot.juristic;

  if (isUnderage)
    return (
      <OuterHint title="Dieses Depot wurde gekündigt">
        <SP>
          Sie haben das Junior-Depot von {depot.depotOwnerFullName} bereits am{" "}
          {moment(cancellationDate).format("DD.MM.yy")} gekündigt. Die Kündigung
          ist eingegangen und wird nun bearbeitet.
          <br />
          <br />
          {isJuristic
            ? "Der Verkauf der Wertpapiere Ihres Kindes und die Überweisung auf das Referenzkonto erfolgt in Kürze."
            : ""}
        </SP>
      </OuterHint>
    );
  return (
    <OuterHint title="Dieses Depot wurde gekündigt">
      <SP>
        Sie haben Ihr Konto bereits am{" "}
        {moment(cancellationDate).format("DD.MM.yy")} gekündigt. Ihre Kündigung
        ist eingegangen und wird nun bearbeitet.
        <br />
        <br />
        {isJuristic
          ? " Der Verkauf Ihrer Wertpapiere und die Überweisung auf Ihr Referenzkonto erfolgt in Kürze."
          : ""}
      </SP>
    </OuterHint>
  );
};

export default CompletedDepotCancellationHint;
