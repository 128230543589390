import { Col, Divider, Row } from "antd";
import React from "react";

import { MenuButtonSkeleton } from "./MenuButtonSkeleton";
import styles from "./style.module.less";

export const OnboardingUnderageNavigationSkeleton: React.FunctionComponent =
  () => {
    return (
      <div className={styles["navigation-wrapper"]}>
        <Row>
          <Col span={24}>
            <div
              style={{
                padding: 25,
                paddingTop: 0,
                borderRight: "1px solid rgba(255,255,255,0.1)"
              }}
            >
              {[0, 1].map(idx => (
                <div key={`navsec_${idx}`}>
                  <div>
                    <Divider style={{ borderColor: "#1b4040" }} />
                    <div className={styles["section-title"]} />
                  </div>

                  {[0, 1, 2].map(idx => (
                    <div style={{ marginTop: idx === 0 ? 4 : 20 }} key={idx}>
                      <MenuButtonSkeleton />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    );
  };
