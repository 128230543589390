export const IBAN_DICTIONARY = {
  AL: {
    code: "AL",
    sepa: false,
    length: 28,
    example: "AL35202111090000000001234567",
    mask: "aa99 9999 9999 9999 9999 9999 9999"
  },
  AD: {
    code: "AD",
    sepa: true,
    length: 24,
    example: "AD1400080001001234567890",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  AT: {
    code: "AT",
    sepa: true,
    length: 20,
    example: "AT483200000012345864",
    mask: "aa99 9999 9999 9999 9999"
  },
  BH: {
    code: "BH",
    sepa: false,
    length: 22,
    example: "BH02CITI00001077181611",
    mask: "aa99 aaaa 9999 9999 9999 99"
  },
  BY: {
    code: "BY",
    sepa: false,
    length: 28,
    example: "BY86AKBB10100000002966000000",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999"
  },
  BE: {
    code: "BE",
    sepa: true,
    length: 16,
    example: "BE71096123456769",
    mask: "aa99 9999 9999 9999"
  },
  BA: {
    code: "BA",
    sepa: false,
    length: 20,
    example: "BA393385804800211234",
    mask: "aa99 9999 9999 9999 9999"
  },
  BR: {
    code: "BR",
    sepa: false,
    length: 29,
    example: "BR1500000000000010932840814P2",
    mask: "aa99 9999 9999 9999 9999 9999 999a 9"
  },
  BG: {
    code: "BG",
    sepa: true,
    length: 22,
    example: "BG18RZBB91550123456789",
    mask: "aa99 aaaa 9999 9999 9999 99"
  },
  BI: {
    code: "BI",
    sepa: false,
    length: 27,
    example: "BI43220001131012345678912345",
    mask: "aa99 9999 9999 9999 9999 9999 999"
  },
  CR: {
    code: "CR",
    sepa: false,
    length: 22,
    example: "CR23015108410026012345",
    mask: "aa99 9999 9999 9999 9999 99"
  },
  HR: {
    code: "HR",
    sepa: true,
    length: 21,
    example: "HR1723600001101234565",
    mask: "aa99 9999 9999 9999 9999 9"
  },
  CY: {
    code: "CY",
    sepa: true,
    length: 28,
    example: "CY21002001950000357001234567",
    mask: "aa99 9999 9999 9999 9999 9999 9999"
  },
  CZ: {
    code: "CZ",
    sepa: true,
    length: 24,
    example: "CZ5508000000001234567899",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  DK: {
    code: "DK",
    sepa: true,
    length: 18,
    example: "DK9520000123456789",
    mask: "aa99 9999 9999 9999 99"
  },
  DO: {
    code: "DO",
    sepa: false,
    length: 28,
    example: "DO22ACAU00000000000123456789",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999"
  },
  EG: {
    code: "EG",
    sepa: false,
    length: 20,
    example: "EG800002000156789012345180002",
    mask: "aa99 9999 9999 9999 9999"
  },
  SV: {
    code: "SV",
    sepa: false,
    length: 28,
    example: "SV43ACAT00000000000000123123",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999"
  },
  EE: {
    code: "EE",
    sepa: true,
    length: 20,
    example: "EE471000001020145685",
    mask: "aa99 9999 9999 9999 9999"
  },
  FO: {
    code: "FO",
    sepa: false,
    length: 18,
    example: "FO9264600123456789",
    mask: "aa99 9999 9999 9999 99"
  },
  FI: {
    code: "FI",
    sepa: true,
    length: 18,
    example: "FI1410093000123458",
    mask: "aa99 9999 9999 9999 99"
  },
  FR: {
    code: "FR",
    sepa: true,
    length: 27,
    example: "FR7630006000011234567890189",
    mask: "aa99 9999 9999 9999 9999 9999 999"
  },
  GE: {
    code: "GE",
    sepa: false,
    length: 22,
    example: "GE60NB0000000123456789",
    mask: "aa99 9999 9999 9999 9999 99"
  },
  DE: {
    code: "DE",
    sepa: true,
    length: 22,
    example: "DE75512108001245126199",
    mask: "aa99 9999 9999 9999 9999 99"
  },
  GI: {
    code: "GI",
    sepa: true,
    length: 23,
    example: "GI56XAPO000001234567890",
    mask: "aa99 9999 9999 9999 9999 999"
  },
  GR: {
    code: "GR",
    sepa: true,
    length: 27,
    example: "GR9608100010000001234567890",
    mask: "aa99 9999 9999 9999 9999 9999 999"
  },
  GL: {
    code: "GL",
    sepa: false,
    length: 18,
    example: "GL8964710123456789",
    mask: "aa99 9999 9999 9999 99"
  },
  VA: {
    code: "VA",
    sepa: true,
    length: 22,
    example: "VA59001123000012345678",
    mask: "aa99 9999 9999 9999 9999 99"
  },
  HU: {
    code: "HU",
    sepa: true,
    length: 28,
    example: "HU93116000060000000012345676",
    mask: "aa99 9999 9999 9999 9999 9999 9999"
  },
  IS: {
    code: "IS",
    sepa: true,
    length: 26,
    example: "IS750001121234563108962099",
    mask: "aa99 9999 9999 9999 9999 9999 99"
  },
  IQ: {
    code: "IQ",
    sepa: false,
    length: 23,
    example: "IQ20CBIQ861800101010500",
    mask: "aa99 aaaa 9999 9999 9999 999"
  },
  IE: {
    code: "IE",
    sepa: true,
    length: 22,
    example: "IE64IRCE92050112345678",
    mask: "aa99 aaaa 9999 9999 9999 99"
  },
  IL: {
    code: "IL",
    sepa: false,
    length: 23,
    example: "IL170108000000012612345",
    mask: "aa99 9999 9999 9999 9999 999"
  },
  IT: {
    code: "IT",
    sepa: true,
    length: 27,
    example: "IT60X0542811101000000123456",
    mask: "aa99 99a9 9999 9999 9999 9999 999"
  },
  JO: {
    code: "JO",
    sepa: false,
    length: 30,
    example: "JO71CBJO0000000000001234567890",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 99"
  },
  KZ: {
    code: "KZ",
    sepa: false,
    length: 20,
    example: "KZ244350000012344567",
    mask: "aa99 9999 9999 9999 9999"
  },
  XK: {
    code: "XK",
    sepa: false,
    length: 20,
    example: "XK051212012345678906",
    mask: "aa99 9999 9999 9999 9999"
  },
  KW: {
    code: "KW",
    sepa: false,
    length: 30,
    example: "KW81CBKU0000000000001234560101",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 99"
  },
  LV: {
    code: "LV",
    sepa: true,
    length: 21,
    example: "LV97HABA0012345678910",
    mask: "aa99 aaaa 9999 9999 9999 9"
  },
  LB: {
    code: "LB",
    sepa: false,
    length: 28,
    example: "LB92000700000000123123456123",
    mask: "aa99 9999 9999 9999 9999 9999 9999"
  },
  LY: {
    code: "LY",
    sepa: false,
    length: 25,
    example: "LY38021001000000123456789",
    mask: "aa99 9999 9999 9999 9999 9999 9"
  },
  LI: {
    code: "LI",
    sepa: true,
    length: 21,
    example: "LI7408806123456789012",
    mask: "aa99 aaaa 9999 9999 9999 9"
  },
  LT: {
    code: "LT",
    sepa: true,
    length: 20,
    example: "LT601010012345678901",
    mask: "aa99 9999 9999 9999 9999"
  },
  LU: {
    code: "LU",
    sepa: true,
    length: 20,
    example: "LU120010001234567891",
    mask: "aa99 9999 9999 9999 9999"
  },
  MT: {
    code: "MT",
    sepa: true,
    length: 31,
    example: "MT31MALT01100000000000000000123",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 999"
  },
  MR: {
    code: "MR",
    sepa: false,
    length: 27,
    example: "MR1300020001010000123456753",
    mask: "aa99 9999 9999 9999 9999 9999 999"
  },
  MU: {
    code: "MU",
    sepa: false,
    length: 30,
    example: "MU43BOMM0101123456789101000MUR",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 99"
  },
  MD: {
    code: "MD",
    sepa: false,
    length: 24,
    example: "MD21EX000000000001234567",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  ME: {
    code: "ME",
    sepa: false,
    length: 22,
    example: "ME25505000012345678951",
    mask: "aa99 9999 9999 9999 9999 99"
  },
  NL: {
    code: "NL",
    sepa: true,
    length: 18,
    example: "NL02ABNA0123456789",
    mask: "aa99 aaaa 9999 9999 99"
  },
  MK: {
    code: "MK",
    sepa: false,
    length: 19,
    example: "MK07200002785123453",
    mask: "aa99 9999 9999 9999 999"
  },
  NO: {
    code: "NO",
    sepa: true,
    length: 15,
    example: "NO8330001234567",
    mask: "aa99 9999 9999 999"
  },
  PK: {
    code: "PK",
    sepa: false,
    length: 24,
    example: "PK36SCBL0000001123456702",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  PS: {
    code: "PS",
    sepa: false,
    length: 29,
    example: "PS92PALS000000000400123456702",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 9"
  },
  PL: {
    code: "PL",
    sepa: true,
    length: 28,
    example: "PL10105000997603123456789123",
    mask: "aa99 9999 9999 9999 9999 9999 9999"
  },
  PT: {
    code: "PT",
    sepa: true,
    length: 25,
    example: "PT50002700000001234567833",
    mask: "aa99 9999 9999 9999 9999 9999 9"
  },
  QA: {
    code: "QA",
    sepa: false,
    length: 29,
    example: "QA54QNBA000000000000693123456",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 9"
  },
  RO: {
    code: "RO",
    sepa: true,
    length: 24,
    example: "RO66BACX0000001234567890",
    mask: "aa99 aaaa 9999 9999 9999 9999"
  },
  LC: {
    code: "LC",
    sepa: false,
    length: 32,
    example: "LC14BOSL123456789012345678901234",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 9999"
  },
  SM: {
    code: "SM",
    sepa: true,
    length: 27,
    example: "SM76P0854009812123456789123",
    mask: "aa99 a999 9999 9999 9999 9999 999"
  },
  ST: {
    code: "ST",
    sepa: false,
    length: 25,
    example: "ST23000200000289355710148",
    mask: "aa99 9999 9999 9999 9999 9999 9"
  },
  SA: {
    code: "SA",
    sepa: false,
    length: 24,
    example: "SA4420000001234567891234",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  RS: {
    code: "RS",
    sepa: false,
    length: 22,
    example: "RS35105008123123123173",
    mask: "aa99 9999 9999 9999 9999 99"
  },
  SC: {
    code: "SC",
    sepa: false,
    length: 31,
    example: "SC74MCBL01031234567890123456USD",
    mask: "aa99 aaaa 9999 9999 9999 9999 9999 999"
  },
  SK: {
    code: "SK",
    sepa: true,
    length: 24,
    example: "SK8975000000000012345671",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  SI: {
    code: "SI",
    sepa: true,
    length: 19,
    example: "SI56192001234567892",
    mask: "aa99 9999 9999 9999 999"
  },
  ES: {
    code: "ES",
    sepa: true,
    length: 24,
    example: "ES7921000813610123456789",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  SD: {
    code: "SD",
    sepa: false,
    length: 18,
    example: "SD8811123456789012",
    mask: "aa99 9999 9999 9999 99"
  },
  SE: {
    code: "SE",
    sepa: true,
    length: 24,
    example: "SE7280000810340009783242",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  CH: {
    code: "CH",
    sepa: true,
    length: 21,
    example: "CH5604835012345678009",
    mask: "aa99 9999 9999 9999 9999 9"
  },
  TL: {
    code: "TL",
    sepa: false,
    length: 23,
    example: "TL380010012345678910106",
    mask: "aa99 9999 9999 9999 9999 999"
  },
  TN: {
    code: "TN",
    sepa: false,
    length: 24,
    example: "TN5904018104004942712345",
    mask: "aa99 9999 9999 9999 9999 9999"
  },
  TR: {
    code: "TR",
    sepa: false,
    length: 26,
    example: "TR320010009999901234567890",
    mask: "aa99 9999 9999 9999 9999 9999 99"
  },
  UA: {
    code: "UA",
    sepa: false,
    length: 29,
    example: "UA903052992990004149123456789",
    mask: "aa99 9999 9999 9999 9999 9999 9999 9"
  },
  AE: {
    code: "AE",
    sepa: false,
    length: 23,
    example: "AE460090000000123456789",
    mask: "aa99 9999 9999 9999 9999 999"
  },
  GB: {
    code: "GB",
    sepa: true,
    length: 22,
    example: "GB33BUKB20201555555555",
    mask: "aa99 aaaa 9999 9999 9999 99"
  },
  VG: {
    code: "VG",
    sepa: false,
    length: 24,
    example: "VG07ABVI0000000123456789",
    mask: "aa99 aaaa 9999 9999 9999 9999"
  }
};
