import UNDERAGE_URL_PARAM from "@utils/constants/Urls";
import type WorkflowStepGroup from "@src/workflows/engine/models/WorkflowStepGroup";

const underageOnboardingStepsSecondGuardian: WorkflowStepGroup[] = [
  {
    order: 1,
    items: [
      {
        order: 1,
        to: `/registration/submit`,
        text: "Zugangsdaten",
        chapterName: "ZUGANGSDATEN"
      }
    ]
  },
  {
    order: 2,
    title: "Angaben gesetzliche Vertreter",
    items: [
      {
        order: 2,
        to: `/onboarding/personal-data?${UNDERAGE_URL_PARAM}`,
        text: "Persönliche Angaben",
        chapterName: "PERSOENLICHE_ANGABEN_SORGEBERECHTIGTER"
      },
      {
        order: 3,
        to: `/onboarding/knowledge?${UNDERAGE_URL_PARAM}`,
        text: "Kenntnisse",
        chapterName: "KENNTNISSE_SORGEBERECHTIGTER"
      },
      {
        order: 4,
        to: `/onboarding/summary-child?${UNDERAGE_URL_PARAM}`,
        text: "Zusammenfassung",
        chapterName: "SUMMARY",
        subSteps: [
          {
            order: 5,
            to: `/onboarding/summary-child?${UNDERAGE_URL_PARAM}`,
            text: "Kind",
            chapterName: "SUMMARY"
          },
          {
            order: 6,
            to: `/onboarding/summary-representative?${UNDERAGE_URL_PARAM}`,
            text: "Gesetzlicher Vertreter",
            chapterName: "SUMMARY"
          }
        ]
      },
      {
        order: 7,
        to: `/onboarding/summary2?${UNDERAGE_URL_PARAM}`,
        text: "Dokumente und Legitimation",
        text_short: "Dokumente",
        chapterName: "DOKUMENTE_GESETZLICHER_VERTRETER_2"
      }
    ]
  }
];

export default underageOnboardingStepsSecondGuardian;
