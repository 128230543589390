import { simulateFeatureFlagsLikeProductionState } from "@src/state/simulate.state";
import type { FEATURE_FLAG_FEATURE } from "@src/utils/development/isFeatureFlagged";
import isFeatureFlagged from "@src/utils/development/isFeatureFlagged";
import { useAsyncSelector } from "./useAsyncSelector";

export const useFeatureFlag = (featureFlag: FEATURE_FLAG_FEATURE) => {
  const featureFlagRequest = isFeatureFlagged(featureFlag);

  const [, simulateFeatureFlagsLikeProduction] = useAsyncSelector(
    simulateFeatureFlagsLikeProductionState
  );

  if (simulateFeatureFlagsLikeProduction)
    return isFeatureFlagged(featureFlag, true);

  return featureFlagRequest;
};
