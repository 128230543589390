import { useContext } from "react";

import { ThemeContext } from "@src/pages/_app";

import { Row, Skeleton } from "antd";

import CancellationTooltip from "@components/form/tooltips/CancellationTooltip";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { riskYieldState } from "@src/state/risk-yield.state";
import { notJuristicDepotState } from "@states/depot.state";
import { SButton } from "@styled-components/form/SButtons";

import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";
import { userUriState } from "@src/state/auth.state";
import {
  loggedInUserOnboardingState,
  onboardingByEnvironmentState
} from "@src/state/onboarding.state";
import { bundledLoadingState } from "@src/utils/recoil/bundledLoadingState";
import { useRouter } from "next/router";
import isFirstGuardianForDepot from "smavesto.core/lib/utils/typeguards/isFirstGuardianForDepot";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import OuterHint from "./OuterHint";

function LegitimationRequiredDepotMessage() {
  const router = useRouter();

  const { theme } = useContext(ThemeContext);

  const [loggedInUserLoadingState, loggedInUserUri] = useAsyncSelector(
    userUriState("loggedInUser")
  );

  const [loggedInUserOnboardingDataLoadingState, loggedInUserOnboardingData] =
    useAsyncSelector(loggedInUserOnboardingState);

  const [onboardingDepotLoadingState, onboardingDepot] = useAsyncSelector(
    notJuristicDepotState
  );

  const [onboardingDataLoadingState, onboardingData] = useAsyncSelector(
    onboardingByEnvironmentState("notJuristicOnboardingOwner")
  );

  const isLoading =
    bundledLoadingState([
      loggedInUserLoadingState,
      loggedInUserOnboardingDataLoadingState,
      onboardingDepotLoadingState,
      onboardingDataLoadingState
    ]) === "loading";

  const isParentLegitimationRequired =
    loggedInUserOnboardingData?.legitimationRequired === true &&
    (onboardingData?.onboardingCompleted ||
      (onboardingData &&
        onboardingDepot &&
        isFirstGuardianForDepot(onboardingDepot) &&
        onboardingData.completedChapters.indexOf(
          "DOKUMENTE_GESETZLICHER_VERTRETER_1"
        ) > -1));

  const isJuniorDepotLegitimationRequired =
    (onboardingDepot &&
      isJuniorDepot(onboardingDepot) &&
      onboardingData?.legitimationRequired &&
      onboardingData.onboardingCompleted) ||
    false;

  const [, riskYield] = useAsyncSelector(riskYieldState("currentDepotOwner"));

  const isAncekaTheme = theme.key === "anceka";

  const startAmount = riskYield?.startbetrag || 0;

  const hasStartAmount = startAmount > 0;

  const isDebitOrder = startAmount < 10000;

  const bothLegitimationHint = (
    <>
      <p>
        Die Legitimation Ihrer Person sowie die Ihres Kindes sind noch nicht
        abgeschlossen. Führen Sie den Legitimationsprozess über einen der
        untenstehenden Buttons oder mithilfe der Ihnen zugestellten E-Mail aus,
        um sich bei Smavesto zu legitimieren. Sobald dieser Prozess erfolgreich
        durchgeführt ist, erhalten Sie eine weitere E-Mail zur Bestätigung und
        können mit Ihrem Depot durchstarten.
      </p>
      {isDebitOrder && hasStartAmount ? (
        <p>
          Nach Ihren Legitimationen veranlassen wir die Lastschrift Ihrer
          gewünschten Starteinzahlung für Ihr Kind.
        </p>
      ) : (
        <p>
          Nach Ihrer Legitimationen richtet unser Partner, die Baader Bank, Ihr
          Depot für Sie ein. Im Anschluss erhalten Sie eine E-Mail mit den
          Überweisungsdaten für Ihre Starteinzahlung.
        </p>
      )}
    </>
  );

  const parentLegitimationHint = (
    <>
      <p>
        Die Legitimation Ihrer Person ist noch nicht abgeschlossen. Führen Sie
        den Legitimationsprozess über einen der untenstehenden Buttons oder
        mithilfe der Ihnen zugestellten E-Mail aus, um sich bei Smavesto zu
        legitimieren. Sobald dieser Prozess erfolgreich durchgeführt ist,
        erhalten Sie eine weitere E-Mail zur Bestätigung und können mit Ihrem
        Depot durchstarten.
      </p>
      {isDebitOrder && hasStartAmount ? (
        <p>
          Nach Ihrer Legitimation veranlassen wir die Lastschrift Ihrer
          gewünschten Starteinzahlung für Sie.
        </p>
      ) : (
        <p>
          Nach Ihrer Legitimation richtet unser Partner, die Baader Bank, Ihr
          Depot für Sie ein. Im Anschluss erhalten Sie eine E-Mail mit den
          Überweisungsdaten für Ihre Starteinzahlung.
        </p>
      )}
    </>
  );

  const juniorLegitimationHint = (
    <>
      <p>
        Die Legitimation Ihres Kindes ist noch nicht abgeschlossen. Führen Sie
        den Legitimationsprozess über einen der untenstehenden Buttons oder
        mithilfe der Ihnen zugestellten E-Mail aus, um sich bei Smavesto zu
        legitimieren. Sobald dieser Prozess erfolgreich durchgeführt ist,
        erhalten Sie eine weitere E-Mail zur Bestätigung und können mit Ihrem
        Depot durchstarten.
      </p>
      {isDebitOrder && hasStartAmount ? (
        <p>
          Nach der Legitimation Ihres Kindes veranlassen wir die Lastschrift
          Ihrer gewünschten Starteinzahlung für Ihr Kind.
        </p>
      ) : (
        <p>
          Nach der Legitimation Ihres Kindes richtet unser Partner, die Baader
          Bank, Ihr Depot für Sie ein. Im Anschluss erhalten Sie eine E-Mail mit
          den Überweisungsdaten für Ihre Starteinzahlung.
        </p>
      )}
    </>
  );

  const getHintForViewState = () => {
    if (isParentLegitimationRequired && !isJuniorDepotLegitimationRequired)
      return parentLegitimationHint;

    if (!isParentLegitimationRequired && isJuniorDepotLegitimationRequired) {
      return juniorLegitimationHint;
    }
    if (isParentLegitimationRequired && isJuniorDepotLegitimationRequired) {
      return bothLegitimationHint;
    }

    throw new Error("Invalid State");
  };

  if (isLoading) return <Skeleton />;

  return (
    <OuterHint title="Depothinweis">
      {getHintForViewState()}

      {isParentLegitimationRequired && (
        <Row
          justify="center"
          style={{ marginTop: 12, gap: "1rem", flexDirection: "column" }}
        >
          <CancellationTooltip style={{ display: "none", width: "100%" }}>
            <SButton
              onClick={() =>
                router.push(
                  `/dashboard/${loggedInUserUri}/legitimation-vor-ort`
                )
              }
              className="reset"
            >
              {isAncekaTheme ? (
                <>Meine Legitimation bei AnCeKa</>
              ) : (
                <>
                  Meine Legitimation bei einer teilnehmenden Sparkasse
                  <PopoverInfo
                    title="Legitimation bei einer Sparkasse"
                    text="Sie haben die Möglichkeit sich bei den folgenden teilnehmenden Sparkassen legitimieren zu lassen: Sparkasse Bremen, Sparkasse Duisburg"
                  >
                    {" "}
                  </PopoverInfo>
                </>
              )}
            </SButton>
          </CancellationTooltip>

          <CancellationTooltip>
            <SButton
              onClick={() =>
                router.push(`/dashboard/${loggedInUserUri}/legitimation-video`)
              }
              className="reset"
            >
              Zu meiner Videolegitimation
            </SButton>
          </CancellationTooltip>
        </Row>
      )}

      {isJuniorDepotLegitimationRequired && (
        <Row
          justify="center"
          style={{
            marginTop: isParentLegitimationRequired ? 32 : 12,
            gap: "1rem",
            flexDirection: "column"
          }}
        >
          <CancellationTooltip style={{ display: "none", width: "100%" }}>
            <SButton
              onClick={() =>
                router.push(
                  `/dashboard/${onboardingDepot?.userUri}/legitimation-vor-ort`
                )
              }
              className="reset"
            >
              {isAncekaTheme ? (
                <>Legitimation bei AnCeKa für mein Kind</>
              ) : (
                <>
                  Legitimation bei einer teilnehmenden Sparkasse
                  <PopoverInfo
                    title="Legitimation bei einer Sparkasse"
                    text="Sie haben die Möglichkeit sich bei den folgenden teilnehmenden Sparkassen legitimieren zu lassen: Sparkasse Bremen, Sparkasse Duisburg"
                  />{" "}
                  &nbsp; für mein Kind
                </>
              )}
            </SButton>
          </CancellationTooltip>

          <CancellationTooltip>
            <SButton
              onClick={() =>
                router.push(
                  `/dashboard/${onboardingDepot?.userUri}/legitimation-video`
                )
              }
              className="reset"
            >
              Zur Videolegitimation meines Kindes
            </SButton>
          </CancellationTooltip>
        </Row>
      )}
    </OuterHint>
  );
}

export default LegitimationRequiredDepotMessage;
