import PageWrapper from "@src/components/layout/template/PageWrapper/PageWrapper";
import { SDashboardCol } from "@src/styled-components/SDashboardCol";
import { SDashboardContentWrapper } from "@src/styled-components/SDashboardContentWrapper";
import { Row, Skeleton } from "antd";

const DashboardSkeleton: React.FunctionComponent = () => {
  return (
    <PageWrapper title="Bitte warten...">
      <SDashboardContentWrapper justify="space-between">
        <>
          <SDashboardCol span={24} margin="0 0 60px">
            <Row>
              <Skeleton active />
            </Row>
          </SDashboardCol>

          <SDashboardCol md={24} lg={9} margin="0 0 60px">
            <Skeleton active style={{ width: 270 }} />
          </SDashboardCol>

          <SDashboardCol md={24} lg={14} margin="0 0 60px">
            <Skeleton active style={{ width: 270 }} />
          </SDashboardCol>
        </>
      </SDashboardContentWrapper>
    </PageWrapper>
  );
};

export default DashboardSkeleton;
