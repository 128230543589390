import { LikeOutlined, MailOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { RiThumbUpLine } from "react-icons/ri";
import styled from "styled-components";

import { ThemeContext } from "@pages/_app";
import theme from "@styles/theme";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";

import LiveDepotIcon from "../../../../assets/livedepot.svg";

export const SIconCircleComponent = (theme: AppTheme) => styled(props => (
  <div {...props} />
))`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: ${theme.palette.primary};

  border-radius: 50%;
  font-size: 55px;
`;

export const SIconCircle: React.FunctionComponent<any> = props => {
  const { theme } = useContext(ThemeContext);

  const RenderComponent = SIconCircleComponent(theme);

  return <RenderComponent {...props} />;
};

export const SIcon = styled(LikeOutlined)`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px auto;

  background: ${theme.primary};
  border-radius: 50%;
  font-size: 55px;
`;

export const SMailIcon = styled(MailOutlined)`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px auto;
  border-radius: 50%;
  font-size: 58px;
`;

export const SLiveDepotIcon = styled(LiveDepotIcon)`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px auto;
  background: ${theme.primary};
  border-radius: 50%;
  font-size: 58px;
`;

export const SThumbsUpIcon = styled(RiThumbUpLine)`
  width: 100px;
  height: 100px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin: 24px auto;
  background: ${theme.primary};
  border-radius: 50%;
`;
