import moment from "moment";

export const TimeRanges = {
  FromBegin: "FROM_BEGIN",
  Last12Month: "LAST_12_MONTH",
  TwoYearsAgo: "TWO_YEARS_AGO",
  OneYearAgo: "ONE_YEAR_AGO",
  WholeYear: "WHOLE_YEAR",
  Last7Days: "LAST_7_DAYS"
};

const DateFormat = "DD.MM.YYYY";

export const DepotTimeRanges = [
  {
    rangeId: TimeRanges.FromBegin,
    getFrom: (openingDate: string) => moment(openingDate).format(DateFormat),
    getTo: () => moment().format(DateFormat),
    title: "Seit Eröffnung"
  },
  {
    rangeId: TimeRanges.Last12Month,
    getFrom: () => moment().subtract(12, "months").format(DateFormat),
    getTo: () => moment().format(DateFormat),
    title: "12 Monate"
  },
  {
    rangeId: TimeRanges.TwoYearsAgo,
    getFrom: () =>
      moment(`01.01.${moment().year() - 2}`, DateFormat).format(DateFormat),
    getTo: () => moment().subtract(2, "year").endOf("year").format(DateFormat),
    title: `${moment().year() - 2}`
  },
  {
    rangeId: TimeRanges.OneYearAgo,
    getFrom: () =>
      moment(`01.01.${moment().year() - 1}`, DateFormat).format(DateFormat),
    getTo: () => moment().subtract(1, "year").endOf("year").format(DateFormat),
    title: `${moment().year() - 1}`
  },
  {
    rangeId: TimeRanges.WholeYear,
    getFrom: () => moment().startOf("year").format(DateFormat),
    getTo: () => moment().format(DateFormat),
    title: `${moment().year()}`
  },
  {
    rangeId: TimeRanges.Last7Days,
    getFrom: () => moment().subtract(7, "days").format(DateFormat),
    getTo: () => moment().format(DateFormat),
    title: "7 Tage"
  }
];
