import { Col, Divider, Row } from "antd";
import { useRouter } from "next/router";
import React from "react";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import type { ICompBaseProps } from "@src/interfaces/jsx.interface";
import { isStartAmountLargeState } from "@src/state/risk-yield.state";
import getAllPreviousSteps from "@src/workflows/engine/methods/getAllPreviousSteps";
import type WorkflowStep from "@src/workflows/engine/models/WorkflowStep";
import underageOnboardingStepsFirstGuardian from "@src/workflows/onboarding-underage/workflow-paths/underageOnboardingStepsFirstGuardian";
import underageOnboardingStepsSecondGuardian from "@src/workflows/onboarding-underage/workflow-paths/underageOnboardingStepsSecondGuardian";
import { loggedInUserUriState } from "@states/auth.state";
import {
  activeOnboardingDepotState,
  userIsSecondGuardianState
} from "@states/depot.state";
import {
  onboardingByEnvironmentState,
  onboardingUserCompletedSummaryPageState
} from "@states/onboarding.state";
import theme from "@styles/theme";
import removeQueryString from "@utils/query-params/removeQueryString";
import { bundledLoadingState } from "@utils/recoil/bundledLoadingState";

import useThemedModal from "@src/theme/hook/useThemedModal";
import isLegitimated from "smavesto.core/lib/utils/typeguards/isLegitimated";
import InfoCollapseBox from "../../dashboard/InfoCollapseBox";
import ContactWidget from "../../widgets/ContactWidget";
import { MenuButton } from "./MenuButton";
import { OnboardingUnderageNavigationSkeleton } from "./OnboardingUnderageNavigationSkeleton";
import styles from "./style.module.less";

interface OnboardingUnderageNavigationProps extends ICompBaseProps {
  completedChapters: string[];
}

export const OnboardingUnderageNavigation: React.FunctionComponent<
  OnboardingUnderageNavigationProps
> = ({ completedChapters }) => {
  const { contextHolder, openModal } = useThemedModal(true);

  const { push, route, query } = useRouter();

  const isNotLoggedInSecondGuardian = !!query.continueOnboarding;

  const [, userUri] = useAsyncSelector(loggedInUserUriState);

  const [isStartAmountLargeLoadingState, isStartAmountLarge] = useAsyncSelector(
    isStartAmountLargeState("activeOnboardingOwner")
  );
  const [userIsSecondGuardianLoadingState, userIsSecondGuardian] =
    useAsyncSelector(userIsSecondGuardianState);

  const [, onboardingUserCompletedSummary] = useAsyncSelector(
    onboardingUserCompletedSummaryPageState
  );

  const [onboardingLoadingState, onboarding] = useAsyncSelector(
    onboardingByEnvironmentState("loggedInUser")
  );

  const menuLoadingState = bundledLoadingState([
    isStartAmountLargeLoadingState,
    userIsSecondGuardianLoadingState,
    onboardingLoadingState
  ]);

  const [, activeOnboarding] = useAsyncSelector(activeOnboardingDepotState);

  const underageOnboardingSteps =
    userIsSecondGuardian || isNotLoggedInSecondGuardian
      ? underageOnboardingStepsSecondGuardian
      : underageOnboardingStepsFirstGuardian(!!activeOnboarding);

  // We disable all personal data steps of the guardian if this user is allready legitimated
  const userIsLegitimated = onboarding ? isLegitimated(onboarding) : false;

  const isLoading = menuLoadingState === "loading";

  const legitimatedUserDisabledSteps = [
    "PERSOENLICHE_ANGABEN_SORGEBERECHTIGTER",
    "KENNTNISSE_SORGEBERECHTIGTER"
  ];

  const userIsLoggedIn = !!userUri;

  const loggedInUserDisabledSteps = ["ZUGANGSDATEN"];

  const disabledSteps = userIsLegitimated ? legitimatedUserDisabledSteps : [];

  const isActiveStep = (step: WorkflowStep) => {
    return (
      route === removeQueryString(step.to) ||
      step.subSteps?.some(step => route === removeQueryString(step.to))
    );
  };

  const isDisabled = (step: WorkflowStep): boolean => {
    // We ignore this if the user has this step currently open
    if (isActiveStep(step)) return false;

    const previousSteps = getAllPreviousSteps(
      step.chapterName,
      underageOnboardingSteps
    );

    if (previousSteps.some(previousStep => !isCompleted(previousStep)))
      return true;

    return disabledSteps.includes(step.chapterName);
  };

  const isDisabledForLoggedInUsers = (step: WorkflowStep): boolean => {
    return (
      userIsLoggedIn && loggedInUserDisabledSteps.includes(step.chapterName)
    );
  };

  const isCompleted = (chapterName: string): boolean => {
    /* Workaround for the missing chapter at DSER */
    if (chapterName === "SUMMARY" && onboardingUserCompletedSummary === true)
      return true;

    return completedChapters.includes(chapterName);
  };

  if (isLoading) return <OnboardingUnderageNavigationSkeleton />;

  return (
    <div className={styles["navigation-wrapper"]}>
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: 25,
              paddingTop: 0,
              borderRight: "1px solid rgba(255,255,255,0.1)"
            }}
          >
            {underageOnboardingSteps.map((section, idx) => (
              <div key={`navsec_${section.order}`}>
                {section.items.length > 0 && section.title && (
                  <div>
                    <Divider style={{ borderColor: "#1b4040" }} />
                    <p className={styles["section-title"]}>{section.title}</p>
                  </div>
                )}
                {section.items.map((step, index) => (
                  <div
                    style={{ marginTop: idx === 0 ? 4 : 20 }}
                    key={step.order}
                  >
                    <MenuButton
                      style={{
                        boxShadow: isActiveStep(step)
                          ? `0 0 10px 5px ${theme.primary}`
                          : "none"
                      }}
                      disabled={isDisabled(step)}
                      title={step.text}
                      order={(index + 1).toString()}
                      complete={isCompleted(step.chapterName)}
                      hidden={false}
                      onClick={() =>
                        isDisabledForLoggedInUsers(step)
                          ? openModal("info", {
                              title: "Hinweis",
                              content: (
                                <>
                                  Sie haben sich bereits bei Smavesto
                                  registriert und können Ihre Zugangsdaten nach
                                  Ihrer Legitimation in Ihrem Dashboard
                                  anpassen.
                                </>
                              )
                            })
                          : push({
                              pathname: removeQueryString(step.to),
                              query
                            })
                      }
                    />
                    {step.subSteps?.map(subStep => (
                      <div style={{ marginTop: 4 }} key={subStep.order}>
                        <MenuButton
                          hideOrderCircle
                          style={{
                            boxShadow: isActiveStep(subStep)
                              ? `0 0 10px 3px ${theme.primary}`
                              : "none"
                          }}
                          title={subStep.text}
                          order={idx.toString()}
                          complete={isCompleted(subStep.chapterName)}
                          disabled={isDisabled(subStep)}
                          hidden={false}
                          onClick={() =>
                            push({
                              pathname: removeQueryString(subStep.to),
                              query
                            })
                          }
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
            {route === "/onboarding/summary2" ? (
              <InfoCollapseBox
                title={
                  isStartAmountLarge
                    ? "Überweisung Ihres Startbetrags"
                    : "Lastschrift Ihres Startbetrags"
                }
              >
                {isStartAmountLarge
                  ? "Nachdem unsere Partnerbank, die Baader Bank, das Depot eingerichtet hat, erhalten Sie im Anschluss eine E-Mail mit den Überweisungsdaten für die Starteinzahlung."
                  : "Im Zuge der Depoteröffnung veranlassen wir die Lastschrift der gewünschten Starteinzahlung für Sie."}
              </InfoCollapseBox>
            ) : (
              <ContactWidget
                title="Haben Sie Fragen?"
                content="Unser Relationship Manager Tim Bergmann und sein Team sind für Sie da."
              />
            )}
          </div>
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
};
