import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";

const ReallocationTooltip: React.FunctionComponent = () => (
  <PopoverInfo
    title="Reallokation"
    text={
      <>
        Unter Reallokation versteht man die Verteilung des Vermögens in
        unterschiedliche Anlageklassen (z.B. Aktien, Geldmarkt..) unter
        Berücksichtigung der Risikoklasse.
      </>
    }
  />
);

export default ReallocationTooltip;
