import { ThemeContext } from "@pages/_app";
import React, { useMemo } from "react";
import type { AppTheme } from "smavesto.core";
import type { AppThemeSetting } from "smavesto.core/lib/static/themes/AppThemeSetting";
import type { CSSProperties } from "styled-components";
import useTheme from "../hook/useTheme";

interface ThemeProviderProps extends React.PropsWithChildren {}

export type ThemeContextType = {
  theme: AppTheme;
  cssProperties: CSSProperties;
  appModeTheme?: AppThemeSetting;
  inputCssProperties: CSSProperties;
};

const ThemeProvider: React.FunctionComponent<ThemeProviderProps> = ({
  children
}) => {
  const [
    theme,
    cssProperties,
    isInitialized,
    appModeTheme,
    inputCssProperties
  ] = useTheme();

  const value = useMemo(
    () => ({
      theme,
      cssProperties,
      appModeTheme,
      inputCssProperties
    }),
    [theme, cssProperties, appModeTheme]
  );

  if (!isInitialized) return null;

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
