import { useSupplierAndAffiliateOrigin } from "@hooks/onboarding/affiliate/useSupplierAndAffiliateOrigin";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { getRequestState } from "@src/state/request.state";
import { userUriState } from "@states/auth.state";
import {
  themeCurrentDepotSelector,
  themeOnboardingOwnerSelector
} from "@states/theme.state";
import { bundledLoadingState } from "@utils/recoil/bundledLoadingState";

import {
  lightModeState,
  localLightModeState,
  localThemeOverWriteState,
  remoteLightModeState
} from "@src/state/all.states";
import { currentDepotState } from "@src/state/current.depot.state";
import { activeOnboardingDepotState } from "@src/state/depot.state";
import { getCookie, setCookie } from "@src/utils/cookies";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { ConfigProvider } from "antd";
import type { CSSProperties } from "react";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { getAppThemeForSupplierId } from "smavesto.core/lib/business-logic/supplier/getThemeForSupplierID";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import type { AppThemeSetting } from "smavesto.core/lib/static/themes/AppThemeSetting";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";
import premiumTheme from "smavesto.core/lib/static/themes/premiumTheme";
import werderTheme from "smavesto.core/lib/static/themes/werderTheme";
import useIsLogin from "./useIsLogin";
import useIsOnboarding from "./useIsOnboarding";

/* This hook allows you to determine the theme based on the supplierId */
function useTheme(): [
  AppTheme,
  CSSProperties,
  boolean,
  AppThemeSetting | undefined,
  CSSProperties
] {
  const themes = [defaultTheme, werderTheme, premiumTheme];

  const [, overWriteTheme] = useAsyncSelector(localThemeOverWriteState);

  const [, isLightMode] = useAsyncSelector(lightModeState);

  const [, remoteLightMode] = useAsyncSelector(remoteLightModeState);

  const setLocalLightMode = useSetRecoilState(localLightModeState);

  const [, themeReq] = useAsyncSelector(getRequestState("theme"));

  const isOnboarding = useIsOnboarding();

  const isLogin = useIsLogin();

  /* Affiliate data from cookie and url parameter */
  const { affiliateData, affiliateDataIsLoading } =
    useSupplierAndAffiliateOrigin(false);

  const [userUriLoadingState, loggedInUserUri] = useAsyncSelector(
    userUriState("loggedInUser")
  );

  const [activeOnboardingDepotLoadingState, activeOnboardingDepot] =
    useAsyncSelector(activeOnboardingDepotState);

  const [currentDepotLoadingState, currentDepot] =
    useAsyncSelector(currentDepotState);

  const [currentDepotOwnerLoadingState, activeOnboardingUserUri] =
    useAsyncSelector(userUriState("activeOnboardingOwner"));

  const [activeOnboardingLoadingState, currentDepotOwnerUserUri] =
    useAsyncSelector(userUriState("currentDepotOwner"));

  const [supplierThemeLoadingState, supplierTheme] = useAsyncSelector(
    isOnboarding ? themeOnboardingOwnerSelector : themeCurrentDepotSelector
  );

  const isLoading =
    bundledLoadingState([
      supplierThemeLoadingState,
      userUriLoadingState,
      activeOnboardingLoadingState,
      activeOnboardingDepotLoadingState,
      currentDepotLoadingState,
      currentDepotOwnerLoadingState
    ]) === "loading" || affiliateDataIsLoading;

  const [theme, setTheme] = useState<AppTheme>(defaultTheme);

  const applySavedTheme = (theme: AppTheme) => {
    setTheme(overWriteTheme || theme);
  };

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading === false) {
      setIsInitialized(true);
      loadAndSaveThemeInLocalStorage();
    }
  }, [isLoading, currentDepotOwnerUserUri, supplierTheme]);

  useEffect(() => {
    setTimeout(() => {
      const lightModeCookie = getCookie("lightmode", window.document.cookie);

      if (lightModeCookie === undefined) {
        setLocalLightMode(remoteLightMode === true);
        setCookie("lightmode", remoteLightMode === true ? "on" : "off", 365);
        return;
      }

      if (lightModeCookie === "on") {
        setLocalLightMode(true);
      } else {
        setLocalLightMode(false);
      }
    }, 1000);
  }, []);

  const loadAndSaveThemeInLocalStorage = async () => {
    const clientTheme = affiliateData?.supplierID
      ? await getAppThemeForSupplierId(
          affiliateData?.supplierID,
          smavestoCore,
          isOnboarding ? activeOnboardingDepot : currentDepot
        )
      : undefined;

    if (loggedInUserUri && !isOnboarding) {
      if (supplierTheme) {
        localStorage.setItem("cachedTheme", supplierTheme.key);
        applySavedTheme(supplierTheme);
      } else {
        localStorage.setItem("cachedTheme", "default");
        applySavedTheme(defaultTheme);
      }
    } else if (isOnboarding && clientTheme && !activeOnboardingUserUri) {
      localStorage.setItem("cachedTheme", clientTheme.key);
      applySavedTheme(clientTheme);
    } else if (isOnboarding && !activeOnboardingUserUri) {
      localStorage.setItem("cachedTheme", defaultTheme.key);
      applySavedTheme(defaultTheme);
    } else if (supplierTheme && isOnboarding && activeOnboardingUserUri) {
      localStorage.setItem("cachedTheme", supplierTheme.key);
      applySavedTheme(supplierTheme);
    } else if (isLogin && clientTheme) {
      applySavedTheme(clientTheme);
    }
  };

  const getThemeFromLocalStorage = () => {
    const cachedTheme = localStorage.getItem("cachedTheme");

    if (cachedTheme) {
      const themeToSet =
        themes.find(theme => theme.key === cachedTheme) || defaultTheme;
      setTheme(overWriteTheme || themeToSet);
    }
  };

  useEffect(() => {
    if (overWriteTheme) setTheme(overWriteTheme);
  }, [overWriteTheme]);

  /* Provide the global theme configuration on every theme change */
  useEffect(() => {
    getThemeFromLocalStorage();

    if (isInitialized) loadAndSaveThemeInLocalStorage();
  }, [isInitialized, isOnboarding, themeReq]);

  ConfigProvider.config({
    theme: {
      primaryColor: theme.palette.primary,
      warningColor: theme.palette.iconColor,
      infoColor: theme.palette.iconColor
    }
  });

  const modeTheme =
    isLightMode && theme.lightMode ? theme.lightMode : theme.darkMode;

  const inputCssProperties: CSSProperties =
    modeTheme?.colorSchema === "light"
      ? {
          borderRadius: 30,
          outline: `1px solid black`,
          border: "0px",
          background: "white",
          color: "inherit"
        }
      : {
          borderRadius: 30,
          background: "linear-gradient(0.25turn,#80808057,#002d2f34)",
          color: modeTheme?.secondLayerTextColor || "white"
        };

  /* BasicPage style options (background image and background color) */
  const rootCssProperties: CSSProperties = {
    backgroundColor: theme.palette.backgroundColor,
    backgroundImage:
      theme.assets.backgroundImage && `url(${theme.assets.backgroundImage})`,
    backgroundSize: "cover",
    height: "fit-content",
    minHeight: "100vh"
  };

  return [
    theme,
    rootCssProperties,
    isInitialized,
    modeTheme,
    inputCssProperties
  ];
}

export default useTheme;
