import { atom, atomFamily, selector, selectorFamily } from "recoil";

import { userUriState } from "@states/auth.state";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type InboxDto from "smavesto.core/lib/types/dto/inbox/InboxDto";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import { getCategories } from "./inbox.state.helpers";
import { getRequestState, useRefreshState } from "./request.state";

export const currentFileIdState = atom({
  key: "currentFileId",
  default: ""
});

export const docsAsideAmountState = atom({
  key: "docsAsideAmount",
  default: 0
});

export const clientSideReadDocumentsState = atom<string[]>({
  key: "clientSideReadDocuments",
  default: []
});

export const inboxState = selectorFamily({
  key: "inbox",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      if (!userUri) return [];

      get(getRequestState("inbox", userUri));

      return userUri
        ? successOrUndefined<InboxDto[]>(
            await smavestoCore.services.inbox.getInbox(userUri)
          ) ?? []
        : [];
    }
});

export const inboxForUserUriState = selectorFamily({
  key: "inboxForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("inbox", userUri));

      return successOrUndefined<InboxDto[]>(
        await smavestoCore.services.inbox.getInbox(userUri)
      );
    }
});

export const inboxToConfirmState = selectorFamily({
  key: "inboxToConfirm",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("inboxToConfirm", userUri));

      if (!userUri) return [];

      return userUri
        ? successOrUndefined<InboxDto[]>(
            await smavestoCore.services.inbox.getInboxToConfirm(userUri)
          ) ?? []
        : [];
    }
});

export const useRefreshInboxToConfirm = (type: StateEnvironmentUser) =>
  useRefreshState("inboxToConfirm", type);

export const inboxCategoriesState = selectorFamily({
  key: "inboxCategories",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const inbox = get(inboxState(type));
      const inboxToConfirm = get(inboxToConfirmState(type));

      return getCategories(inbox, inboxToConfirm);
    }
});

export const categoriesFilterState = atomFamily<number, StateEnvironmentUser>({
  key: "categoriesFilter",
  default: (type: StateEnvironmentUser) =>
    selector({
      key: "categoriesFilter/Default",
      get: ({ get }) => {
        const inboxToConfirm = get(inboxToConfirmState(type));

        if (!inboxToConfirm) return 0;

        return inboxToConfirm.length > 0 ? 6 : 0;
      }
    })
});

export const currentCategoryState = selectorFamily({
  key: "currentCategory",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const filter = get(categoriesFilterState(type));

      const categories = get(inboxCategoriesState(type));

      return categories[filter];
    }
});

export const fileByIdState = selectorFamily({
  key: "fileById",
  get:
    (userUri: string) =>
    async ({ get }) => {
      const id = get(currentFileIdState);

      if (!id) return undefined;

      const files = get(inboxForUserUriState(userUri));

      return (files || []).find(f => f.attachments[0]?.id === id);
    }
});

export const showConfirmDocsState = selectorFamily({
  key: "showConfirmDocs",
  get:
    (type: StateEnvironmentUser) =>
    ({ get }) => {
      const inboxToConfirm = get(inboxToConfirmState(type));

      const docsAsideAmount = get(docsAsideAmountState);

      const isShowModal =
        inboxToConfirm.length > 0 && docsAsideAmount !== inboxToConfirm.length;

      return isShowModal;
    }
});

export const useRefreshInbox = (type: StateEnvironmentUser) =>
  useRefreshState("inbox", type);
