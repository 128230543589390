export const RULES = {
  custodyType: [{ required: true, message: "Bitte wählen Sie etwas aus." }],
  gender: [{ required: true, message: "Bitte wählen Sie Ihre Anrede aus." }],

  zustimmenGesetzlicheVertreter: [
    { required: true, message: "Bitte bestätigen." }
  ],

  firstName: [
    { required: true, message: "Bitte geben Sie Ihren Vornamen ein." },
    {
      pattern: /^[\u00C0-\u017Fa-zA-Z- ,.'-]+$/,
      message: "Dieses Feld darf keine Zahlen enthalten"
    }
  ],

  lastName: [
    { required: true, message: "Bitte geben Sie Ihren Nachnamen ein." },
    {
      pattern: /[\u00C0-\u017Fa-zA-Z- ,.'-]+$/,
      message: "Dieses Feld darf keine Zahlen enthalten."
    }
  ]
};

export const CUSTODY_TYPES = [
  {
    label: "Wir tragen das gemeinsame Sorgerecht",
    value: "JOINT_CUSTODY"
  },
  {
    label: "Ich habe das alleinige Sorgerecht",
    value: "SOLE_CUSTODY"
  }
];

export const LABEL_INFO = {
  custodyType: {
    title: "Wie ist die Sorgerechtsverteilung bei Ihrem Kind?",
    text: ""
  }
};

export const RADIO_GENDERS = [
  { label: "Herr", value: "MALE" },
  { label: "Frau", value: "FEMALE" }
];

export const OPTION_CHECKBOX_EMAIL = {
  id: 1,
  value: 1,
  label:
    "Ich bestätige, dass ich den weiteren gesetzlichen Vertreter über die hier von mir über ihn angegebene Daten informiert habe und er damit einverstanden ist im Anschluss eine E-Mail von Smavesto zu erhalten.*"
};

export const RADIO_TAN = [
  { label: "TAN per SMS senden", value: "sms" },
  { label: "TAN per E-Mail senden", value: "email" }
];
