import type { ICompBaseProps } from "@src/interfaces/jsx.interface";
import React from "react";

import { SButton } from "@src/styled-components/form/SButtons";
import { StyledButton } from "./StyledButton";

interface IProps extends ICompBaseProps {
  title: string;
  htmlType: string;
  onClick?: any;
  style?: any;
  loading?: boolean;
  testId?: string;
  disabled?: boolean;
}

export const ActionButtonComponent: React.FC<IProps> = ({
  title,
  htmlType,
  onClick,
  style,
  loading,
  testId,
  disabled
}) => {
  return (
    <StyledButton style={style} data-testid={testId}>
      {htmlType === "submit" ? (
        <SButton
          type="primary"
          onClick={onClick}
          htmlType="submit"
          className="submit"
          disabled={disabled}
          loading={loading}
        >
          {title}
        </SButton>
      ) : htmlType === "reset" ? (
        <SButton
          type="primary"
          onClick={onClick}
          htmlType="reset"
          className="reset"
          disabled={disabled}
          loading={loading}
        >
          {title}
        </SButton>
      ) : htmlType === "action" ? (
        <SButton
          type="primary"
          onClick={onClick}
          className="reset"
          disabled={disabled}
          loading={loading}
        >
          {title}
        </SButton>
      ) : htmlType === "link" ? (
        <SButton
          className="link"
          onClick={onClick}
          type="link"
          disabled={disabled}
          loading={loading}
        >
          {title}
        </SButton>
      ) : (
        <SButton
          type="primary"
          onClick={onClick}
          htmlType="button"
          className="general"
          disabled={disabled}
          loading={loading}
        >
          {title}
        </SButton>
      )}
    </StyledButton>
  );
};
