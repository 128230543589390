import { Button } from "antd";
import { Suspense, useState } from "react";

import { loggedInUserUriState } from "@states/auth.state";
import { currentMassOrderIDState } from "@states/simulate.state";
import { SH2, SP, SSection } from "@styled-components/STags";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import CancellationTooltip from "../../form/tooltips/CancellationTooltip";
import { MassOrderSelect } from "./MassOrderSelect";

export function MassOrder() {
  const [, userUri] = useAsyncSelector(loggedInUserUriState);

  const [, currentMassOrderID] = useAsyncSelector(currentMassOrderIDState);

  const [loading, setLoading] = useState(false);

  const onMassOrderSimulate = async () => {
    if (!userUri || !currentMassOrderID) return;

    setLoading(true);

    await smavestoCore.services.simulate.massOrder(userUri, currentMassOrderID);

    setLoading(false);
  };

  return (
    <SSection>
      <SH2 fontSize="14px" fontWeight={700}>
        MassOrder simulieren
      </SH2>
      <SP color="#000" margin="0 0 8px">
        BlockOrders (bestätigt und submitted!)
      </SP>
      <Suspense fallback={<div>Bitte warten - Daten werden geladen</div>}>
        <MassOrderSelect />
      </Suspense>

      <CancellationTooltip>
        <Button
          style={{ width: 300 }}
          loading={loading}
          onClick={onMassOrderSimulate}
        >
          BlockOrder simulieren
        </Button>
      </CancellationTooltip>
    </SSection>
  );
}
