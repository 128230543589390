import cx from "classnames";
import React from "react";

import { FooterPublic } from "@components/layout/page/footer/FooterPublic";
import type { ILayoutBaseProps } from "@src/interfaces/jsx.interface";

import BasicPageLayout from "../layout/BasicPageLayout";
import styles from "./styles.module.less";

export interface PublicLayoutProps extends ILayoutBaseProps {}
const PublicLayout: React.FunctionComponent<PublicLayoutProps> = props => {
  return (
    <BasicPageLayout redirectToSmavestoDeOnLogoClick>
      <div
        className={cx(styles["layout-container"], "g-layout-container--open")}
      >
        <props.mainComp router={props.routeProps} pageProps={props.pageProps} />
      </div>

      <div className={cx(styles["layout-footer"], "g-layout-footer--master")}>
        <FooterPublic />
      </div>
    </BasicPageLayout>
  );
};

export default PublicLayout;
