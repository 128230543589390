import { Suspense, useContext, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import {
  onboardingByEnvironmentState,
  useRefreshOnboarding
} from "@states/onboarding.state";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";
import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import {
  devThemeConfiguationWindowState,
  localLightModeState
} from "@src/state/all.states";
import { currentDepotState } from "@src/state/current.depot.state";
import {
  simulateDateState,
  simulateDepotError,
  simulateDepotErrorBalanceDate,
  simulateFeatureFlagsLikeProductionState
} from "@src/state/simulate.state";
import { SCustomDivider } from "@src/styled-components/SCustomDivider";
import { openIndependentInfoModal } from "@src/theme/modal/openThemedModal";
import { loggedInUserUriState } from "@states/auth.state";
import SSwitch from "@styled-components/form/SSwitch";
import { Button, Collapse } from "antd";
import moment from "moment";
import { useRouter } from "next/router";
import { assertNotEmpty } from "smavesto.core";
import handleApiWrapper from "smavesto.core/lib/engine/wrapper/handleApiWrapper";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { DirectDeposit } from "./DirectDeposit";
import KeyValueStoreValue from "./KeyValueStoreValue";
import { LiveSimulateList } from "./LiveSimulateList";
import { MassOrder } from "./MassOder";

export function OnboardingStateSimulate() {
  const [, userUri] = useAsyncSelector(loggedInUserUriState);

  const [displayDevThemeConfiguration, setDisplayDevThemeConfiguration] =
    useRecoilState(devThemeConfiguationWindowState);

  const { push } = useRouter();

  const [handleApiError] = useApiErrorHandler();

  const [, onboarding] = useAsyncSelector(
    onboardingByEnvironmentState("loggedInUser")
  );

  const [
    simulateFeatureFlagsLikeProduction,
    setSimulateFeatureFlagsLikeProduction
  ] = useRecoilState(simulateFeatureFlagsLikeProductionState);

  const [islightMode, setLightmode] = useRecoilState(localLightModeState);

  const [depotError, setDepotError] = useRecoilState(simulateDepotError);

  const [depotErrorBalance, setDepotErrorBalance] = useRecoilState(
    simulateDepotErrorBalanceDate
  );

  const [simulateDate, setSimulateDateState] =
    useRecoilState(simulateDateState);

  const { theme } = useContext(ThemeContext);

  const refreshOnboarding = useRefreshOnboarding("loggedInUser");

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const [loadingChangeDirectAccess, setLoadingChangeDirectAccess] =
    useState(false);

  const [loadingChangeGoToOnboarding, setLoadingChangeGoToOnboarding] =
    useState(false);

  const simulateInvalidSession = () => {
    assertNotEmpty(userUri);

    smavestoCore.services.auth.logout(userUri).then(() =>
      openIndependentInfoModal(
        {
          title: "Ihre Session wurde im Hintergrund beendet",
          content:
            "Sie erleben nun den Zustand den ein Nutzer erlebt, wenn seine Session gerade abgelaufen ist."
        },
        defaultTheme
      )
    );
  };

  const resetAppRating = () => {
    assertNotEmpty(userUri);

    smavestoCore.services.keyValueStore
      .setValue(userUri, "dashboard.rating.hideuntil", "empty")
      .then(() =>
        openIndependentInfoModal(
          {
            title: "Die App-Bewertung wurde zurückgesetzt.",
            content:
              "Wenn die Anforderungen an das Depot erfüllt werden und Sie die Seite neu laden, dann wird die Bewertung wieder eingefordert."
          },
          defaultTheme
        )
      );
  };

  const resetDepotStatusAllocation = () => {
    assertNotEmpty(userUri);

    smavestoCore.services.keyValueStore
      .setValue(
        userUri,
        `depotstatus.hint.allocation.${currentDepot?.id}`,
        "empty"
      )
      .then(() =>
        openIndependentInfoModal(
          {
            title: "Gelesener Allokationshinweis wurde zurückgesetzt",
            content:
              "Bitte lad die Seite einmal neu, um den Hinweis für das aktuelle Depot wieder zu sehen."
          },
          defaultTheme
        )
      );
  };

  const onDirectAccessChange = async (directAccess: boolean) => {
    if (!onboarding || !userUri) return;

    setLoadingChangeDirectAccess(true);

    await smavestoCore.services.onboarding.setDirectAccess(
      userUri,
      directAccess
    );

    refreshOnboarding();

    setLoadingChangeDirectAccess(false);
  };

  const onGoToOnboardingChange = async (goToOnboarding: boolean) => {
    if (!onboarding || !userUri) return;

    setLoadingChangeGoToOnboarding(true);

    await smavestoCore.services.onboarding.setGoToOnboarding(
      userUri,
      goToOnboarding
    );

    refreshOnboarding();

    setLoadingChangeGoToOnboarding(false);
  };

  const openAffiliateLink = (type: "supplierID" | "financeAds" | "uppr") => {
    if (type === "supplierID") {
      push(
        "/registration?utm_source=partner&supplierID=5e0859fd-a803-416b-b25b-fd044925e6e6"
      );
    } else if (type === "financeAds") {
      push(
        "/registration?utm_source=financeads&s_id=5e0859fd-a803-416b-b25b-fd044925e6e6"
      );
    } else if (type === "uppr") {
      if (smavestoCore.environment === "ad") {
        push(
          "https://trck.smavesto.de/trck/eclick/57ad948bced42e7afec615d24a48c654"
        );
      } else if (smavestoCore.environment === "as") {
        push(
          "https://trck.smavesto.de/trck/eclick/57ad948bced42e7a0192c912b82346dc"
        );
      } else if (smavestoCore.environment === "ap") {
        push(
          "https://trck.smavesto.de/trck/eclick/57ad948bced42e7a527d5678fdee9370"
        );
      } else {
        throw new Error("This Environment is not supported!");
      }
    }
  };

  const { Panel } = Collapse;

  return (
    <Collapse defaultActiveKey={["1"]}>
      <Panel header="Temporäre Manipulation" key="1">
        <SStateSimulate>
          <li>
            <SSwitch
              checkedChildren="Lightmode: aktiv"
              unCheckedChildren="Lightmode: inaktiv"
              checked={islightMode}
              onChange={value => setLightmode(value)}
            />

            <PopoverInfo
              title="Feature Flags"
              text="Manchmal aktivieren wir Features erst später im Live-System, obwohl sie auf dem Dev-System schon umgesetzt sind (so geschehen beim Werder und auch Junior-Depot). Um trotzdem zu sehen wie eine Seite im Live-System aussehen wird, habe ich euch den Switch Feature-Flag hinzugefügt."
            />
          </li>
          <li>
            <SSwitch
              checkedChildren="Feature-Flags: Live-Version"
              unCheckedChildren="Feature-Flags: Dev-Version"
              checked={simulateFeatureFlagsLikeProduction}
              onChange={value => setSimulateFeatureFlagsLikeProduction(value)}
            />

            <PopoverInfo
              title="Feature Flags"
              text="Manchmal aktivieren wir Features erst später im Live-System, obwohl sie auf dem Dev-System schon umgesetzt sind (so geschehen beim Werder und auch Junior-Depot). Um trotzdem zu sehen wie eine Seite im Live-System aussehen wird, habe ich euch den Switch Feature-Flag hinzugefügt."
            />
          </li>
          <li>
            <SSwitch
              checkedChildren="Echtkunde"
              unCheckedChildren="Test-Kunde"
              checked={onboarding?.directAccess}
              onChange={onDirectAccessChange}
              loading={loadingChangeDirectAccess}
            />

            <PopoverInfo
              title="Echtkunde"
              text="Eine Zeit lang wurde bei Usern mit dem Flag directAccesss zwischen Echt und Testkunden unterschieden. Inzwischen gibt es keine neuen Test-Depots mehr und wir verwenden dieses Flag nicht für Abfragen. "
            />
          </li>
          <li>
            <SSwitch
              checkedChildren="Direkt zum Onboarding"
              unCheckedChildren="Nicht direkt zum Onboarding"
              checked={onboarding?.goToOnboarding}
              onChange={onGoToOnboardingChange}
              loading={loadingChangeGoToOnboarding}
            />

            <PopoverInfo
              title="Direkt zum Onboarding"
              text="Wenn der Nutzer nur ein Depot hat wird über dieses Flag gesteuert, ob er direkt nach dem Login in den Onboardingprozess geleitet werden soll. In Zukunft wird dieses Flag so gut wie nicht mehr verwendet werden."
            />
          </li>
          <li>
            <SSwitch
              checkedChildren="Depot-Fehler"
              unCheckedChildren="Depot-Fehler"
              checked={depotError}
              onChange={value => setDepotError(!depotError)}
            />
          </li>
          <li>
            <SSwitch
              checkedChildren="Guthaben-Fehler"
              unCheckedChildren="Balance-Fehler"
              checked={depotErrorBalance}
              onChange={value => setDepotErrorBalance(!depotErrorBalance)}
            />
          </li>
          <li>
            <SSwitch
              checkedChildren="Simuliere Dezember"
              unCheckedChildren="Simuliere Dezember"
              checked={simulateDate.getMonth() === 11}
              onChange={value =>
                value
                  ? setSimulateDateState(moment("2024-12-01").toDate())
                  : setSimulateDateState(new Date())
              }
            />
          </li>
        </SStateSimulate>
      </Panel>
      <Panel header="Onboarding - Dauerhaft" key="2">
        <LiveSimulateList />
      </Panel>
      <Panel header="Session Manipulation" key="3">
        <Button
          style={{ width: 300 }}
          shape="default"
          onClick={simulateInvalidSession}
        >
          Abgelaufene Session simulieren{" "}
          <PopoverInfo
            title="Abgelaufene Session"
            text="Im Hintergrund wird ein Logout durchgeführt, die Seite aber nicht neu geladen. So wird simuliert wie die Seite sich verhält, wenn die Session abläuft."
          />
        </Button>
        <br />
        <br />
        {userUri ? (
          <KeyValueStoreValue
            keyName="dashboard.rating.hideuntil"
            userUri={userUri}
            transform={(value: string) => {
              if (
                value === "empty" ||
                value === "loading" ||
                value === "forever"
              )
                return value;
              return moment.unix(parseFloat(value)).format();
            }}
          />
        ) : (
          <i>useruri fehlt</i>
        )}
        <Button style={{ width: 300 }} shape="default" onClick={resetAppRating}>
          App-Bewertung zurücksetzen
        </Button>
        <br />
        <br />
        {userUri ? (
          <KeyValueStoreValue
            keyName={`depotstatus.hint.allocation.${currentDepot?.id}`}
            userUri={userUri}
            transform={(value: string) => {
              if (
                value === "empty" ||
                value === "loading" ||
                value === "forever"
              )
                return value;
              return moment.unix(parseFloat(value)).format();
            }}
          />
        ) : (
          <i>userUri fehlt</i>
        )}
        <Button
          style={{ width: 300 }}
          shape="default"
          onClick={resetDepotStatusAllocation}
        >
          Allokationshinweis zurücksetzen
        </Button>
      </Panel>
      <Panel header="Sentry" key="4">
        <Button
          style={{ width: 300 }}
          shape="default"
          onClick={() => {
            throw new Error("Simulated Error");
          }}
        >
          Fehler auslösen
        </Button>

        <Button
          style={{ width: 300 }}
          shape="default"
          onClick={async () => {
            const err = await handleApiWrapper(
              smavestoCore,
              "GET",
              "/api/test"
            );

            if (isApiErrorResponse(err)) handleApiError(err, "modal", "info");
          }}
        >
          Backend-Fehler auslösen
        </Button>
      </Panel>
      <Panel header="Einzahlung und Mass Order" key="5">
        <Suspense fallback={<div>Bitte warten - Daten werden geladen</div>}>
          <DirectDeposit />
        </Suspense>

        <SCustomDivider style={{ width: "80%", margin: "20px auto" }} />

        <MassOrder />
      </Panel>
      <Panel header="Werbepartner und Tracking" key="6">
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Button onClick={() => openAffiliateLink("financeAds")}>
            FinanceAds-Link öffnen
          </Button>
          <Button onClick={() => openAffiliateLink("uppr")}>
            Uppr-Link öffnen
          </Button>
          <Button onClick={() => openAffiliateLink("supplierID")}>
            SupplierID-Link öffnen
          </Button>
        </div>
      </Panel>
      <Panel header="Theming" key="7">
        <Button
          onClick={() =>
            setDisplayDevThemeConfiguration(!displayDevThemeConfiguration)
          }
        >
          Theme-Konfigurator{" "}
          {displayDevThemeConfiguration ? "schließen" : "öffnen"}
        </Button>
      </Panel>
    </Collapse>
  );
}
const SStateSimulate = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding: 4px 0;
  }
`;
