import { cancellationDateState } from "@states/cancellation.state";
import type { StateEnvironmentUser } from "@models/types/StateEnvironment";

import { useAsyncSelector } from "./useAsyncSelector";

export const useCancelation = (type: StateEnvironmentUser) => {
  const [state, cancelationDate] = useAsyncSelector(
    cancellationDateState(type)
  );

  const disableControl = state === "hasValue" && !!cancelationDate;

  return { disableControl, cancelationDate };
};
