import type { MutableRefObject } from "react";

export const handlerDebounce = <T extends unknown[]>(
  fn: (...params: T) => void,
  delay = 1000
) => {
  let timeout: NodeJS.Timeout;

  return (...params: T) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn(...params);
      clearTimeout(timeout);
    }, delay);
  };
};

type TimerRef = MutableRefObject<NodeJS.Timeout | null>;

export const sessionDebounce =
  <T extends unknown[]>(
    fn: (...params: T) => void,
    timerRef: TimerRef,
    delay = 10000
  ) =>
  (...params: T) => {
    // eslint-disable-next-line no-unused-expressions
    timerRef.current && clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      fn(...params);
      // eslint-disable-next-line no-unused-expressions
      timerRef.current && clearTimeout(timerRef.current);
    }, delay);
  };
