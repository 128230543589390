import DepotEntityItem from "@components/entities/depot/DepotEntityItem";
import { StyledEntityPanel } from "@components/form/panels/StyledEntityPanel";
import CustomModalContent from "@components/layout/modal/CustomModalContent";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import useNewDepotModal from "@src/hooks/dashboard/useNewDepotModal";
import { currentDepotIDState } from "@states/current.depot.state";
import { depotsState } from "@states/depot.state";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";

import { ThemeContext } from "@src/pages/_app";
import { Button, Col, Modal, Row } from "antd";
import { useContext } from "react";
import { RiAddCircleFill } from "react-icons/ri";
import { useSetRecoilState } from "recoil";

interface SelectDepotModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SelectDepotModal: React.FunctionComponent<SelectDepotModalProps> = ({
  isOpen,
  onClose
}) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const [, depots] = useAsyncSelector(depotsState);

  const setCurrentDepotID = useSetRecoilState(currentDepotIDState);

  const { setSelectNewDepotModalOpen, multiContextHolder } = useNewDepotModal();

  return (
    <Modal
      onCancel={onClose}
      width="100%"
      style={{
        maxWidth: 900,
        boxSizing: "border-box",
        margin: "2rem 0",
        color: appModeTheme?.secondLayerTextColor
      }}
      centered
      visible={isOpen}
      modalRender={() => (
        <CustomModalContent>
          <h2
            style={{
              margin: 0,
              marginBottom: "1rem",
              color: theme.palette.primary
            }}
          >
            Ihre Depots
          </h2>

          <p style={{ margin: 0, marginBottom: "2rem" }}>
            Hier sehen Sie eine Übersicht über Ihre eigenen sowie Ihre
            verwalteten Depots, an dieser Stelle können Sie auch neue Depots
            anlegen oder in die Detailansicht der einzelnen Depots wechseln.
          </p>

          <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
            {depots?.map(depot => (
              <DepotEntityItem
                onSelect={depotId => {
                  setCurrentDepotID(depotId);
                  onClose();
                }}
                key={depot.id}
                depot={depot}
              />
            ))}
            <StyledEntityPanel
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{ marginTop: 20 }}>
                <h2 style={{ margin: 0 }}>Weiteres Depot anlegen</h2>
                z.B. ein Junior Depot für mein Kind
              </div>
              <Row>
                <Col xs={20} />
                <Col xs={4} style={{ textAlign: "right", display: "flex" }}>
                  &nbsp;{" "}
                </Col>
              </Row>

              <Button
                onClick={() => setSelectNewDepotModalOpen(true)}
                className="icon-button"
                type="link"
              >
                <RiAddCircleFill style={{ fontSize: 48 }} />
              </Button>
            </StyledEntityPanel>
          </div>
          <SActionButtonsWrapper>
            <SButton
              className="reset"
              onClick={onClose}
              data-testid="close-select-depot-modal"
            >
              Schließen
            </SButton>
          </SActionButtonsWrapper>
          {multiContextHolder}
        </CustomModalContent>
      )}
    />
  );
};

export default SelectDepotModal;
