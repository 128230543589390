import { StyledEntityPanel } from "@components/form/panels/StyledEntityPanel";
import { Col, Row, Skeleton } from "antd";

const DepotEntityItemSkeleton: React.FunctionComponent = () => {
  return (
    <StyledEntityPanel>
      <h2>
        <Skeleton active title />
      </h2>

      <Row justify="space-between">
        <Col xs={12}>
          <span className="value-top">&nbsp;</span>
          <span className="value">
            <Skeleton.name />
          </span>
        </Col>
        <Col xs={12}>
          <Skeleton.name />
        </Col>
      </Row>
    </StyledEntityPanel>
  );
};

export default DepotEntityItemSkeleton;
