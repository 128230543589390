import { useRouter } from "next/router";
import { useCallback, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { isSessionActiveState, loggedInUserUriState } from "@states/auth.state";
import { sessionDebounce } from "@utils/helpers";

const DEFAULT_DELAY = 1000 * 60 * 10;

export const useCleanupSession = (delay = DEFAULT_DELAY) => {
  const { route, push } = useRouter();

  const [isSessionActive, setIsActiveSession] =
    useRecoilState(isSessionActiveState);

  const realUserUri = useRecoilValue(loggedInUserUriState);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const cleanupSession = useCallback(() => {
    setIsActiveSession(false);

    if (route === "/login") return;

    push("/login?loggedOut=true");

    realUserUri && smavestoCore.services.auth.logout(realUserUri);
  }, [realUserUri, setIsActiveSession, route, push]);

  useEffect(() => {
    const clearRef = timerRef;

    clearRef.current && clearTimeout(clearRef.current);

    if (route === "/login") {
      cleanupSession();
    } else {
      sessionDebounce(cleanupSession, timerRef, delay)();

      setIsActiveSession(true);
    }

    return () => {
      clearRef.current && clearTimeout(clearRef.current);
    };
  }, [setIsActiveSession, route, cleanupSession, delay]);

  return [
    isSessionActive,
    sessionDebounce(cleanupSession, timerRef, delay)
  ] as const;
};
