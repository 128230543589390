import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { atom, selector, useResetRecoilState } from "recoil";
import type { AppTheme } from "smavesto.core";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import {
  expiresState,
  isSessionActiveState,
  loggedInUserUriState,
  refreshLoadingState
} from "./auth.state";
import { showExemptionOrderInfoModalState } from "./bank-details.state";
import { currentDepotIDState } from "./current.depot.state";
import { rangeDepotIDState } from "./depot.state";
import { appModeState, loadingState, offlineState } from "./global.state";
import { currentFileIdState, docsAsideAmountState } from "./inbox.state";
import {
  apiErrorMessageState,
  currentLiquidCapitalState,
  currentMonthlyAmountState,
  currentMonthlyIncomeState,
  currentStartAmountState
} from "./income-costs.state";
import { transactionsFilterState } from "./investment-plan.state";
import { localInvestmentSettingsState } from "./investment-settings.state";
import {
  additionalExperiencesState,
  showCustomEducationState,
  showProfileVerificationUserControlState
} from "./knowledge.state";
import { onboardingUserCompletedSummaryPageState } from "./onboarding.state";
import {
  countryCodeState,
  customJobStatusState,
  isChangePasswordState
} from "./profile.state";
import { getRequestState } from "./request.state";
import { localRiskYieldState } from "./risk-yield.state";
import { currentMassOrderIDState } from "./simulate.state";
import { strategyRiskState } from "./strategy-risk.state";
import {
  receivePreContractualInfoState,
  summaryHistoryState
} from "./summary.state";
import { cachedThemeState } from "./theme.state";

export function useResetDashboardStates() {
  const resetshowExemptionOrderInfoModalState = useResetRecoilState(
    showExemptionOrderInfoModalState
  );

  const resetcurrentDepotIDState = useResetRecoilState(currentDepotIDState);

  const resetrangeDepotIDState = useResetRecoilState(rangeDepotIDState);

  const resetcurrentFileIdState = useResetRecoilState(currentFileIdState);

  const resetdocsAsideAmountState = useResetRecoilState(docsAsideAmountState);

  const resetapiErrorMessageState = useResetRecoilState(apiErrorMessageState);

  const resettransactionsFilterState = useResetRecoilState(
    transactionsFilterState
  );

  const resetlocalInvestmentSettingsState = useResetRecoilState(
    localInvestmentSettingsState
  );

  const resetCachedThemeState = useResetRecoilState(cachedThemeState);

  const resetshowProfileVerificationUserControlState = useResetRecoilState(
    showProfileVerificationUserControlState
  );

  return () => {
    resetshowExemptionOrderInfoModalState();
    resetcurrentDepotIDState();
    resetrangeDepotIDState();
    resetcurrentFileIdState();
    resetdocsAsideAmountState();
    resetapiErrorMessageState();
    resettransactionsFilterState();
    resetlocalInvestmentSettingsState();
    resetshowProfileVerificationUserControlState();
    resetCachedThemeState();
  };
}

export function useResetOnboardingStates() {
  const resetcurrentLiquidCapitalState = useResetRecoilState(
    currentLiquidCapitalState
  );

  const resetcurrentStartAmountState = useResetRecoilState(
    currentStartAmountState
  );

  const resetOnboardingUserCompletedSummary = useResetRecoilState(
    onboardingUserCompletedSummaryPageState
  );

  const resetcurrentMonthlyAmountState = useResetRecoilState(
    currentMonthlyAmountState
  );

  const resetcurrentMonthlyIncomeState = useResetRecoilState(
    currentMonthlyIncomeState
  );

  const resetadditionalExperiencesState = useResetRecoilState(
    additionalExperiencesState
  );

  const resetstrategyRiskState = useResetRecoilState(strategyRiskState);

  return () => {
    resetcurrentLiquidCapitalState();
    resetcurrentStartAmountState();
    resetcurrentMonthlyAmountState();
    resetcurrentMonthlyIncomeState();
    resetadditionalExperiencesState();
    resetstrategyRiskState();
    resetOnboardingUserCompletedSummary();
  };
}

export function useResetBaseStates() {
  // base

  const resetAppModeState = useResetRecoilState(appModeState);

  const resetofflineState = useResetRecoilState(offlineState);

  const resetloadingState = useResetRecoilState(loadingState);

  const resetloggedInUserUriState = useResetRecoilState(loggedInUserUriState);

  const resetexpiresState = useResetRecoilState(expiresState);

  const resetrefreshLoadingState = useResetRecoilState(refreshLoadingState);

  return () => {
    resetAppModeState();
    resetofflineState();
    resetloadingState();
    resetloggedInUserUriState();
    resetexpiresState();
    resetrefreshLoadingState();
  };
}

export function useResetProfileStates() {
  const resetisSessionActiveState = useResetRecoilState(isSessionActiveState);

  const resetshowCustomEducationState = useResetRecoilState(
    showCustomEducationState
  );

  const resetisChangePasswordState = useResetRecoilState(isChangePasswordState);

  const resetcustomJobStatusState = useResetRecoilState(customJobStatusState);

  const resetcountryCodeState = useResetRecoilState(countryCodeState);

  const resetlocalRiskYieldState = useResetRecoilState(localRiskYieldState);

  const resetcurrentMassOrderIDState = useResetRecoilState(
    currentMassOrderIDState
  );

  const resetreceivePreContractualInfoState = useResetRecoilState(
    receivePreContractualInfoState
  );

  const resetsummaryHistoryState = useResetRecoilState(summaryHistoryState);

  return () => {
    resetisSessionActiveState();
    resetshowCustomEducationState();
    resetisChangePasswordState();
    resetcustomJobStatusState();
    resetcountryCodeState();
    resetlocalRiskYieldState();
    resetcurrentMassOrderIDState();
    resetreceivePreContractualInfoState();
    resetsummaryHistoryState();
  };
}

export function useResetStates() {
  const resetDashboardStates = useResetDashboardStates();
  const resetOnboardingStates = useResetOnboardingStates();
  const resetBaseStates = useResetBaseStates();
  const resetProfileStates = useResetProfileStates();

  return () => {
    resetDashboardStates();
    resetOnboardingStates();
    resetBaseStates();
    resetProfileStates();
  };
}

export const remoteLightModeState = selector<boolean>({
  key: "remoteLightModeState",
  get: async ({ get }) => {
    const userUri = get(loggedInUserUriState);
    get(getRequestState("lightmode", userUri));

    const result = await smavestoCore.services.keyValueStore.getValueByKey(
      userUri,
      "lightmode"
    );

    if (isApiErrorResponse(result)) return false;

    return result.value === "on";
  }
});

export const localLightModeState = atom<boolean>({
  key: "localLightModeState",
  default: false
});

export const localThemeOverWriteState = atom<AppTheme>({
  key: "localThemeOverWriteState",
  default: undefined
});

export const devThemeConfiguationWindowState = atom<boolean>({
  key: "devThemeConfiguationWindowState",
  default: undefined
});

export const lightModeState = selector<boolean>({
  key: "lightModeState",
  get: async ({ get }) => {
    const userUri = get(loggedInUserUriState);

    return get(localLightModeState);
  }
});
