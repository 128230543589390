import type DepotDto from "smavesto.core/lib/types/dto/depot/DepotDto";
import { useCancellation } from "@page-components/dashboard/Profile/cancellation/useCancellation";
import { SButton } from "@styled-components/form/SButtons";
import { SP } from "@styled-components/STags";
import OuterHint from "./OuterHint";

interface PendingDepotCancellationHintProps {
  actionNeeded: boolean;
  depot: DepotDto;
  description: string;
}

const PendingDepotCancellationHint: React.FunctionComponent<
  PendingDepotCancellationHintProps
> = ({ actionNeeded, depot, description }) => {
  const {
    rejectCancellation,
    approveCancellation,
    confirmLoading,
    modalContextHolder
  } = useCancellation(depot.userUri);

  return (
    <OuterHint title="Depothinweis">
      <SP>{description}</SP>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "1rem",
          gap: "1rem"
        }}
      >
        {actionNeeded && (
          <SButton
            loading={confirmLoading}
            type="primary"
            onClick={approveCancellation}
          >
            Kündigung zustimmen
          </SButton>
        )}
        <SButton
          loading={confirmLoading}
          className="reset"
          onClick={rejectCancellation}
        >
          Kündigung abbrechen
        </SButton>
      </div>
      {modalContextHolder}
    </OuterHint>
  );
};

export default PendingDepotCancellationHint;
