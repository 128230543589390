import { selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import { userUriState } from "./auth.state";
import { getRequestState } from "./request.state";

export const customerSupplierFromOriginOrConfigSelector = selectorFamily({
  key: "customerSupplierFromOriginOrConfigSelector",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      if (!userUri) return undefined;

      get(getRequestState("supplier", userUri));

      const supplier =
        await smavestoCore.services.user.getCustomerSupplierFromOriginOrConfig(
          userUri
        );

      return successOrUndefined(supplier);
    }
});
