import { SettingOutlined } from "@ant-design/icons";
import { isDebugEnvironment } from "@utils/environment/isDebugEnvironment";
import { Button, Col, Drawer, Row } from "antd";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { pkg } from "src/configs/pkg.config";
import { GlobalStateView } from "./GlobalStateView";
import { OnboardingStateSimulate } from "./OnboardingStateSimulate";

const DebugSideDrawer: React.FunctionComponent = () => {
  const [visible, setVisible] = useState(false);

  const router = useRouter();

  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    // Check if the code is running on the client side
    if (process) {
      // Access the current page URL using window.location
      setCurrentUrl(window.location.href);
    }
  }, []);

  const showDebugButton =
    isDebugEnvironment &&
    !currentUrl.startsWith("https://webapp-staging.smavesto.de");

  return showDebugButton ? (
    <div>
      {router.basePath}
      <Button
        type="primary"
        danger
        shape="circle"
        icon={<SettingOutlined />}
        onClick={() => setVisible(true)}
        style={{ position: "absolute", top: 10, left: 12, zIndex: 999 }}
      />
      <Drawer
        title={`Smavesto Webapp (Entwicklungsversion) - v${pkg.version}`}
        placement="top"
        onClose={() => setVisible(false)}
        visible={visible}
        size="large"
        style={{ color: "#333" }}
      >
        <Row>
          <Col span={8} style={{ paddingRight: 40 }}>
            <h3>💾 Daten und Konfiguration</h3>
            <GlobalStateView />
          </Col>
          <Col span={8}>
            <h3>🔬 Simulation von Zuständen</h3>
            <OnboardingStateSimulate />
          </Col>
        </Row>
      </Drawer>
    </div>
  ) : null;
};

export default DebugSideDrawer;
