import { selectorFamily } from "recoil";

import { userUriState } from "@states/auth.state";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import { getRequestState, useRefreshState } from "./request.state";

export const financialPositionState = selectorFamily({
  key: "financialPosition",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("financialPosition", userUri));

      if (!userUri) return undefined;

      return successOrUndefined(
        await smavestoCore.services.user.getFinancialSituation(userUri)
      );
    }
});

export const financialPositionForUserUriState = selectorFamily({
  key: "financialPositionForUserUri",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("financialPosition", userUri));

      return successOrUndefined(
        await smavestoCore.services.user.getFinancialSituation(userUri)
      );
    }
});

export const useRefreshFinancialPosition = (type: StateEnvironmentUser) =>
  useRefreshState("financialPosition", type);
