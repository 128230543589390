export const Sizes = {
  mobile: 480,
  mobileL: 576,
  tablet: 768,
  tabletL: 960,
  desktop: 1200,
  desktopL: 1400
};

export const Devices = {
  mobileDown: `(max-width: ${Sizes.mobileL}px)`,
  mobileUp: `(min-width: ${Sizes.mobileL + 1}px)`,
  tabletDown: `(max-width: ${Sizes.tabletL}px)`,
  tabletUp: `(min-width: ${Sizes.tabletL + 1}px)`,
  desktopDown: `(max-width: ${Sizes.desktop}px)`,
  desktopUp: `(min-width: ${Sizes.desktop + 1}px)`
};

export const BREAK_POINTS = {
  xs: 280,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};
