import { atom, selector, selectorFamily } from "recoil";

import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";

import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";
import type RiskYieldInitValueKey from "smavesto.core/lib/types/dto/onboarding/RiskYieldInitValueKey";
import { onboardingLabelsByActiveOnboardingState } from "./depot.state";
import { financialPositionState } from "./financial-position.state";
import { riskYieldState } from "./risk-yield.state";

export const RISKY_YIELD_DEFAULT_VALUES = {
  anlagerisiko: 15,
  monatlicheEinzahlung: 50,
  startbetrag: 1000
};

export const currentLiquidCapitalState = atom<number | undefined>({
  key: "CurrentLiquidCapital",

  default: undefined
});

export const currentStartAmountState = atom<number>({
  key: "CurrentStartAmount",
  default: 1000
});

export const currentMonthlyIncomeState = atom<number | undefined>({
  key: "CurrentMonthlyIncome",
  default: undefined
});

export const currentMonthlyAmountState = atom<number>({
  key: "CurrentMonthlyAmount",
  default: 50
});

export const apiErrorMessageState = atom({
  key: "apiErrorMessageState",
  default: ""
});

export const initRiskYieldValueState = selectorFamily({
  key: "InitRiskYieldValue",
  get:
    <K extends RiskYieldInitValueKey>({
      key,
      type
    }: {
      key: K;
      type: StateEnvironmentUser;
    }) =>
    ({ get }) =>
      get(riskYieldState(type))?.[key] ?? RISKY_YIELD_DEFAULT_VALUES[key]
});

export const initFinancialPositionValueState = selectorFamily({
  key: "InitFinancialPositionValue",
  get:
    <K extends keyof FinancialPositionDto>({
      key,
      type
    }: {
      key: K;
      type: StateEnvironmentUser;
    }) =>
    ({ get }) =>
      get(financialPositionState(type))?.[key]
});

export const startAmountErrorsState = selector({
  key: "StartAmountErrors",
  get: ({ get }) => {
    const labels = get(onboardingLabelsByActiveOnboardingState);

    const currentStartAmount = get(currentStartAmountState);

    const currentMonthlyAmount = get(currentMonthlyAmountState);

    const minValue =
      currentStartAmount > 0 &&
      currentStartAmount !== null &&
      currentStartAmount < 1000
        ? labels.investmentSettings.incomeCostsValidationMessage.startAmount
            .minValue
        : "";

    const equalZero =
      currentStartAmount === 0 && currentMonthlyAmount === 0
        ? labels.investmentSettings.incomeCostsValidationMessage.startAmount
            .equalZero
        : "";

    const required =
      currentStartAmount === null
        ? labels.investmentSettings.incomeCostsValidationMessage.startAmount
            .required
        : "";

    return [minValue, equalZero, required].filter(e => !!e);
  }
});

export const MonthlyAmountErrorsState = selector({
  key: "MonthlyAmountErrors",
  get: ({ get }) => {
    const labels = get(onboardingLabelsByActiveOnboardingState);

    const currentStartAmount = get(currentStartAmountState);

    const currentMonthlyAmount = get(currentMonthlyAmountState);

    const minValue =
      currentMonthlyAmount > 0 &&
      currentMonthlyAmount !== null &&
      currentMonthlyAmount < 50
        ? labels.investmentSettings.incomeCostsValidationMessage.monthlyAmount
            .minValue
        : "";

    const equalZero =
      currentStartAmount === 0 && currentMonthlyAmount === 0
        ? labels.investmentSettings.incomeCostsValidationMessage.monthlyAmount
            .equalZero
        : "";

    const required =
      currentMonthlyAmount === null
        ? labels.investmentSettings.incomeCostsValidationMessage.monthlyAmount
            .required
        : "";

    return [minValue, equalZero, required].filter(e => !!e);
  }
});
export const getLiquidCapitalRangeError = (
  onboardingLabelsByDepotType: any,
  currentLiquidCapital?: number,
  currentStartAmount?: number
) => {
  const minLiquidCapital = Math.round((currentStartAmount || 0) * 1.26);

  return currentLiquidCapital !== undefined &&
    currentLiquidCapital !== null &&
    currentLiquidCapital < minLiquidCapital
    ? onboardingLabelsByDepotType.investmentSettings.incomeCostsValidationMessage.liquidCapital.range.replace(
        "_betrag_",
        parseNumberToCurrencyString(minLiquidCapital)
      )
    : "";
};

export const liquidCapitalRangeErrorState = selector({
  key: "LiquidCapitalRangeError",
  get: ({ get }) => {
    const labels = get(onboardingLabelsByActiveOnboardingState);

    const currentLiquidCapital = get(currentLiquidCapitalState);

    const currentStartAmount = get(currentStartAmountState);

    const minLiquidCapital = Math.round(currentStartAmount * 1.26);

    return currentLiquidCapital !== undefined &&
      currentLiquidCapital !== null &&
      currentLiquidCapital < minLiquidCapital
      ? labels.investmentSettings.incomeCostsValidationMessage.liquidCapital.range.replace(
          "_betrag_",
          parseNumberToCurrencyString(minLiquidCapital)
        )
      : "";
  }
});

export const startAmountRangeErrorState = selector({
  key: "StartAmountRangeError",
  get: ({ get }) => {
    const currentLiquidCapital = get(currentLiquidCapitalState);

    const currentStartAmount = get(currentStartAmountState);

    if (currentLiquidCapital !== undefined && currentLiquidCapital !== null) {
      const minLiquidCapital = Math.round(currentStartAmount * 1.26);

      if (currentLiquidCapital === 0)
        return "Bitte geben Sie Ihr liquides Vermögen an.";

      return currentLiquidCapital < minLiquidCapital
        ? `Das liquide Vermögen muss bei einem Startbetrag von ${parseNumberToCurrencyString(
            currentStartAmount
          )} mindestens ${parseNumberToCurrencyString(
            minLiquidCapital
          )} (126% vom Startbetrag) betragen.`
        : "";
    }

    return "";
  }
});

export const getMonthlyIncomeRangeError = (
  onboardingLabelSource: any,
  currentMonthlyIncome?: number,
  currentMonthlyAmount?: number
): string => {
  return currentMonthlyIncome !== undefined &&
    currentMonthlyIncome !== null &&
    currentMonthlyIncome < (currentMonthlyAmount || 0)
    ? onboardingLabelSource.investmentSettings.incomeCostsValidationMessage
        .monthlyIncome.range
    : "";
};

export const monthlyIncomeRangeErrorState = selector({
  key: "MonthlyIncomeRangeError",
  get: ({ get }) => {
    const labels = get(onboardingLabelsByActiveOnboardingState);

    const currentMonthlyIncome = get(currentMonthlyIncomeState);

    const currentMonthlyAmount = get(currentMonthlyAmountState);

    return currentMonthlyIncome !== undefined &&
      currentMonthlyIncome !== null &&
      currentMonthlyIncome < currentMonthlyAmount
      ? labels.investmentSettings.incomeCostsValidationMessage.monthlyIncome
          .range
      : "";
  }
});

export const getLiquidCapitalErrors = (
  onboardingLabelsByDepotType: any,
  currentLiquidCapital?: number,
  currentStartAmount?: number
) => {
  const range = getLiquidCapitalRangeError(
    onboardingLabelsByDepotType,
    currentLiquidCapital,
    currentStartAmount
  );

  const required =
    currentLiquidCapital === null || currentLiquidCapital === undefined
      ? "Bitte geben Sie Ihr liquides Vermögen ein."
      : "";

  return [range, required].filter(e => !!e);
};

export const liquidCapitalErrorsState = selector({
  key: "LiquidCapitalErrors",
  get: ({ get }) => {
    const currentLiquidCapital = get(currentLiquidCapitalState);

    const range = get(liquidCapitalRangeErrorState);

    const required =
      currentLiquidCapital === null || currentLiquidCapital === undefined
        ? "Bitte geben Sie Ihr liquides Vermögen ein."
        : "";

    return [range, required].filter(e => !!e);
  }
});

export const getMonthlyIncomeError = (
  onboardingLabelSource: any,
  currentMonthlyIncome?: number,
  currentMonthlyAmount?: number
) => {
  const rangeError = getMonthlyIncomeRangeError(
    onboardingLabelSource,
    currentMonthlyIncome,
    currentMonthlyAmount
  );

  const requiredError =
    currentMonthlyIncome === null || currentMonthlyIncome === undefined
      ? onboardingLabelSource.investmentSettings.rules.monthlyIncome[0].message
      : "";

  return [rangeError, requiredError].filter(e => !!e);
};

export const monthlyIncomeErrorsState = selector<string[]>({
  key: "MonthlyIncomeErrors",
  get: ({ get }) => {
    const currentMonthlyIncome = get(currentMonthlyIncomeState);

    const range = get(monthlyIncomeRangeErrorState);

    const required =
      currentMonthlyIncome === null || currentMonthlyIncome === undefined
        ? "Bitte geben Sie Ihr regelmäßiges Einkommen (netto) abzüglich regelmäßiger Verpflichtungen ein."
        : "";

    return [range, required].filter(e => !!e);
  }
});

export const costsErrorsState = selector({
  key: "CostsErrors",
  get: ({ get }) => {
    const startAmountErrors = get(startAmountErrorsState);

    const MonthlyAmountErrors = get(MonthlyAmountErrorsState);

    return [...startAmountErrors, ...MonthlyAmountErrors];
  }
});

export const allErrorsState = selector({
  key: "AllErrors",
  get: ({ get }) => {
    const apiError = get(apiErrorMessageState);

    return [
      ...(apiError ? [apiError] : []),
      ...get(liquidCapitalErrorsState),
      ...get(monthlyIncomeErrorsState),
      ...get(costsErrorsState)
    ];
  }
});
