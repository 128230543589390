import type { ModalFuncProps } from "antd/lib/modal";
import modal from "antd/lib/modal";
import type { ModalStaticFunctions } from "antd/lib/modal/confirm";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import type { DestoryModal } from "../hook/useThemedModal";

/* Opens a confirm modal in a given theme */
export function openThemedModalConfirm(
  modal: Omit<ModalStaticFunctions, "warn">,
  modalProps: ModalFuncProps,
  theme: AppTheme
) {
  const okButtonProps = { style: { color: theme.palette.primaryTextColor } };

  return modal.confirm({
    ...modalProps,
    okButtonProps
  });
}
/* Opens a info modal in a given theme */
export function openThemedModalInfo(
  modal: Omit<ModalStaticFunctions, "warn">,
  modalProps: ModalFuncProps,
  theme: AppTheme
): DestoryModal {
  const okButtonProps = { style: { color: theme.palette.primaryTextColor } };

  return modal.info({
    ...modalProps,
    okButtonProps
  });
}

/* 
    This modals are not contained within a context holder 
    hooks cannot be used!
    */
export function openIndependentInfoModal(
  modalProps: ModalFuncProps,
  theme: AppTheme
) {
  const okButtonProps = { style: { color: theme.palette.primaryTextColor } };

  return modal.info({
    ...modalProps,
    okButtonProps
  });
}

/* 
    This modals are not contained within a context holder 
    hooks cannot be used!
    */
export function openIndependentConfirmModal(
  modalProps: ModalFuncProps,
  theme: AppTheme
) {
  const okButtonProps = { style: { color: theme.palette.primaryTextColor } };

  return modal.confirm({
    ...modalProps,
    okButtonProps
  });
}
