import { selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { userUriState } from "@states/auth.state";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import getCurrentAccreditationStateForUserUri from "smavesto.core/lib/business-logic/supplier/accreditation/getCurrentAccreditationStateForUserUri";
import { getRequestState, useRefreshState } from "./request.state";

export const settingsState = selectorFamily({
  key: "settings",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("settings", userUri));

      return userUri
        ? successOrUndefined(
            await smavestoCore.services.user.getSettings(userUri)
          )
        : undefined;
    }
});

export const settingsForUserUriState = selectorFamily({
  key: "settingsForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("settings", userUri));

      const settings = await smavestoCore.services.user.getSettings(userUri);

      return successOrUndefined(settings);
    }
});

export const useRefreshSettings = (type: StateEnvironmentUser) =>
  useRefreshState("settings", type);

export const customerSupplierState = selectorFamily({
  key: "customerSupplier",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("customerSupplier", userUri));

      if (!userUri) return undefined;

      const customerSupplierConfig =
        await smavestoCore.services.user.getCustomerSupplierFromOriginOrConfig(
          userUri
        );

      return successOrUndefined(customerSupplierConfig);
    }
});

export const customerSupplierForUserUriState = selectorFamily({
  key: "customerSupplierForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("customerSupplier", userUri));

      return successOrUndefined(
        await smavestoCore.services.user.getCustomerSupplierFromOriginOrConfig(
          userUri
        )
      );
    }
});

export const customerSupplierAccreditationForUserUriState = selectorFamily({
  key: "customerSupplierAccreditationForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      smavestoCore.debugLog(
        "info",
        "reload customerSupplierAccreditationForUserUriState"
      );

      get(getRequestState("customerAccreditation", userUri));

      const status = getCurrentAccreditationStateForUserUri(
        smavestoCore,
        userUri
      );

      return status;
    }
});

export const customerSupplierAccreditationState = selectorFamily({
  key: "customerSupplierAccreditationState",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("customerAccreditation", userUri));

      if (!userUri) return undefined;

      const status = get(customerSupplierAccreditationForUserUriState(userUri));

      return status;
    }
});

export const customerSupplierFullAddressState = selectorFamily({
  key: "customerSupplierFullAddress",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const customerSupplier = get(customerSupplierState(type));

      const fullAddress = customerSupplier
        ? `${customerSupplier?.street ?? ""} ${
            customerSupplier?.streetNumber ?? ""
          }, ${customerSupplier?.zip ?? ""} ${customerSupplier?.city ?? ""}`
        : ``;

      return fullAddress;
    }
});

export const customerSupplierFullAddressForUserUriState = selectorFamily({
  key: "customerSupplierFullAddressForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      const customerSupplier = get(customerSupplierForUserUriState(userUri));

      const fullAddress = customerSupplier
        ? `${customerSupplier?.street ?? ""} ${
            customerSupplier?.streetNumber ?? ""
          }, ${customerSupplier?.zip ?? ""} ${customerSupplier?.city ?? ""}`
        : ``;

      return fullAddress;
    }
});

export const useRefreshCustomerSupplier = (type: StateEnvironmentUser) =>
  useRefreshState("customerSupplier", type);

export const customerAccreditationState = selectorFamily({
  key: "customerAccreditation",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("customerAccreditation", userUri));

      const accreditationType = get(settingsState(type))
        ?.zustimmungCustomerSupplier?.customerAccreditationType;

      if (userUri && accreditationType) {
        const customerAccreditiation =
          await smavestoCore.services.user.getCustomerAccreditation(
            userUri,
            accreditationType
          );
        // Todo: Add error handling
        if (!isApiErrorResponse(customerAccreditiation)) {
          return customerAccreditiation;
        }
      }

      return undefined;
    }
});

export const useRefreshCustomerAccreditation = (type: StateEnvironmentUser) =>
  useRefreshState("customerAccreditation", type);
