import { Row } from "antd";
import styled from "styled-components";

import GooglePlayBadge from "@src/assets/google-play-badge.png";
import Logo from "@src/assets/smavesto-logo-small@2x.png";
import { BREAK_POINTS } from "@theme/deviceBreakpoints";
import theme from "@styles/theme";

// Styles Mobile First
export const SFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 14px;

  @media (min-width: ${BREAK_POINTS.lg}px) {
    padding: 0 40px;
    padding-bottom: 10px;
  }

  .logo {
    height: 40px;
    margin-bottom: 10px;
    background-image: url("${Logo.src}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;

    @media (min-width: ${BREAK_POINTS.sm}px) {
      background-position: 0 50%;
    }

    @media (min-width: ${BREAK_POINTS.xl}px) {
      height: 40px;
    }
  }

  .links {
    min-height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;

    a {
      padding-right: 8px;
      color: inherit;
      font-size: 12px;
    }

    a:hover {
      color: ${theme.primary};
    }

    @media (min-width: ${BREAK_POINTS.sm}px) {
      justify-content: flex-start;
      padding-top: 10px;
    }

    @media (min-width: ${BREAK_POINTS.lg}px) {
      justify-content: center;
    }
  }

  && .stores {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    margin: auto;
    margin-bottom: 10px;

    @media (min-width: ${BREAK_POINTS.lg}px) {
      justify-content: flex-end;
      margin: 0;
      margin-bottom: 10px;
    }

    a {
      width: 120px;
      height: 40px;
      margin-right: 10px;
    }

    a:last-child {
      margin-right: 0;
    }

    .google-play-badge {
      height: 40px;
      background-image: url("${GooglePlayBadge.src}");
      background-size: 114% 146%;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }
`;
