import { getCookie } from "@src/utils/cookies";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

export enum TRIGGER_ID {
  FIRST_REGISTRATION = 1,
  FULL_CONVERSION = 2
}

const sendUpprAffiliateConversion = async (
  triggerId: TRIGGER_ID,
  orderId: string
) => {
  const campaignId = 237;
  const description = "RoboAdvisor";
  const emid = getCookie("emid", document.cookie) || "";

  const url = `https://trck.smavesto.de/trck/etrack/?campaign_id=${campaignId}&trigger_id=${triggerId}&emid=${emid}&token=${orderId}&descr=${description}&t=json`;

  try {
    const conversionResponse = await fetch(url);
    const conversionResponseJSON = await conversionResponse.json();

    smavestoCore.debugLog(
      "info",
      "uppr conversion sent successfully",
      conversionResponseJSON
    );
  } catch (ex) {
    smavestoCore.debugLog("error", "uppr conversion send failed", ex);
  }
};

export default sendUpprAffiliateConversion;
