import cx from "classnames";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { MobileUnderageMenu } from "@components/layout/navigation/OnboardingNavigation/MobileUnderageMenu";
import { OnboardingUnderageNavigation } from "@components/layout/navigation/OnboardingNavigation/OnboardingUnderageNavigation";
import { FooterPublic } from "@components/layout/page/footer/FooterPublic";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import type { ILayoutBaseProps } from "@src/interfaces/jsx.interface";
import { activeOnboardingState } from "@states/depot.state";
import { localInvestmentSettingsState } from "@states/investment-settings.state";

import BasicPageLayout from "../layout/BasicPageLayout";
import styles from "../OnboardingLayout/styles.module.less";
import SecondLayerWrapper from "../../lightmode/SecondLayerWrapper";
import LightModeSwitch from "../../lightmode/LightModeSwitch";

export interface PublicUnderageOnboardingLayoutProps extends ILayoutBaseProps {
  disableFooter?: boolean;
}

export const PublicUnderageOnboardingLayout: React.FunctionComponent<
  PublicUnderageOnboardingLayoutProps
> = props => {
  const { query } = useRouter();

  const [completedChapters, setCompletedChapters] = useState<string[]>([]);
  const [disabledChapters] = useState<string[]>([
    "ZUGANGSDATEN",
    "PREFERECENCES",
    "PERSOENLICHE_ANGABEN",
    "FINANZIELLE_LAGE",
    "KENNTNISSE",
    "BANKVERBINDUNG",
    "SUMMARY",
    "SUMMARY2"
  ]);

  const [, onboarding] = useAsyncSelector(activeOnboardingState);

  const localInvestmentSettings = useAsyncSelector(
    localInvestmentSettingsState
  );

  useEffect(() => {
    if (
      (localInvestmentSettings || query.continueOnboarding) &&
      !completedChapters.includes("ANLAGEWUNSCH")
    ) {
      completedChapters.push("ANLAGEWUNSCH");
      setCompletedChapters([...new Set(completedChapters)]);

      const idx = disabledChapters.indexOf("ZUGANGSDATEN");
      if (idx >= 0) {
        disabledChapters.splice(idx, 1);
      }
    }
  }, [
    completedChapters,
    disabledChapters,
    onboarding?.directAccess,
    query,
    localInvestmentSettings
  ]);

  return (
    <BasicPageLayout>
      <SecondLayerWrapper paddingTop>
        <div className={styles["main-layout-container"]}>
          <div className={styles["bar-wrapper"]}>
            <OnboardingUnderageNavigation
              completedChapters={completedChapters}
            />
          </div>

          <div className={styles["mobile-bar-wrapper"]}>
            <MobileUnderageMenu completedChapters={completedChapters} />
          </div>

          <div
            className={cx(
              styles["layout-container"],
              styles["wrapper-container"],
              "g-layout-container--master"
            )}
          >
            <div className={styles["progress-wrapper"]}>
              <LightModeSwitch />
            </div>
            <props.mainComp
              router={props.routeProps}
              pageProps={props.pageProps}
            />
          </div>
        </div>
      </SecondLayerWrapper>
      {props?.disableFooter ? null : (
        <div className={cx(styles["layout-footer"], "g-layout-footer--master")}>
          <FooterPublic />
        </div>
      )}
    </BasicPageLayout>
  );
};
