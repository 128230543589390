import { Progress } from "antd";
import cx from "classnames";

import OnboardingNavigation from "@components/layout/navigation/OnboardingNavigation/OnboardingNavigation";
import { FooterIntern } from "@components/layout/page/footer/FooterIntern";
import { ThemeContext } from "@pages/_app";
import type { ILayoutBaseProps } from "@src/interfaces/jsx.interface";
import { SButton } from "@styled-components/form/SButtons";
import { useContext, useState } from "react";

import { depotsState } from "@src/state/depot.state";
import { useRouter } from "next/router";
import LightModeSwitch from "../../lightmode/LightModeSwitch";
import SecondLayerWrapper from "../../lightmode/SecondLayerWrapper";
import OnboardingMobileMenu from "../../navigation/OnboardingMobileMenu/OnboardingMobileMenu";
import BasicPageLayout from "../layout/BasicPageLayout";
import styles from "./styles.module.less";
import { useOnboardingLayout } from "./useOnboardingLayout";

export interface IProps extends ILayoutBaseProps {
  disableHeader?: boolean;
  disableFooter?: boolean;
}

export const OnboardingLayout: React.FC<IProps> = props => {
  const { logout, completedChapters, disabledChapters, progress } =
    useOnboardingLayout();

  const [, depots] = useState(depotsState);

  const userHasMoreThanOneDepot = depots?.length || 0 > 1;

  const { push } = useRouter();

  const { theme } = useContext(ThemeContext);

  const gotoDashboard = () => {
    push("/dashboard/depot");
  };

  return (
    <BasicPageLayout>
      <SecondLayerWrapper paddingTop>
        <div className={styles["main-layout-container"]}>
          {props?.disableHeader ? null : (
            <>
              <div className={styles["bar-wrapper"]}>
                <OnboardingNavigation
                  completedChapters={completedChapters}
                  disabledChapters={disabledChapters}
                  hiddenChapters={[]}
                />
              </div>

              <div className={styles["mobile-bar-wrapper"]}>
                <OnboardingMobileMenu
                  completedChapters={completedChapters}
                  disabledChapters={disabledChapters}
                  hiddenChapters={[]}
                />
              </div>
            </>
          )}

          <div
            className={cx(
              styles["layout-container"],
              styles["wrapper-container"],
              "g-layout-container--master"
            )}
          >
            <div className={styles["progress-wrapper"]}>
              <LightModeSwitch />
            </div>
            <div className={styles["progress-wrapper"]}>
              <Progress
                format={percent => (
                  <span
                    style={{ color: theme.palette.primary, fontWeight: "bold" }}
                  >
                    {percent}%
                  </span>
                )}
                width={50}
                trailColor="#80808057"
                strokeColor={theme.palette.primary}
                type="circle"
                percent={progress}
              />
              {/* 
            <SButton className="reset" onClick={onDeleteOnboarding}>
              Löschen
            </SButton> */}
              {userHasMoreThanOneDepot && (
                <SButton type="primary" onClick={gotoDashboard}>
                  Zurück zum Dashboard
                </SButton>
              )}
              <SButton type="primary" onClick={logout}>
                Abmelden
              </SButton>
            </div>
            <props.mainComp
              router={props.routeProps}
              pageProps={props.pageProps}
            />{" "}
          </div>
        </div>
      </SecondLayerWrapper>{" "}
      {props?.disableFooter ? null : (
        <div className={cx(styles["layout-footer"], "g-layout-footer--master")}>
          <FooterIntern />
        </div>
      )}
    </BasicPageLayout>
  );
};
