import { Button, Skeleton } from "antd";
import React from "react";

export const MenuButtonSkeleton: React.FunctionComponent = () => {
  return (
    <div
      style={{ position: "relative", minWidth: 260, height: 58, opacity: 0.5 }}
    >
      <Button
        type="primary"
        shape="circle"
        style={{
          height: 58,
          width: 58,
          position: "absolute",
          left: 0,
          zIndex: 2,
          fontWeight: "bold",
          borderWidth: 4,
          borderColor: "#002d2f"
        }}
      >
        &nbsp;
      </Button>

      <Skeleton.Button
        active
        style={{
          borderRadius: 19,
          width: 220,
          textAlign: "start",
          color: "white",
          fontWeight: "bold",
          position: "absolute",
          height: 48,
          left: 10,
          top: 4,
          fontSize: 13,
          paddingLeft: 55,
          boxShadow: "3px 3px 3px rgba(0,0,0,0.2)"
        }}
      />
    </div>
  );
};
