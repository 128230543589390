import type InvestmentSettingsDto from "smavesto.core/lib/types/dto/user/InvestmentSettingsDto";

export default function getDepotSettingsLabel(
  ownerInvestmentSettings: InvestmentSettingsDto
) {
  return ([] as string[])
    .concat(
      ownerInvestmentSettings.onlySustainableCompanies ? ["Nachhaltig"] : []
    )
    .concat(ownerInvestmentSettings.noForeignCurrency ? [] : ["Fremdwährungen"])
    .concat(
      ownerInvestmentSettings.noEmergingMarkets ? [] : ["Emerging Markets"]
    )
    .concat(ownerInvestmentSettings.investInCrypto ? ["Kryptowährungen"] : [])
    .concat(ownerInvestmentSettings.investInCrypto ? ["Gold"] : [])
    .flat()
    .join(" | ");
}
