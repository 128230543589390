import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import { loggedInUserUriState } from "@src/state/auth.state";
import {
  depotAllocationsState,
  notJuristicDepotState
} from "@src/state/depot.state";
import { SDashboardCol } from "@src/styled-components/SDashboardCol";
import { cancellationDateState } from "@states/cancellation.state";
import {
  currentDepotCustodyState,
  currentDepotInvestmentPlanState,
  currentDepotOnboardingState,
  currentDepotState,
  firstTransactionState
} from "@states/current.depot.state";
import {
  activeOnboardingState,
  allocationHintReadStateForCurrentDepotState,
  loggedInUserOnboardingState,
  onboardingByEnvironmentState
} from "@states/onboarding.state";
import { profileState } from "@states/profile.state";
import { bundledLoadingState } from "@utils/recoil/bundledLoadingState";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext } from "react";
import getCancellationStatus from "smavesto.core/lib/business-logic/cancellation/getCancellationStatus";
import getDepotOpeningStatus from "smavesto.core/lib/business-logic/workflow/getDepotOpeningStatus";
import getWorkflowStatus from "smavesto.core/lib/business-logic/workflow/getWorkflowStatus";

import isFirstGuardianForDepot from "smavesto.core/lib/utils/typeguards/isFirstGuardianForDepot";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import BasicInfoBox from "../info-box/BasicInfoBox";
import CompletedDepotCancellationHint from "./CompletedDepotCancellationHint";
import DepotOpeningStatusHint from "./DepotOpeningStatusHint";
import FinishDepotHintSwitch from "./FinishDepotHintSwitch";
import LegitimationRequiredDepotMessage from "./LegitimationRequiredDepotMessage";
import PendingDepotCancellationHint from "./PendingDepotCancellationHint";
import WaitingForAnotherSecondGuardianInviteMessage from "./WaitingForAnotherSecondGuardianInviteMessage";
import WaitingForFirstTransactionDepotMessage from "./WaitingForFirstTransactionDepotMessage";
import WaitingForSecondGuardianDepotMessage from "./WaitingForSeconGuardianDepotMessage";

export default function useHintCollection() {
  const hintTestMode = false;

  const { theme } = useContext(ThemeContext);

  const [investmentPlanLoadingState, investmentPlan] = useAsyncSelector(
    currentDepotInvestmentPlanState
  );

  const [hintDepotReadStatusLoadingState, hintDepotReadStatus] =
    useAsyncSelector(allocationHintReadStateForCurrentDepotState);

  const [currentDepotAllocationLoadingState, currentDepotAllocation] =
    useAsyncSelector(depotAllocationsState);

  const [loggedInUserUriLoadingState, loggedInUserUri] =
    useAsyncSelector(loggedInUserUriState);

  const [currentDepotCustodyLoadingState, currentDepotCustody] =
    useAsyncSelector(currentDepotCustodyState);

  const [currentDepotLoadingState, currentDepot] =
    useAsyncSelector(currentDepotState);

  const [currentDepotOnboardingDataLoadingState, currentDepotOnboardingData] =
    useAsyncSelector(currentDepotOnboardingState);

  const [loggedInUserOnboardingDataLoadingState, loggedInUserOnboardingData] =
    useAsyncSelector(loggedInUserOnboardingState);

  const [activeOnboardingDataLoadingState, activeOnboardingData] =
    useAsyncSelector(activeOnboardingState);

  const [depotOwnerOnboardingDataLoadingState, depotOwnerOnboardingData] =
    useAsyncSelector(currentDepotOnboardingState);

  const [firstTransactionLoadingState, firstTransaction] = useAsyncSelector(
    firstTransactionState
  );

  const [onboardingDepotLoadingState, onboardingDepot] = useAsyncSelector(
    notJuristicDepotState
  );

  const [onboardingDataLoadingState, onboardingData] = useAsyncSelector(
    onboardingByEnvironmentState("notJuristicOnboardingOwner")
  );

  const [loggedInUserProfileLoadingState, loggedInUserProfile] =
    useAsyncSelector(profileState("loggedInUser"));

  const [, cancellationDate] = useAsyncSelector(
    cancellationDateState("currentDepotOwner")
  );

  const isParentLegitimationRequired =
    loggedInUserOnboardingData?.legitimationRequired === true &&
    (onboardingData?.onboardingCompleted ||
      (onboardingData &&
        onboardingDepot &&
        isFirstGuardianForDepot(onboardingDepot) &&
        onboardingData.completedChapters.indexOf(
          "DOKUMENTE_GESETZLICHER_VERTRETER_1"
        ) > -1));

  const isJuniorDepotLegitimationRequired =
    (onboardingDepot &&
      isJuniorDepot(onboardingDepot) &&
      onboardingData?.legitimationRequired &&
      onboardingData.onboardingCompleted) ||
    false;

  const router = useRouter();

  const componentLoadingState = bundledLoadingState([
    currentDepotLoadingState,
    loggedInUserOnboardingDataLoadingState,
    depotOwnerOnboardingDataLoadingState,
    currentDepotCustodyLoadingState,
    firstTransactionLoadingState,
    loggedInUserProfileLoadingState,
    investmentPlanLoadingState,
    currentDepotOnboardingDataLoadingState,
    currentDepotAllocationLoadingState,
    loggedInUserUriLoadingState,
    hintDepotReadStatusLoadingState,
    activeOnboardingDataLoadingState,
    onboardingDepotLoadingState,
    onboardingDataLoadingState
  ]);

  const shouldDisplayDepotStatusHint =
    componentLoadingState !== "loading" &&
    !hintDepotReadStatus &&
    hintDepotReadStatus !== "empty";

  /* Cancellation status of the current Depot */
  const cancellationStatus =
    currentDepot &&
    loggedInUserProfile &&
    getCancellationStatus(currentDepot, loggedInUserProfile, cancellationDate);

  const isCancelled = !!cancellationStatus?.cancellationDate;

  const isCancelledButNotJuristic = isCancelled && !currentDepot?.juristic;

  const isLoading = componentLoadingState === "loading";

  if (
    !loggedInUserOnboardingData ||
    !depotOwnerOnboardingData ||
    !currentDepot ||
    !activeOnboardingData
  )
    return [isLoading, undefined, undefined];

  const onboardingWorkflowStatus = getWorkflowStatus(
    loggedInUserOnboardingData,
    depotOwnerOnboardingData,
    currentDepot,
    currentDepotCustody
  );

  const depotOpeningStatus =
    !isLoading &&
    currentDepotOnboardingData &&
    getDepotOpeningStatus(
      loggedInUserOnboardingData,
      depotOwnerOnboardingData,
      currentDepot,
      investmentPlan?.investmentPlanHistory || [],
      currentDepotCustody,
      !!cancellationDate,
      currentDepotAllocation
    );

  const currentDepotIsUnderage = isJuniorDepot(currentDepot);

  const firstTransactionIsDone =
    !firstTransaction || firstTransaction?.status === "FINISHED";

  const waitingForFirstTransaction =
    currentDepot.juristic && !firstTransactionIsDone;

  const firstTransactionIsDebitOrder = (firstTransaction?.value || 0) < 10000;

  // This defines in which state the legitimation hint should be shown
  const displayLegitimationInfo =
    !currentDepot.juristic &&
    (isParentLegitimationRequired || isJuniorDepotLegitimationRequired);

  const hideAllCenterHints = isCancelledButNotJuristic;

  const centerContextPlaceHolder = !hideAllCenterHints && (
    <>
      {shouldDisplayDepotStatusHint &&
        depotOpeningStatus &&
        loggedInUserUri &&
        depotOpeningStatus.displayLocation === "primary" && (
          <SDashboardCol margin="0 0 60px">
            <DepotOpeningStatusHint
              userUri={loggedInUserUri}
              depotId={currentDepot.id}
              depotOpeningStatus={depotOpeningStatus}
            />
          </SDashboardCol>
        )}

      {onboardingWorkflowStatus === "WAITING_FOR_GUARDIAN" &&
        (depotOwnerOnboardingData.secondGuardianInvitation === "present" ||
        depotOwnerOnboardingData.secondGuardianInvitation === "finished" ? (
          <WaitingForSecondGuardianDepotMessage
            finished={
              depotOwnerOnboardingData.secondGuardianInvitation === "finished"
            }
          />
        ) : (
          <WaitingForAnotherSecondGuardianInviteMessage />
        ))}
    </>
  );

  const hasNoTopRightHints = ![
    hintTestMode || displayLegitimationInfo,
    !isCancelled &&
      firstTransaction &&
      (hintTestMode || waitingForFirstTransaction),
    hintTestMode || onboardingWorkflowStatus === "NOT_FINISHED",
    cancellationStatus?.step === "pending" &&
      cancellationStatus.pendingCancellation,
    cancellationStatus?.step === "done" && cancellationStatus.cancellationDate
  ].some(a => a === true);

  const topRightContextPlaceHolder = (
    <>
      {!isCancelled &&
        firstTransaction &&
        (hintTestMode || waitingForFirstTransaction) && (
          <WaitingForFirstTransactionDepotMessage
            transactionAmount={firstTransaction.value}
            isJuniorDepot={currentDepotIsUnderage}
            isDebitOrder={firstTransactionIsDebitOrder}
          />
        )}

      {(hintTestMode || displayLegitimationInfo) && (
        <LegitimationRequiredDepotMessage />
      )}

      {(hintTestMode ||
        onboardingWorkflowStatus === "NOT_FINISHED" ||
        onboardingWorkflowStatus === "IS_TEST_DEPOT") && (
        <FinishDepotHintSwitch depot={currentDepot} />
      )}

      {depotOpeningStatus &&
        loggedInUserUri &&
        depotOpeningStatus.displayLocation === "secondary" && (
          <DepotOpeningStatusHint
            userUri={loggedInUserUri}
            depotId={currentDepot.id}
            depotOpeningStatus={depotOpeningStatus}
          />
        )}

      {cancellationStatus?.step === "pending" &&
        cancellationStatus.pendingCancellation && (
          <PendingDepotCancellationHint
            depot={currentDepot}
            actionNeeded={
              cancellationStatus.pendingCancellation.loggedInUserHasToApprove
            }
            description={cancellationStatus.pendingCancellation.description}
          />
        )}

      {cancellationStatus?.step === "done" &&
        cancellationStatus.cancellationDate && (
          <CompletedDepotCancellationHint
            cancellationDate={cancellationStatus.cancellationDate}
            depot={currentDepot}
          />
        )}
      {hasNoTopRightHints && !router.pathname.includes("support") && (
        <div style={{ margin: "1rem" }}>
          <BasicInfoBox
            icon={null}
            actions={[]}
            title="Haben Sie Fragen oder Anregungen?"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "left",
                gap: "2rem",
                maxWidth: 600,
                margin: "auto",
                marginBottom: "1rem"
              }}
            >
              <Image
                style={{
                  borderRadius: "50%",
                  objectFit: "cover"
                }}
                alt="contact"
                className="image"
                src="/assets/Tim_Bergmann_web.jpg"
                width={90}
                height={90}
              />
              {theme.premiumOnly ? (
                <div style={{ maxWidth: 400 }}>
                  Kontaktieren Sie Ihren persönlichen Ansprechpartner Tim
                  Bergmann über unser{" "}
                  <a
                    href="https://www.smavesto.de/premium/kontakt/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kontaktformular
                  </a>{" "}
                  oder telefonisch unter{" "}
                  <a href="tel:+4904211794519">+49 (0) 421 179 4519</a>.
                </div>
              ) : (
                <div style={{ maxWidth: 400 }}>
                  Unser Relationship Manager Tim Bergmann und sein Team kümmern
                  sich gerne um Ihr Anliegen. Kontaktieren Sie uns über unser{" "}
                  <a
                    href="https://www.smavesto.de/kontakt/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kontaktformular
                  </a>{" "}
                  oder unser Service-Telefon unter: <br />
                  <a href="tel:+498007767676">+49 (0) 800 7767676</a>.
                </div>
              )}
            </div>
          </BasicInfoBox>
        </div>
      )}
    </>
  );

  return [
    isLoading,
    topRightContextPlaceHolder,
    centerContextPlaceHolder,
    depotOpeningStatus &&
      shouldDisplayDepotStatusHint &&
      depotOpeningStatus?.displayLocation === "primary"
  ];
}
