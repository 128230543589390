import { ThemeContext } from "@pages/_app";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type { ModalFuncProps } from "antd";
import { Modal } from "antd";
import { useContext, useState } from "react";
import {
  openThemedModalConfirm,
  openThemedModalInfo
} from "../modal/openThemedModal";

export type ThemedModal = {
  destroy: () => void;
  update: (configUpdate: DestoryModal) => void;
};

export type DestoryModal = {
  destroy: () => void;
  update: (configUpdate: any) => void;
};

export type OpenModalFunc = (
  type: "info" | "confirm",
  modalProps: ModalFuncProps
) => ThemedModal;

function useThemedModal(allowOnlyOneModalAtTheTime?: boolean) {
  const [modal, contextHolder] = Modal.useModal();
  const { theme } = useContext(ThemeContext);

  const [currentlyOpenModal, setCurrentlyOpenModal] = useState<DestoryModal>();

  function openModal(
    type: "info" | "confirm",
    modalProps: ModalFuncProps
  ): ThemedModal {
    smavestoCore.debugLog("info", `open modal of type ${type}`);

    if (allowOnlyOneModalAtTheTime && currentlyOpenModal)
      currentlyOpenModal.destroy();

    let currentModal: DestoryModal | undefined;
    if (type === "info")
      currentModal = openThemedModalInfo(modal, modalProps, theme);
    else if (type === "confirm")
      currentModal = openThemedModalConfirm(modal, modalProps, theme);

    if (!currentModal) throw new Error("Unknown modal type");

    setCurrentlyOpenModal(currentModal);

    return currentModal;
  }

  return { contextHolder, openModal, theme };
}

export default useThemedModal;
