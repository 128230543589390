import type WorkflowStep from "../models/WorkflowStep";
import type WorkflowStepGroup from "../models/WorkflowStepGroup";

/* This method returns the active step of a workflow or undefined if the workflow is finished */
const getActiveStep = (
  onboardingStepGroup: WorkflowStepGroup[],
  completedChapters: string[]
): WorkflowStep | undefined => {
  const steps = onboardingStepGroup.flatMap(group => group.items);

  for (const step of steps) {
    if (!completedChapters.includes(step.chapterName)) {
      return step;
    }
  }

  return undefined;
};

export default getActiveStep;
