import moment from "moment";
import type { PlannedMaintenance } from "../services/firebase.service";
import { fireBaseService } from "../services/firebase.service";

export type MaintenaceModeDetails = {
  isCurrentlyActive: boolean;
  description?: string;
  plannedMaintenaceInTheFuture?: PlannedMaintenance;
};

export async function getMaintenanceModeDetails(): Promise<MaintenaceModeDetails> {
  const plannedMaintenanceModes =
    await fireBaseService.getPlannedMaintenaceModes();

  const activeMaintenance = plannedMaintenanceModes.find(item =>
    isMaintenanceActive(item)
  );

  if (activeMaintenance)
    return { isCurrentlyActive: true, description: activeMaintenance.reason };

  if (plannedMaintenanceModes.length)
    return {
      isCurrentlyActive: false,
      plannedMaintenaceInTheFuture: plannedMaintenanceModes[0]
    };

  return {
    isCurrentlyActive: false
  };

  /* 
  const STATIC_MAINTENANCE_MODE_START = moment(new Date("2022-12-06T06:00:00"));
  const STATIC_MAINTENANCE_MODE_END = moment(new Date("2022-12-07T22:00:00"));

  const isBetween = moment(new Date()).isBetween(
    STATIC_MAINTENANCE_MODE_START,
    STATIC_MAINTENANCE_MODE_END
  );

  return isBetween; */
}

const isMaintenanceActive = (plannedMaintenance: PlannedMaintenance) => {
  const currentTimeStamp = moment();
  const startMoment = moment.unix(plannedMaintenance.startTimestamp);
  const endMoment = moment.unix(plannedMaintenance.endTimestamp);

  const isMatching =
    currentTimeStamp.isAfter(startMoment) &&
    currentTimeStamp.isBefore(endMoment);

  return isMatching;
};
