import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { useRouter } from "next/router";
import { useEffect } from "react";

function useSessionHealthState() {
  const { push, pathname } = useRouter();

  const pageAccessWithoutValidSession = [
    "/login",
    "/maintenance",
    "/change-email",
    "/registration",
    "/safe-logout"
  ];

  const handleInvalidSession = () => {
    smavestoCore.reset();
    push("/safe-logout?ref=/login%3FloggedOut=true");
  };

  useEffect(() => {
    const currentPagePathIsPublic =
      pageAccessWithoutValidSession.includes(pathname);

    if (
      !currentPagePathIsPublic &&
      smavestoCore.services.auth.lastApiCallReturnedInvalidSession === true
    ) {
      handleInvalidSession();
    }
  }, [smavestoCore.services.auth.lastApiCallReturnedInvalidSession]);
}

export default useSessionHealthState;
