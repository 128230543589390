import { useState } from "react";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { useSafeLogout } from "@src/page-components/onboarding/hooks";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";
import { depotForUserUriState } from "@src/state/depot.state";
import { bankDetailsStateForUserUri } from "@states/bank-details.state";
import { cancellationDateStateForUserUri } from "@states/cancellation.state";
import { useRefreshState } from "@states/request.state";
import useThemedModal from "@theme/hook/useThemedModal";
import { Input, Modal } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

export const useCancellation = (
  userUri: string,
  depotId?: string,
  isTestDepot?: boolean
) => {
  const { contextHolder, openModal } = useThemedModal();

  const [depotNumberConfirmationForm] = useForm();

  const [cancellationDoubleConfirmIsOpen, setCancellationDoubleConfirmIsOpen] =
    useState<boolean>(false);

  const [, cancellationDate] = useAsyncSelector(
    cancellationDateStateForUserUri(userUri)
  );

  const refreshCancellationDateInfo = useRefreshState(
    "cancellationDate",
    "currentDepotOwner",
    userUri
  );

  const logout = useSafeLogout();

  const refreshDepots = useRefreshState("depots", "loggedInUser");

  const [visible, setVisible] = useState(false);

  const [, depot] = useAsyncSelector(depotForUserUriState(userUri));

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [, bankDetails] = useAsyncSelector(bankDetailsStateForUserUri(userUri));

  const iBan = bankDetails?.payment?.bankAccount?.iban ?? "";

  const refreshCancellationRelatedStates = () => {
    refreshCancellationDateInfo();
    refreshDepots();
  };

  const openCustomerServiceModal = () =>
    openModal("confirm", {
      title: "Hinweis",
      content:
        "Die Kündigung konnte nicht durchgeführt werden. Bitte wenden Sie sich an den Kundenservice.",
      okText: "Kundenservice kontaktieren",
      onOk: () => {
        window.open("https://www.smavesto.de/kontakt");
      },
      cancelText: "Abbrechen"
    });

  const approveCancellation = async () => {
    setConfirmLoading(true);

    const approveCancellationResponse =
      await smavestoCore.services.user.performCancellation(userUri, "Approve");

    if (isApiErrorResponse(approveCancellationResponse)) {
      openCustomerServiceModal();
    }
    refreshCancellationRelatedStates();
    setConfirmLoading(false);
  };

  const rejectCancellation = async () => {
    setConfirmLoading(true);
    const rejectCancellationResponse =
      await smavestoCore.services.user.performCancellation(userUri, "Reject");

    if (isApiErrorResponse(rejectCancellationResponse)) {
      openCustomerServiceModal();
    }

    refreshCancellationRelatedStates();
    setConfirmLoading(false);
  };

  const onConfirm = () => {
    setCancellationDoubleConfirmIsOpen(true);
  };

  const onFinalconfirm = async () => {
    if (!userUri) return;

    setConfirmLoading(true);

    const cancellationResponse = depotId
      ? await smavestoCore.services.depot.cancellation(depotId)
      : await smavestoCore.services.user.performCancellation(userUri);

    if (isApiErrorResponse(cancellationResponse)) {
      openModal("confirm", {
        title: "Hinweis",
        content:
          "Die Kündigung konnte nicht durchgeführt werden. Bitte wenden Sie sich an den Kundenservice.",
        okText: "Kundenservice kontaktieren",
        onOk: () => {
          window.open("https://www.smavesto.de/kontakt");
        },
        cancelText: "Abbrechen"
      });
    }

    refreshCancellationRelatedStates();
    setConfirmLoading(false);
    setVisible(false);

    if (isTestDepot) await logout();
  };

  const onDoubleConfirmCancellation = (values: any) => {
    if (values.depotnumber === depot?.displayName) {
      setCancellationDoubleConfirmIsOpen(false);
      onFinalconfirm();
    }
  };

  const modalContextHolder = (
    <>
      <Modal
        okText="Kündigung bestätigen"
        cancelText="Abbrechen"
        title="Kündigung bestätigen"
        onOk={() => depotNumberConfirmationForm.submit()}
        visible={cancellationDoubleConfirmIsOpen}
        onCancel={() => setCancellationDoubleConfirmIsOpen(false)}
      >
        Bitte bestätigen Sie die Kündigung des Depots, indem Sie die{" "}
        <PopoverInfo
          title="Depotnummer"
          text="Die Depotnummer finden Sie oben links in Ihrem Menü, neben dem Namen des Depotinhabers."
        >
          Depotnummer
        </PopoverInfo>{" "}
        eingeben.
        <br />
        <br />
        <Form
          onFinish={onDoubleConfirmCancellation}
          form={depotNumberConfirmationForm}
        >
          <FormItem
            name="depotnumber"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie die Depotnummer ein."
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  return !value ||
                    getFieldValue("depotnumber") === depot?.displayName
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "Die eingebende Depotnummer stimmt nicht mit Ihrem ausgewählten Depot überein."
                        )
                      );
                }
              })
            ]}
          >
            <Input title="Depotnummer" />
          </FormItem>
        </Form>
      </Modal>

      {contextHolder}
    </>
  );

  return {
    visible,
    setVisible,
    cancellationDate,
    confirmLoading,
    iBan,
    onConfirm,
    approveCancellation,
    rejectCancellation,
    modalContextHolder
  };
};
