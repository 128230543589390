import { CheckOutlined } from "@ant-design/icons";
import cx from "classnames";
import { useRouter } from "next/router";
import React, { useContext, useRef, useState } from "react";

import { loggedInUserUriState } from "@states/auth.state";
import {
  activeOnboardingDepotState,
  userIsSecondGuardianState
} from "@states/depot.state";
import {
  onboardingByEnvironmentState,
  onboardingUserCompletedSummaryPageState
} from "@states/onboarding.state";
import removeQueryString from "@utils/query-params/removeQueryString";
import getAllPreviousSteps from "@workflows/engine/methods/getAllPreviousSteps";
import type WorkflowStep from "@workflows/engine/models/WorkflowStep";
import underageOnboardingStepsFirstGuardian from "@workflows/onboarding-underage/workflow-paths/underageOnboardingStepsFirstGuardian";
import underageOnboardingStepsSecondGuardian from "@workflows/onboarding-underage/workflow-paths/underageOnboardingStepsSecondGuardian";

import { ThemeContext } from "@pages/_app";
import type { ICompBaseProps } from "@src/interfaces/jsx.interface";
import Image from "next/image";

import isLegitimated from "smavesto.core/lib/utils/typeguards/isLegitimated";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import Hamburger from "../HamburgerMenu";
import {
  StyledLink,
  StyledMenu,
  StyledNav
} from "../OnboardingMobileMenu/MobileMenu.styled";
import styles from "./style.module.less";

interface OnboardingUnderageNavigationProps extends ICompBaseProps {
  completedChapters: string[];
}

export const MobileUnderageMenu: React.FunctionComponent<
  OnboardingUnderageNavigationProps
> = ({ completedChapters }) => {
  const { push, route, query } = useRouter();

  const { theme } = useContext(ThemeContext);

  const isNotLoggedInSecondGuardian = !!query.continueOnboarding;

  const [, userUri] = useAsyncSelector(loggedInUserUriState);

  const [, onboardingUserCompletedSummary] = useAsyncSelector(
    onboardingUserCompletedSummaryPageState
  );

  const [, userIsSecondGuardian] = useAsyncSelector(userIsSecondGuardianState);

  const [, onboarding] = useAsyncSelector(
    onboardingByEnvironmentState("loggedInUser")
  );

  const [, activeOnboarding] = useAsyncSelector(activeOnboardingDepotState);

  const underageOnboardingSteps =
    userIsSecondGuardian || isNotLoggedInSecondGuardian
      ? underageOnboardingStepsSecondGuardian
      : underageOnboardingStepsFirstGuardian(!!activeOnboarding);

  // We disable all personal data steps of the guardian if this user is allready legitimated
  const userIsLegitimated = onboarding ? isLegitimated(onboarding) : false;

  const legitimatedUserDisabledSteps = [
    "PERSOENLICHE_ANGABEN_SORGEBERECHTIGTER",
    "KENNTNISSE_SORGEBERECHTIGTER",
    "ZUGANGSDATEN"
  ];

  const userIsLoggedIn = !!userUri;

  const loggedInUserDisabledSteps = ["ZUGANGSDATEN"];

  const disabledSteps = (
    userIsLoggedIn ? loggedInUserDisabledSteps : []
  ).concat(userIsLegitimated ? legitimatedUserDisabledSteps : []);

  const isActiveStep = (step: WorkflowStep) => {
    return (
      route === removeQueryString(step.to) ||
      step.subSteps?.some(step => route === removeQueryString(step.to))
    );
  };

  const isDisabled = (step: WorkflowStep): boolean => {
    // We ignore this if the user has this step currently open
    if (isActiveStep(step)) return false;

    const previousSteps = getAllPreviousSteps(
      step.chapterName,
      underageOnboardingSteps
    );

    if (previousSteps.some(previousStep => !isCompleted(previousStep)))
      return true;

    return disabledSteps.includes(step.chapterName);
  };

  const isCompleted = (chapterName: string): boolean => {
    /* Workaround for the missing chapter at DSER */
    if (chapterName === "SUMMARY" && onboardingUserCompletedSummary === true)
      return true;

    return completedChapters.includes(chapterName);
  };

  const node = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const noStepIsActive = !underageOnboardingSteps
    .flatMap(section => section.items)
    .some(a => isActiveStep(a));

  let currentIndex = 0;

  return (
    <div ref={node}>
      <StyledMenu open={open}>
        <div className="wrap-main-logo">
          {theme.assets.logoImage && (
            <Image
              style={{
                objectFit: "contain",
                objectPosition: "top"
              }}
              width={200}
              height={200}
              alt="SMAVESTO - Einfach anlegen"
              src={theme.assets.logoImage}
            />
          )}
        </div>
        <StyledNav>
          {underageOnboardingSteps.map((section, sectionIndex: number) => (
            <div key={section.order}>
              <br />
              <p style={{ color: "white" }}>{section.title}</p>
              {section.items.map((item, index: number) => {
                currentIndex += 1;
                return (
                  <StyledLink
                    key={section.order}
                    onClick={async () => {
                      if (!isDisabled(item)) {
                        await push({
                          pathname: removeQueryString(item.to),
                          query
                        });
                        setOpen(false);
                      }
                    }}
                    style={{
                      boxShadow:
                        isActiveStep(item) ||
                        (noStepIsActive && index === 0 && sectionIndex === 0)
                          ? `0 0 4px 2px ${theme.palette.primary}`
                          : "none",
                      borderRadius: "20px 0px 0px 20px"
                    }}
                    className={cx({
                      [styles.exact]: isCompleted(item.chapterName),
                      [styles.disabled]: isDisabled(item)
                    })}
                  >
                    <span style={{ marginRight: 20 }}>
                      {isCompleted(item.chapterName) ? (
                        <CheckOutlined />
                      ) : (
                        currentIndex
                      )}
                    </span>

                    <span style={{ marginLeft: 10 }}>
                      {item.text_short || item.text}
                    </span>
                  </StyledLink>
                );
              })}
            </div>
          ))}
        </StyledNav>
      </StyledMenu>

      <Hamburger open={open} setOpen={setOpen} />
    </div>
  );
};
