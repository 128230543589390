import { selectorFamily } from "recoil";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { userUriState } from "@states/auth.state";
import getGiftCodeObjectByGiftCodeString from "smavesto.core/lib/business-logic/gift-codes/getGiftCodeObjectByGiftCodeString";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";

import { getRequestState } from "./request.state";

export const giftCodeNotUnset = selectorFamily({
  key: "giftcode.dev.notunset",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("giftcode", userUri));

      if (!userUri) return undefined;

      const giftcode = await smavestoCore.services.keyValueStore.getGiftCode(
        userUri
      );

      const giftCodeObject = await getGiftCodeObjectByGiftCodeString(
        successOrUndefined(giftcode) || "",
        smavestoCore
      );

      return giftCodeObject;
    }
});

export const giftCodeState = selectorFamily({
  key: "giftcode.code",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("giftcode", userUri));

      if (!userUri) return undefined;

      const activatedGiftCode = get(giftCodeActivationState(type));

      const giftcode = await smavestoCore.services.keyValueStore.getGiftCode(
        userUri
      );

      if (giftcode === "empty" || activatedGiftCode) return undefined;

      return getGiftCodeObjectByGiftCodeString(
        successOrUndefined(giftcode) || "",
        smavestoCore
      );
    }
});

export const giftCodeActivationState = selectorFamily({
  key: "giftcode.activation",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("giftcode", userUri));

      if (!userUri) return undefined;

      const giftcodeActivation =
        await smavestoCore.services.keyValueStore.getGiftCodeActivation(
          userUri
        );

      return successOrUndefined(giftcodeActivation);
    }
});
