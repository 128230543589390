import { useState } from "react";

import EditCustodyModalForm from "@components/entities/custody/modal/EditCustodyModalForm";
import { LockedDepotMessage } from "@page-components/dashboard/Depot/locked-message/LockedDepotMessage";
import {
  SIconCircle,
  SMailIcon
} from "@page-components/public/registration/activation/styled";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";
import { SP } from "@styled-components/STags";

interface WaitingForSecondGuardianDepotMessageProps {
  finished?: boolean;
}

const WaitingForSecondGuardianDepotMessage: React.FunctionComponent<
  WaitingForSecondGuardianDepotMessageProps
> = ({ finished }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openEditModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <LockedDepotMessage
        actions={
          !finished && (
            <SActionButtonsWrapper style={{ marginTop: 30 }}>
              <SButton
                onClick={() => openEditModal()}
                className="reset"
                style={{ marginRight: "auto" }}
              >
                E-Mail erneut versenden
              </SButton>
            </SActionButtonsWrapper>
          )
        }
        icon={
          <SIconCircle>
            <SMailIcon />
          </SIconCircle>
        }
      >
        <SP fontWeight={600} fontSize="20px">
          Die nächsten Schritte
        </SP>
        {finished ? (
          <SP fontWeight={400} fontSize="16px">
            Der gesetzliche Vertreter hat Ihre Einladung angenommen.
          </SP>
        ) : (
          <>
            <SP fontWeight={400} fontSize="16px">
              Es wurde eine E-Mail an den gesetzlichen Vertreter Ihres Kindes
              geschickt, in dieser befindet sich ein Link über den dieser
              gemeinsam mit Ihnen die Kontoeröffnung Ihres Kindes
              vervollständigen und beenden kann.
            </SP>
            <SP fontWeight={400} fontSize="16px">
              Erst danach kann das Depot Ihres Kindes eröffnet werden!
            </SP>
          </>
        )}
      </LockedDepotMessage>
      {!finished && (
        <EditCustodyModalForm
          onCancel={() => setModalOpen(false)}
          isOpen={isModalOpen}
        />
      )}
    </>
  );
};

export default WaitingForSecondGuardianDepotMessage;
