import { atom, selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { userUriState } from "@states/auth.state";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import type RiskYieldDto from "smavesto.core/lib/types/dto/user/RiskYieldDto";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import { getRequestState, useRefreshState } from "./request.state";

export type LocalRiskYieldType = RiskYieldDto & {
  defaultStartAmount: string;
  defaultMonthlyAmount: string;
};

export const localRiskYieldState = atom<LocalRiskYieldType | undefined>({
  key: "localRiskYield",
  default: undefined
});

export const riskYieldState = selectorFamily({
  key: "riskYield",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("riskYield", userUri));

      return userUri
        ? successOrUndefined(
            await smavestoCore.services.user.getRiskYield(userUri)
          )
        : undefined;
    }
});

export const riskYieldStateForUserUri = selectorFamily({
  key: "riskYieldStateForUserUri",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("riskYield", userUri));

      return successOrUndefined(
        await smavestoCore.services.user.getRiskYield(userUri)
      );
    }
});

export const isStartAmountLargeState = selectorFamily({
  key: "IsStartAmountLarge",
  get:
    (type: StateEnvironmentUser) =>
    ({ get }) => {
      const riskYield = get(riskYieldState(type))?.startbetrag ?? 0;

      return riskYield > 10000;
    }
});

export const useRefreshRiskYield = (type: StateEnvironmentUser) =>
  useRefreshState("riskYield", type);
