import { useContext, useEffect } from "react";

import { ThemeContext } from "@pages/_app";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { allocationHintReadStateForCurrentDepotState } from "@src/state/onboarding.state";
import useIsMobile from "@src/theme/hook/useIsMobile";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { Col, Row } from "antd";
import type { DepotOpeningStatus } from "smavesto.core/lib/business-logic/workflow/getDepotOpeningStatus";
import Tips from "../../../assets/tips-icon_svg.svg";
import { SimpleMenuButton } from "../navigation/OnboardingNavigation/SimpleMenuButton";

interface DepotOpeningStatusHintProps {
  depotOpeningStatus: DepotOpeningStatus;
  userUri: string;
  depotId: string;
}

const DepotOpeningStatusHint: React.FunctionComponent<
  DepotOpeningStatusHintProps
> = ({ depotOpeningStatus, userUri, depotId }) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const { allSteps, currentStepIndex, hintContent, displayLocation } =
    depotOpeningStatus;

  const isMobile = useIsMobile();

  const [hintDepotLoadingState, hintDepotReadStatus] = useAsyncSelector(
    allocationHintReadStateForCurrentDepotState
  );

  const shouldDisplayHint =
    hintDepotLoadingState === "hasValue" &&
    !hintDepotReadStatus &&
    hintDepotReadStatus !== "empty";

  useEffect(() => {
    if (currentStepIndex === 3 && shouldDisplayHint === true) {
      smavestoCore.services.keyValueStore.markDepotAllocationHintAsRead(
        userUri,
        depotId
      );
    }
  }, [currentStepIndex]);

  if (shouldDisplayHint)
    return (
      <div
        style={{
          background: appModeTheme?.secondLayerBaseBackgroundColor,
          color: appModeTheme?.secondLayerTextColor,
          maxWidth: 1200,
          margin: displayLocation === "primary" ? "auto" : "28px 8px",
          border:
            displayLocation === "primary"
              ? ""
              : `2px solid ${theme.palette.primary}`,
          borderRadius: 35,
          padding: "10px 5px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {displayLocation === "secondary" && (
            <div
              style={{ width: 40, height: 70, marginTop: 10, marginLeft: 10 }}
              className="icon"
            >
              <Tips fill={theme.palette.primary} height={40} alt="icon-tips" />
            </div>
          )}
          <h2
            style={{
              color:
                displayLocation === "secondary"
                  ? theme.palette.primary
                  : appModeTheme?.secondLayerTextColor
            }}
          >
            Lehnen Sie sich zurück, wir arbeiten für Sie...
          </h2>
        </div>
        <div
          style={{
            padding: displayLocation === "primary" ? "20px 0px" : "0px 30px"
          }}
        >
          <Row
            style={{
              justifyContent: "stretch",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "nowrap"
            }}
          >
            {allSteps.map((step, index) => (
              <>
                <Col>
                  <SimpleMenuButton
                    style={{
                      boxShadow: `0 0 10px 5px ${theme.palette.primary}`
                    }}
                    disabled={currentStepIndex < index}
                    title={step.stepLabel || ""}
                    size={displayLocation === "primary" ? "large" : "small"}
                    order={(index + 1).toString()}
                    complete={currentStepIndex >= index}
                  />
                </Col>
                {!isMobile && index < allSteps.length - 1 && (
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "100%",
                        width: displayLocation === "primary" ? 20 : 10,
                        marginLeft: 10,
                        height: displayLocation === "primary" ? 10 : 5,
                        content: " ",
                        opacity: currentStepIndex >= index ? 1 : 0.5,
                        backgroundColor: theme.palette.primary
                      }}
                    >
                      &nbsp;
                    </div>
                  </Col>
                )}
              </>
            ))}
          </Row>
          <div
            style={{
              maxWidth: displayLocation === "primary" ? 600 : "100%",
              margin: displayLocation === "primary" ? "auto" : 0,
              marginTop: displayLocation === "primary" ? 40 : 10,
              paddingTop: 10,
              color:
                displayLocation === "secondary"
                  ? theme.palette.primary
                  : appModeTheme?.secondLayerTextColor
            }}
          >
            <h2 style={{ color: "inherit" }}>{hintContent?.title}</h2>

            <p>{hintContent?.content}</p>
          </div>
        </div>
      </div>
    );

  return null;
};

export default DepotOpeningStatusHint;
