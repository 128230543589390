import styled from "styled-components";

import theme from "../../../styles/theme";

export const StyledButton = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  && .title {
    font-weight: bold;
  }

  && .button {
    border-radius: 30px;
    font-weight: bold;
    padding: 20px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .submit {
    background-color: ${theme.primary};
    border: 2px solid ${theme.primary};
    color: inherit;
    box-shadow: inset 0px 0px 0px ${theme.primary};
    transition: all 0.5s !important;
  }

  && .default {
    color: black;
    background-color: ${theme.secondary};
    border: 2px solid ${theme.secondary};
    box-shadow: inset 0px 0px 0px ${theme.secondary};
    transition: all 0.5s !important;
  }

  && .submit:hover {
    box-shadow: inset 0px -80px 0px ${theme.primaryHover};
    border: 2px solid ${theme.primaryHover};
  }

  && .reset {
    background-color: transparent;
    border: 2px solid inherit;
    color: inherit;
    box-shadow: inset 0px 0px 0px white;
    transition: all 0s !important;
  }

  span {
    transition: all 0s !important;
  }

  && .reset:hover {
    box-shadow: inset 0px -80px 0px white;
    color: black;
  }

  && .link {
    color: inherit;
    transition: all 0.5s !important;
    text-decoration: underline;
  }

  && .link:hover {
    color: ${theme.primary};
  }
`;
