import { useAsyncSelector } from "@hooks/useAsyncSelector";
import JsonViewer from "@src/components/other/dev/JsonViewer";
import { useSupplierAndAffiliateOrigin } from "@src/hooks/onboarding/affiliate/useSupplierAndAffiliateOrigin";
import { loggedInUserOnboardingState } from "@src/state/onboarding.state";
import { userGlobalState } from "@states/global.state";
import { Collapse } from "antd";
import isLegitimatedForDepotOpening from "smavesto.core/lib/utils/typeguards/isLegitimatedForDepotOpening";

export function GlobalStateView() {
  const [, userState] = useAsyncSelector(userGlobalState);

  const { affiliateData } = useSupplierAndAffiliateOrigin(false);

  const { Panel } = Collapse;

  const [, onboarding] = useAsyncSelector(loggedInUserOnboardingState);

  return (
    <Collapse defaultActiveKey={["1"]}>
      <Panel header={<>Legitimations-Status</>} key="1">
        {!onboarding
          ? "Bitte warten..."
          : isLegitimatedForDepotOpening(onboarding)
          ? "🟢 Der Nutzer hat eine aktive Legitimation und kann neue Depots damit eröffnen."
          : "🔴 Der Nutzer hat keine aktive Legitimation und muss sich vor der Eröffnung eines Depot legitimieren."}
      </Panel>

      <Panel header={<>Zwischenspeicher für den Nutzer </>} key="2">
        <pre style={{ fontSize: 12 }}>
          <JsonViewer value={userState} />
        </pre>
      </Panel>
      <Panel header="Werbepartner und Tracking" key="3">
        <pre style={{ fontSize: 12 }}>
          <JsonViewer value={affiliateData} />
        </pre>
      </Panel>
      <Panel header="Bereitstellungskonfiguration" key="4">
        <pre style={{ fontSize: 12 }}>
          <JsonViewer
            value={{
              NEXT_PUBLIC_USE_DEBUG: process.env.NEXT_PUBLIC_USE_DEBUG,
              NEXT_PUBLIC_CLIENT_ID: process.env.NEXT_PUBLIC_CLIENT_ID
            }}
          />
        </pre>
      </Panel>
    </Collapse>
  );
}
