import { notification } from "antd";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

import { globalErrorState } from "@states/common.state";
import type { ApiErrorResponse } from "smavesto.core/lib/types/api/generic/ApiErrorResponse";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import * as Sentry from "@sentry/react";
import convertApiErrorResponseToError from "smavesto.core/lib/utils/processing/convertApiErrorResponseToError";

type DisplayType = "none" | "modal" | "info";

export type ErrorHandlerFunc = (
  apiErrorResponse: ApiErrorResponse,
  displayType: DisplayType,
  messageType: "error" | "info",
  overwriteErrorCodes?:
    | {
        key: string;
        value: string;
      }[]
    | undefined
) => void;

export function useApiErrorHandler(): [
  (
    apiErrorResponse: ApiErrorResponse,
    displayType: DisplayType,
    messageType: "error" | "info",
    overwriteErrorCodes?: { key: string; value: string }[],
    actionOnAcceptError?: () => void
  ) => void,
  <T>(methodResult: ApiErrorResponse | any) => T
] {
  const router = useRouter();
  const setCatchedError = useSetRecoilState(globalErrorState);

  /* 
  This method can be used if an error is not assumed
   If an error does occur, it will be handled.
   */
  function expect<T>(methodResult: ApiErrorResponse | any): T {
    if (isApiErrorResponse(methodResult)) {
      handleApiResponse(methodResult, "modal", "error");
      throw new Error("Illegal result: Expected no error response.");
    } else {
      return methodResult as T;
    }
  }

  const handleApiResponse = (
    apiErrorResponse: ApiErrorResponse,
    displayType: DisplayType,
    messageType: "error" | "info",
    overwriteErrorCodes?: { key: string; value: string }[]
  ) => {
    let sentryErrorCode;

    /* unhandled dser exception */
    if (!apiErrorResponse.key) {
      sentryErrorCode = Sentry.captureException(
        convertApiErrorResponseToError(apiErrorResponse),
        {
          extra: apiErrorResponse
        }
      );
    }

    if (!apiErrorResponse.hasResponse && displayType !== "none")
      // if we have no response its maybe a network issue
      notification.error({
        message: apiErrorResponse.humanReadableError
      });
    // Token is invalid, the user should be logged out
    else if (
      apiErrorResponse.key === "token.notFound" ||
      apiErrorResponse.key === "user.not.authorized"
    )
      router.push("/login?loggedOut=true");
    else if (displayType !== "none")
      display(
        apiErrorResponse,
        displayType,
        messageType,
        overwriteErrorCodes,
        sentryErrorCode
      );
  };

  const display = (
    apiErrorResponse: ApiErrorResponse,
    displayType: DisplayType,
    // eslint-disable-next-line unused-imports/no-unused-vars-ts
    messageType: "error" | "info",
    overwriteErrorCodes?: { key: string; value: string }[],
    sentryErrorCode?: string
  ) => {
    const overwrittenErrorCode = overwriteErrorCodes?.find(
      item => item.key === apiErrorResponse.key
    );

    const humanReadableError = overwrittenErrorCode
      ? overwrittenErrorCode.value
      : apiErrorResponse.humanReadableError;

    if (displayType === "info")
      notification.error({
        message: humanReadableError
      });

    if (displayType === "modal") {
      setCatchedError({
        error: apiErrorResponse.error,
        title: "Hinweis",
        bodyText: humanReadableError,
        showDetailedError: false,
        apiErrorResponse,
        sentryErrorCode
      });
    }
  };

  return [handleApiResponse, expect];
}
