import { useEffect, useState } from "react";

export function useWindowDimensions(updateOnScroll?: boolean) {
  const hasWindow = typeof window !== "undefined";

  const [windowDimensions, setWindowDimensions] = useState({
    width: hasWindow ? window.innerWidth : 1000,
    height: hasWindow ? window.innerHeight : 1000,
    scrollHeight: hasWindow ? window.document.body.scrollHeight : 1000
  });

  useEffect(() => {
    if (!hasWindow) return undefined;

    const handleResize = () =>
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        scrollHeight: window.document.body.scrollHeight
      });
    window.addEventListener("resize", handleResize);
    if (updateOnScroll) window.addEventListener("scroll", handleResize);

    handleResize();
    return () => {
      window.document.body.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleResize);
      if (updateOnScroll) window.removeEventListener("scroll", handleResize);
    };
  }, [hasWindow]);

  return windowDimensions;
}
