import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import type { ReactNode } from "react";
import React from "react";

import type {
  ICompBaseProps,
  PropsWithChildren
} from "@src/interfaces/jsx.interface";

import type { TooltipPlacement } from "antd/lib/tooltip";
import styles from "./styles.module.less";

export interface PopoverInfoProps extends ICompBaseProps, PropsWithChildren {
  text: string | ReactNode;
  title: string;
  placement?: TooltipPlacement;
}

const PopoverInfo: React.FunctionComponent<PopoverInfoProps> = ({
  text,
  title,
  children,
  placement
}) => (
  <span>
    {children}&nbsp;&nbsp;
    <Popover
      placement={placement}
      title={<div className={styles.title}>{title}</div>}
      content={<div className={styles.content}>{text}</div>}
    >
      <InfoCircleOutlined
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Popover>
  </span>
);

export default PopoverInfo;
