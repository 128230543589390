import styled from "styled-components";

import theme from "@styles/theme";

export const SA = styled.a<{
  iconFont?: string;
  fontSize?: string;
  withVisited?: boolean;
}>`
  color: inherit;
  transition: all 0.5s;
  text-decoration: underline;
  font-size: ${({ fontSize = "inherit" }) => fontSize};

  &:hover {
    color: ${theme.primary};
  }

  &:visited {
    color: ${({ withVisited = true }) =>
      withVisited ? theme.primary : "#fff"};
  }

  .anticon {
    font-size: ${({ iconFont = "11px" }) => iconFont};
  }
`;

export const SLinkList = styled.div<{
  iconFont?: string;
  fontSize?: string;
  withVisited?: boolean;
}>`
  a {
    color: inherit;
    transition: all 0.5s;
    text-decoration: underline;
    font-size: ${({ fontSize = "inherit" }) => fontSize};
  }

  &:hover {
    color: ${theme.primary};
  }

  &:visited {
    color: ${({ withVisited = true }) =>
      withVisited ? theme.primary : "#fff"};
  }

  .anticon {
    font-size: ${({ iconFont = "11px" }) => iconFont};
  }
`;

export const SLink = styled.link<{
  iconFont?: string;
  fontSize?: string;
  withVisited?: boolean;
}>`
  color: inherit;
  transition: all 0.5s;
  text-decoration: underline;
  font-size: ${({ fontSize = "inherit" }) => fontSize};

  &:hover {
    color: ${theme.primary};
  }

  &:visited {
    color: ${({ withVisited = true }) =>
      withVisited ? theme.primary : "#fff"};
  }

  .anticon {
    font-size: ${({ iconFont = "11px" }) => iconFont};
  }
`;

interface BlockProps {
  width?: string;
  height?: string;
  minHeight?: string;
  display?: string;
  flexDirection?: "column" | "column-reverse" | "row" | "row-reverse";
  justifyContent?:
    | "center"
    | "end"
    | "flex-end"
    | "flex-start"
    | "start"
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "stretch";
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  alignItems?:
    | "center"
    | "end"
    | "flex-end"
    | "flex-start"
    | "self-end"
    | "self-start"
    | "start"
    | "baseline"
    | "normal"
    | "stretch";
  margin?: string;
  padding?: string;
}

type TypographyProps = {
  width?: string;
  margin?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  textAlign?: string;
};

export const SH2 = styled.h2<TypographyProps>`
  width: ${({ width = "100%" }) => width};
  margin: ${({ margin = "0px" }) => margin};
  padding: ${({ padding = "0px" }) => padding};
  font-size: ${({ fontSize = "22px" }) => fontSize};
  font-weight: ${({ fontWeight = 500 }) => fontWeight};
  color: ${({ color = "" }) => color};
  text-align: ${({ textAlign = "" }) => textAlign};
`;

export const SP = styled.p<TypographyProps>`
  width: ${({ width = "100%" }) => width};
  margin: ${({ margin = "0px" }) => margin};
  padding: ${({ padding = "0px" }) => padding};
  font-size: ${({ fontSize = "14px" }) => fontSize};
  font-weight: ${({ fontWeight = 400 }) => fontWeight};
  color: ${({ color = "inherit" }) => color};
  text-align: ${({ textAlign = "" }) => textAlign};
`;

export const SSection = styled.section<BlockProps>`
  width: ${({ width = "100%" }) => width};
  height: ${({ height = "" }) => height};
  min-height: ${({ minHeight = "1px" }) => minHeight};
  display: ${({ display = "block" }) => display};
  flex-direction: ${({ flexDirection = "" }) => flexDirection};
  justify-content: ${({ justifyContent = "" }) => justifyContent};
  align-items: ${({ alignItems = "" }) => alignItems};
  flex-wrap: ${({ flexWrap = "" }) => flexWrap};
  margin: ${({ margin = "0px" }) => margin};
  padding: ${({ padding = "0px" }) => padding};
`;
