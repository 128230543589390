import { ThemeContext } from "@src/pages/_app";
import type { InputProps, InputRef } from "antd";
import { Input, InputNumber } from "antd";
import type { FC, RefObject } from "react";
import { useContext } from "react";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";
import styled, { css } from "styled-components";

export const baseInputStyles = css`
  width: 100%;
  height: 58px;
  padding-left: 25px;
  box-shadow: none;
  border-radius: 50px;
  color: inherit;
  font-size: 16px;
`;

const StyledInput = styled(Input)`
  ${baseInputStyles}

  border:0px;
  .ant-input-affix-wrapper {
    color: inherit !important;
  }

  &&.ant-input-affix-wrapper {
    margin-top: 5px;
    padding: 20px 11px;
  }
`;

export const SInput: FC<InputProps & { ref?: RefObject<InputRef> }> = props => {
  const { inputCssProperties } = useContext(ThemeContext);

  return (
    <StyledInput
      style={{
        ...inputCssProperties
      }}
      {...props}
    />
  );
};

export const SInputMask = styled(InputMask)`
  ${baseInputStyles}
  border: none;
  outline: none;
`;

export const STextArea = styled(Input.TextArea)`
  background: linear-gradient(0.25turn, #80808057, #002d2f33);
  color: inherit;
  font-size: 16px;
  box-shadow: none;
`;

export const SInputNumber = styled(InputNumber)`
  width: 100%;
  position: relative;

  && input {
    ${baseInputStyles}
  }

  .ant-input-number-group .ant-input-number:hover {
    z-index: 0;
  }

  .ant-input-number-disabled {
    background: none;
  }

  && .ant-input-number-affix-wrapper {
    padding-left: 0;

    input.ant-input-number-input {
      padding-left: 25px;
    }
  }

  && .ant-input-number-affix-wrapper-status-validating {
    padding-left: 0;
  }

  & .ant-input-number-group-addon {
    position: absolute;
    top: 0;
    right: 8px;
    color: inherit;
    height: 58px;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
    border: none;
    color: inherit;
    font-size: 26px;
  }

  .ant-input-number-suffix {
    position: absolute;
    top: 0;
    right: 6px;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
`;

export const SPhoneInput = styled(PhoneInput)`
  display: flex;
  align-items: center;

  ${baseInputStyles}
  padding-left: 0px;

  .special-label {
    display: none;
  }

  .form-control {
    border: none;
    background: transparent;
    width: 100%;
  }

  .flag-dropdown {
    border: 0px;
    border-radius: 50px;
  }

  .selected-flag:hover,
  .selected-flag:focus,
  .selected-flag.open {
    background: linear-gradient(0.25turn, #80808057, #002d2f33);
  }

  .flag-dropdown {
    display: block;
    margin-right: 12px;

    justify-content: center;
    align-items: center;
  }

  .arrow.up {
    border-bottom-color: inherit !important;
  }

  .arrow {
    border-top-color: inherit !important;
  }

  .selected-flag {
    border-radius: 100px;
    width: 58px;
    padding-left: 20px;
    box-sizing: border-box;
  }

  .flag-dropdown.open,
  .flag-dropdown.open .selected-flag {
    background: transparent !important;
  }

  .country-list .country:hover {
    color: rgba(0, 0, 0, 0.85);
    background-color: #cfe6df;
  }

  .phone-input-input {
    background: transparent !important;
    border-color: transparent;
    outline: none;
    margin-left: 12px;
  }

  .country-list {
    border-color: transparent;
    outline: none;
    text-align: left;
  }

  .country-list li {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
  }

  input:focus-visible {
    border: none;
    outline: none;
  }
`;

export const SPasswordInputWrapper = styled.div`
  position: relative;

  && .ant-input-password {
    padding: 0;
    border-radius: 50px;
  }

  input.ant-input {
    ${baseInputStyles}
  }

  .ant-input-suffix {
    margin: 0;
    position: absolute;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  && .anticon {
    color: inherit;
  }

  input.ant-input:autofill ~ .ant-input-suffix {
    background: black;
    color: white;
    padding: 3px;
    border-radius: 30px;
    padding: 3px;
  }

  .ant-input-password + div {
    margin-top: 5px;
  }

  .caps-icon {
    position: absolute;
    color: inherit;
    font-size: 18px;
    top: 20px;
    right: 34px;
  }
`;
