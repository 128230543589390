import DebugSideDrawer from "@components/dashboard/debug-side-drawer/DebugSideDrawer";
import { ErrorInfoModal } from "@components/dashboard/modals/error/ErrorInfoModal";
import { isServer } from "@utils/env-util";
import { ConfigProvider, Spin } from "antd";
import type { Router } from "next/router";
import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";

import { LoadingSpinner } from "@components/layout/loading-indicator/LoadingSpinner/LoadingSpinner";
import ErrorBoundary from "@components/layout/loading-indicator/error-boundary/ErrorBoundary";
import { OnboardingLayout } from "@components/layout/page-container/OnboardingLayout/OnboardingLayout";
import PublicLayout from "@components/layout/page-container/PublicLayout/PublicLayout";
import PublicOnboardingLayout from "@components/layout/page-container/PublicOnboardingLayout/PublicOnboardingLayout";
import { PublicUnderageOnboardingLayout } from "@components/layout/page-container/PublicUnderageOnboardingLayout/PublicUnderageOnboardingLayout";
import SecureLayout from "@components/layout/page-container/SecureLayout/SecureLayout";
import { UnderageOnboardingLayout } from "@components/layout/page-container/UnderageOnboardingLayout/UnderageOnboardingLayout";
import { useCleanupSession } from "@hooks/useCleanupSession";
import NoLayout from "@src/components/layout/page-container/SecureLayout/NoLayout";
import { useRefreshSession } from "@src/hooks/useRefreshSession";
import type { ILayout, PropsWithChildren } from "@src/interfaces/jsx.interface";

import TestUserAuthenticationForm from "@src/page-components/public/server/TestUserAuthenticationForm";
import type { ThemeContextType } from "@src/theme/provider/ThemeProvider";
import ThemeProvider from "@src/theme/provider/ThemeProvider";
import "antd/dist/antd.variable.min.css";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";
import { DISABLE_SSR_TRANSITION } from "./_document";

Spin.setDefaultIndicator(<LoadingSpinner />);

export interface ICustomApp {
  Component: React.FunctionComponent;
  pageProps: {
    USE_AUTH: string;
    AUTH_USERNAME: string;
    AUTH_PASSWORD: string;
    TESTUSER_AUTH: boolean;
    layout?: ILayout;
    name?: string;
    hideDepotNavigation?: boolean;
  };
  routeProps: Router;
}

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: defaultTheme,
  cssProperties: {},
  appModeTheme: defaultTheme.darkMode!,
  inputCssProperties: {}
});

function CustomApp(props: ICustomApp) {
  const avoidCssAnimationFlashing = () => {
    if (!isServer()) {
      const disableTransitionDom = document.getElementById(
        DISABLE_SSR_TRANSITION
      );

      if (disableTransitionDom) disableTransitionDom.remove();
    }
  };

  // eslint-disable-next-line global-require
  require("@styles/global.less");

  useEffect(() => {
    avoidCssAnimationFlashing();
  }, []);

  let layoutDom = null;

  const { Component, routeProps, pageProps } = props;

  if (pageProps?.layout === "secure") {
    layoutDom = (
      <SecureLayout
        mainComp={Component}
        routeProps={routeProps}
        pageProps={pageProps}
        hideDepotNavigation={pageProps.hideDepotNavigation}
      />
    );
  }

  if (pageProps?.layout === "none") {
    layoutDom = (
      <NoLayout
        mainComp={Component}
        routeProps={routeProps}
        pageProps={pageProps}
        hideDepotNavigation={pageProps.hideDepotNavigation}
      />
    );
  }

  if (pageProps?.layout === "public") {
    layoutDom = (
      <PublicLayout
        mainComp={Component}
        routeProps={routeProps}
        pageProps={pageProps}
      />
    );
  }

  if (pageProps?.layout === "underage_onboarding") {
    layoutDom = (
      <UnderageOnboardingLayout
        mainComp={Component}
        routeProps={routeProps}
        pageProps={pageProps}
      />
    );
  }

  if (pageProps?.layout === "public_onboarding") {
    layoutDom = (
      <PublicOnboardingLayout
        mainComp={Component}
        routeProps={routeProps}
        pageProps={pageProps}
      />
    );
  }

  if (pageProps?.layout === "onboarding") {
    layoutDom = (
      <OnboardingLayout
        mainComp={Component}
        routeProps={routeProps}
        pageProps={pageProps}
      />
    );
  }

  if (pageProps?.layout === "public_underage_onboarding") {
    layoutDom = (
      <PublicUnderageOnboardingLayout
        mainComp={Component}
        routeProps={routeProps}
        pageProps={pageProps}
      />
    );
  }

  if (pageProps.USE_AUTH === "true" && pageProps.TESTUSER_AUTH === false) {
    return (
      <TestUserAuthenticationForm
        username={pageProps.AUTH_USERNAME}
        password={pageProps.AUTH_PASSWORD}
      />
    );
  }

  const noErrorBoundary = false;

  return (
    <ConfigProvider>
      <HelmetProvider>
        <RecoilRoot>
          <SessionControl>
            <ThemeProvider>
              {noErrorBoundary ? (
                layoutDom
              ) : (
                <ErrorBoundary>{layoutDom}</ErrorBoundary>
              )}
              <ErrorInfoModal />
            </ThemeProvider>
            <DebugSideDrawer />
          </SessionControl>
        </RecoilRoot>
      </HelmetProvider>
    </ConfigProvider>
  );
}

export default CustomApp;

const SessionControl: React.FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const [isSessionActive, clearSessionHandler] = useCleanupSession();

  useRefreshSession();

  return (
    <div
      style={{ height: "100%" }}
      onMouseMove={isSessionActive ? clearSessionHandler : undefined}
    >
      {children}
    </div>
  );
};

// Redirect to root if page not found
/* CustomApp.getStaticProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: "/" });
    appContext.ctx.res.end();
    return undefined;
  }

  return { ...appProps };
};
 */
