import React from "react";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";

import { Footer } from ".";

interface IProps extends ICompBaseProps {}

const publicLinks = [
  {
    id: 1,
    name: "Startseite",
    route: ""
  },
  {
    id: 2,
    name: "Über uns",
    route: "ueber-uns"
  },
  {
    id: 3,
    name: "Kontakt",
    route: "kontakt",
    premiumRoute: "premium/kontakt"
  },
  {
    id: 4,
    name: "Nutzungsbedingungen",
    route: "nutzungsbedingungen"
  },
  {
    id: 5,
    name: "Impressum",
    route: "impressum"
  },
  {
    id: 6,
    name: "Rechtliches",
    route: "rechtliches"
  },
  {
    id: 7,
    name: "Dokumente",
    route: "dokumente"
  },
  {
    id: 8,
    name: "Datenschutz",
    route: "datenschutz"
  },
  {
    id: 9,
    name: "Risikohinweis",
    route: "risikohinweis",
    premiumRoute: "premium/risikohinweis"
  }
  // {
  //   id: 10,
  //   name: 'Karriere',
  //   route: 'karriere',
  // },
];

export const FooterPublic: React.FC<IProps> = props => {
  return <Footer links={publicLinks} {...props} />;
};
