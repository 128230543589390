// eslint-disable-next-line import/no-extraneous-dependencies
import { animated, useSpring } from "@react-spring/web";
import useIsMobile from "@src/theme/hook/useIsMobile";
import Image from "next/image";
import { useRouter } from "next/router";
import staticGoalMessages from "@src/components/games/staticGoalMessages";
import { useEffect, useState } from "react";
import type { AppTheme } from "smavesto.core";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";
import styles from "../layout/page-container/layout/basicPageLayoutStyles.module.less";
import staticSoccerLogoAchievements from "./staticSoccerLogoAchievements";

interface SoccerLogoProps {
  redirectToSmavestoDeOnLogoClick?: boolean;
  isDashboard?: boolean;
  theme: AppTheme;
  animateIntro?: boolean;
}

function calculateDuration(points: number) {
  // Berechnung der Grunddauer
  const baseDuration: number = 1000;

  // Maximal mögliche Punkte, die die Dauer beeinflussen können
  const maxPoints: number = 30;

  // Faktor zur Anpassung der Dauer
  const scaleFactor: number = 30; // kann angepasst werden, um die Schwierigkeit zu steuern

  // Prozentuale Anpassung basierend auf den Punkten
  const percentageAdjustment: number = points / maxPoints;

  // Berechnung der neuen Dauer
  let adjustedDuration = baseDuration - percentageAdjustment * scaleFactor;

  // Begrenzen der Dauer auf einen sinnvollen Bereich
  adjustedDuration = Math.max(500, adjustedDuration); // Minimum von 500 ms
  adjustedDuration = Math.min(2000, adjustedDuration); // Maximum von 2000 ms

  return adjustedDuration;
}

function calculateAnimationSpeed(speed: number) {
  const maxSpeed = 200;
  const minSpeed = 0;
  const maxMs = 1000; // maximale ms für sehr langsame Animation
  const minMs = 100; // minimale ms für sehr schnelle Animation

  // Normalisiere die Geschwindigkeit zwischen 0 und 1
  const normalizedSpeed = (speed - minSpeed) / (maxSpeed - minSpeed);

  // Interpolation zwischen maxMs und minMs
  const animationSpeedMs = maxMs - normalizedSpeed * (maxMs - minMs);
  return animationSpeedMs;
}

function getRandomElementFromArray(arr: any[]) {
  if (arr.length === 0) {
    throw new Error("Das Array darf nicht leer sein.");
  }
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}
const energyGradients = [
  "linear-gradient(180deg, #F6D599 -117.86%, #D60A56 160%)", // für 0 Combos
  "linear-gradient(180deg, #FFD700 -117.86%, #FF8C00 160%)", // für 1 Combo
  "linear-gradient(180deg, #87CEEB -117.86%, #4682B4 160%)", // für 2 Combos
  "linear-gradient(180deg, #ADFF2F -117.86%, #32CD32 160%)", // für 3 Combos
  "linear-gradient(180deg, #FF69B4 -117.86%, #FF1493 160%)", // für 4 Combos
  "linear-gradient(180deg, #BA55D3 -117.86%, #8A2BE2 160%)", // für 5 Combos
  "linear-gradient(180deg, #00CED1 -117.86%, #20B2AA 160%)", // für 6 Combos
  "linear-gradient(180deg, #FF6347 -117.86%, #FF4500 160%)", // für 7 Combos
  "linear-gradient(180deg, #00FF00 -117.86%, #32CD32 160%)", // für 8 Combos
  "linear-gradient(180deg, #FFD700 -117.86%, #FF8C00 160%)" // für 9 Combos
];

const SoccerLogo: React.FunctionComponent<SoccerLogoProps> = ({
  redirectToSmavestoDeOnLogoClick,
  isDashboard,
  theme,
  animateIntro
}) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const easyspeedOptions = [3000, 2500, 2000];

  const speedOptions = [2000, 2000, 3000, 1000, 2000, 1000];
  const [player, setPlayer] = useState<{
    points: number;
    fails: number;
    combo: number;
  }>({
    points: 0,
    fails: 0,
    combo: 0
  });

  const [goalMessage, setGoalMessage] = useState<string>(
    getRandomElementFromArray(staticGoalMessages)
  );

  const [hasPlayed, setHasPlayed] = useState<boolean>(false);
  const [lastGamePoints, setLastGamePoints] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(0);
  const [aiStrategy, setAiStrategy] = useState<"left" | "right">("right");

  const [gameState, setGameState] = useState<
    "intro" | "ingame" | "shoot" | "check" | "goal" | "manual"
  >("intro");

  const gameIsPaused = gameState === "intro" || gameState === "manual";

  const getMoveArea = () => {
    if (player.points < 3) return 200;
    if (player.points < 10) return 100;
    if (player.points < 20) return 80;
    if (player.points < 30) return 60;
    return 30;
  };

  const { scaleAnimation, rotateAnimation } = useSpring({
    from: {
      scaleAnimation: 0.5,
      rotateAnimation: 360
    },
    to: {
      scaleAnimation: 3,
      rotateAnimation: -360
    },
    config: {
      duration: 2000
    },
    pause: gameState !== "goal",
    loop: true
  });

  const { opponentX } = useSpring({
    from: {
      opponentX:
        aiStrategy === "left" ? 110 - getMoveArea() : 140 + getMoveArea()
    },
    to: {
      opponentX:
        aiStrategy !== "left" ? 110 - getMoveArea() : 140 + getMoveArea()
    },
    loop: true,
    config: {
      duration: speed
    },
    pause: gameIsPaused || gameState === "check",
    onRest: () => {
      setAiStrategy(aiStrategy === "left" ? "right" : "left");
      setBallPosition({ ...ballPosition, rotate: ballPosition.rotate * -1 });
      setSpeed(
        getRandomElementFromArray(
          player.points < 10 ? easyspeedOptions : speedOptions
        )
      );
    }
  });

  const { measureWidth } = useSpring({
    from: {
      measureWidth: player.combo < 10 ? player.combo * 20 : 220
    },
    to: {
      measureWidth: 220
    },
    loop: true,
    config: {
      duration: calculateDuration(player.points)
    },
    pause: gameIsPaused || gameState !== "ingame"
  });

  const resetGame = () => {
    setSpeed(0);
    setBallPosition({
      x: 420,
      y: 100,
      width: 50,
      height: 50,
      rotate: 0,
      duration: 1
    });
    setGameState("intro");
    setLastGamePoints(player.points);
    setHasPlayed(true);
    setPlayer({
      points: 0,
      fails: 0,
      combo: 0
    });
  };

  const [ballPosition, setBallPosition] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
    rotate: number;
    duration: number;
  }>({
    x: 420,
    y: animateIntro ? -100 : 100,
    width: 50,
    height: 50,
    rotate: 0,
    duration: 1
  });

  useEffect(() => {
    if (player.points >= 30) {
      setGameState("goal");
      setGoalMessage("🏆 Durchgespielt! 🏆");
    }
    if (player.fails === 5) {
      resetGame();
    }
  }, [player.fails, player.points]);

  useEffect(() => {
    if (animateIntro && gameState === "intro") {
      setTimeout(() => {
        setBallPosition({ ...ballPosition, y: 100, rotate: 360 });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (gameState === "check") {
      setBallPosition({
        x: 450,
        y: 200,
        width: 100,
        height: 100,
        rotate: 180,
        duration: 1
      });

      if (
        opponentX.get() + 95 <= ballPosition.x ||
        opponentX.get() >= ballPosition.x + 30
      ) {
        setPlayer({
          ...player,
          points: player.points + 1,
          combo: player.combo + 1
        });
        setGoalMessage(getRandomElementFromArray(staticGoalMessages));
        setGameState("goal");

        if (player.points < 29) setTimeout(() => setGameState("ingame"), 2000);
      } else {
        setGameState("ingame");
        setPlayer({
          ...player,
          fails: player.fails + 1,
          combo: 0
        });
      }
    }
  }, [gameState]);

  const onBallClick = () => {
    if (gameState === "intro") {
      setGameState("ingame");
      setBallPosition({
        x: 450,
        y: 200,
        width: 100,
        height: 100,
        rotate: 180,
        duration: 1
      });
    }

    if (gameState === "ingame") {
      const animTimeout = calculateAnimationSpeed(measureWidth.get());
      setGameState("shoot");
      setBallPosition({
        x: 140,
        y: 100,
        width: 30,
        height: 30,
        rotate: -180,
        duration: animTimeout * 0.01
      });

      setTimeout(() => {
        setGameState("check");
      }, animTimeout * 8);
    }
  };

  const { x, y, rotate, height, width } = ballPosition;

  return (
    <div
      className={styles["wrap-main-logo"]}
      style={{
        width: "100%",
        minWidth: 600,
        maxWidth: isMobile ? 250 : 600,
        position: "relative",
        overflow: gameState === "goal" ? "hidden" : "unset"
      }}
    >
      <Image
        className={styles["img-logo"]}
        quality={100}
        onClick={
          !gameIsPaused
            ? () =>
                redirectToSmavestoDeOnLogoClick
                  ? router.push("https://smavesto.de")
                  : router.push("/")
            : undefined
        }
        style={{
          ...theme.logoStyles,
          opacity: gameState === "intro" ? 1 : 0.4,
          filter: gameState === "intro" ? "none" : "blur(5px)",
          transition: "all 0.3s"
        }}
        alt="SMAVESTO - Einfach anlegen"
        src={
          (isDashboard && theme.assets.dashboardLogoImage
            ? theme.assets.dashboardLogoImage
            : theme.assets.logoImage) || defaultTheme.assets.logoImage
        }
        layout="intrinsic"
        objectFit="contain"
        objectPosition="center center"
        priority
        height={isDashboard ? 320 : 290}
        width={600}
      />

      {gameState === "intro" && hasPlayed === true ? (
        <div
          style={{
            position: "absolute",
            left: 15,
            bottom: 15,
            padding: 10,
            width: "fit-content",
            textAlign: "center",
            color: "#002D2F",
            background: "white",
            borderRadius: 10
          }}
        >
          <span>
            🎮 Vielen Dank fürs Mitspielen! <br />
            <b>
              Ergebnis: {lastGamePoints} Tore (
              {
                staticSoccerLogoAchievements.find(
                  a => a.points === lastGamePoints
                )?.name
              }{" "}
              {
                staticSoccerLogoAchievements.find(
                  a => a.points === lastGamePoints
                )?.emoji
              }
              )
            </b>
          </span>
        </div>
      ) : null}

      {gameState !== "intro" && player.points > 0 && (
        <div
          style={{
            position: "absolute",
            left: 15,
            bottom: 15,
            padding: 5,
            width: 190,
            textAlign: "center",
            color: "#002D2F",
            background: "white",
            borderRadius: 30
          }}
        >
          <span>
            {
              staticSoccerLogoAchievements.find(a => a.points === player.points)
                ?.emoji
            }{" "}
            {
              staticSoccerLogoAchievements.find(a => a.points === player.points)
                ?.name
            }
          </span>
        </div>
      )}

      {gameState !== "intro" && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 10,
            width: 200,
            textAlign: "right",
            color: "white"
          }}
        >
          <div
            style={{
              borderRadius: 10,
              border: "3px solid white",
              padding: 2,
              overflow: "hidden",
              boxSizing: "border-box"
            }}
          >
            <animated.div
              style={{
                width: measureWidth,
                height: "100%",
                borderRadius: 3,
                background:
                  energyGradients[
                    player.combo < energyGradients.length ? player.combo : 9
                  ]
              }}
            >
              &nbsp;
            </animated.div>
          </div>
          <span>GESCHWINDIGKEIT</span>
          <br />
          <span
            style={{
              color: "white"
            }}
          >
            ⚽️ {5 - player.fails} / 5
          </span>
          <br />
          {player.combo > 0 && (
            <span
              style={{
                color: "white"
              }}
            >
              {player.combo > 0 ? `KOMBI X ${player.combo}` : ""}
            </span>
          )}
        </div>
      )}

      {gameState !== "intro" && (
        <animated.div
          style={{
            position: "absolute",
            transition: `all ${ballPosition.duration}s`,
            top: 50,
            left: 0,
            width: "100%",
            borderBottom: "1px solid white",
            height: 171 / 1.5,
            transformOrigin: "center"
          }}
        >
          <img
            alt="goal"
            width={286 / 1.5}
            style={{ marginLeft: 100 }}
            height="100%"
            src="/assets/images/soccer-game/goal.png"
          />
        </animated.div>
      )}

      {gameState !== "intro" && (
        <animated.div
          style={{
            position: "absolute",
            top: 85,
            left: opponentX,
            width: 100,
            height: 100,
            transformOrigin: "center"
          }}
        >
          <img
            alt="smavesto"
            width="100%"
            height="100%"
            src={
              gameState === "shoot" || gameState === "check"
                ? "/assets/images/soccer-game/smavesto-hands.png"
                : "/assets/images/soccer-game/smavesto.png"
            }
          />
        </animated.div>
      )}

      {gameState === "ingame" && (
        <animated.div
          style={{
            position: "absolute",
            transition: `all ${ballPosition.duration}s`,
            top: 180,
            left: 260,
            width: 578 / 3,
            height: 233 / 3,
            transformOrigin: "center"
          }}
        >
          <img
            alt="path"
            width="100%"
            height="100%"
            src="/assets/images/soccer-game/path.png"
          />
        </animated.div>
      )}

      <animated.div
        onClick={onBallClick}
        style={{
          position: "absolute",
          transition: `all ${ballPosition.duration}s`,
          top: y,
          left: x,
          width,
          height,
          rotate: rotateAnimation,
          transformOrigin: "center",
          cursor: "pointer"
        }}
      >
        <img
          alt="ball"
          width="100%"
          height="100%"
          src="/assets/images/soccer-game/ball.png"
        />
      </animated.div>

      <animated.div
        onClick={onBallClick}
        style={{
          position: "absolute",
          transition: `all ${ballPosition.duration}s`,
          top: y,
          left: x,
          width,
          height,
          rotate,
          transformOrigin: "center",
          cursor: "pointer"
        }}
      >
        <img
          alt="ball"
          width="100%"
          height="100%"
          src="/assets/images/soccer-game/ball.png"
        />
      </animated.div>

      {gameState === "ingame" && (
        <>
          <animated.div
            onClick={() => resetGame()}
            style={{
              position: "absolute",
              background: "var(--ant-primary-color)",
              color: "white",
              transition: `all ${ballPosition.duration}s`,
              top: 10,
              padding: "5px 10px",
              borderRadius: 30,
              left: 10,
              width: 120,
              transformOrigin: "center",
              cursor: "pointer",
              textAlign: "center"
            }}
          >
            Spiel beenden
          </animated.div>
          <animated.div
            onClick={() => setGameState("manual")}
            style={{
              position: "absolute",
              background: "var(--ant-primary-color)",
              color: "white",
              transition: `all ${ballPosition.duration}s`,
              top: 10,
              padding: "5px 10px",
              borderRadius: 30,
              textAlign: "center",
              left: 270,
              width: 120,
              transformOrigin: "center",
              cursor: "pointer"
            }}
          >
            Anleitung
          </animated.div>
          <animated.div
            onClick={() => router.push("mailto:produkttester@smavesto.de")}
            style={{
              position: "absolute",
              background: "var(--ant-primary-color)",
              color: "white",
              transition: `all ${ballPosition.duration}s`,
              top: 10,
              padding: "5px 10px",
              borderRadius: 30,
              textAlign: "center",
              left: 140,
              width: 120,
              transformOrigin: "center",
              cursor: "pointer"
            }}
          >
            Feedback
          </animated.div>
        </>
      )}

      {gameState === "goal" && (
        <>
          <animated.div
            style={{
              position: "absolute",
              top: 30,
              left: 160,
              transition: "all 0.3s",
              scale: scaleAnimation,
              height: 500 / 2,
              rotate: rotateAnimation,
              width: 650 / 2,
              transformOrigin: "center"
            }}
          >
            <img
              alt="path"
              width="100%"
              height="100%"
              src="/assets/images/soccer-game/confetti.png"
            />
          </animated.div>{" "}
          <animated.div
            style={{
              fontSize: 32,
              fontWeight: "bold",
              color: "#D60A56",
              textShadow:
                " -3px 0 black, 0 3px black, 3px 0 black, 0 -3px black",
              position: "absolute",
              left: 0,
              width: "100%",
              height: "100%",
              textAlign: "center",
              justifyContent: "center",
              top: 0,
              display: "flex",
              alignItems: "center",
              scale: scaleAnimation
            }}
          >
            {goalMessage}
          </animated.div>
        </>
      )}

      {gameState === "manual" && (
        <animated.div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            transition: "all 0.3s",
            borderRadius: 10,
            padding: 10,
            height: "fit-content",
            overflowY: "auto",
            overflowX: "hidden",
            background: "white",
            width: "100%",
            transformOrigin: "center"
          }}
        >
          <b>Für die Zeit bis zum Anpfiff: Das Smavesto-Elfmeterschießen</b>
          <br />
          <br />
          Achten Sie auf die Geschwindigkeitsanzeige oben rechts. Ist sie
          komplett gefüllt, wird der Ball umso schneller das Tor erreichen. Bis
          zu 5 Treffer dürfen daneben gehen, danach ist das Spiel vorbei.
          <br />
          <br />
          Ab einem erfolgreichen Treffer gilt der nächste als Kombination. Mit
          jedem weiteren Treffer steigt die minimale Geschwindigkeit, die der
          Ball beim Schuss erreicht. Zusätzlich ändert sich die Farbe der
          Geschwindigkeits-Leiste. <br />
          <br />
          Das Spiel kann jederzeit mit einem Klick auf den Button „Spiel
          beenden“ beendet werden. <br />
          Viel Spaß!
          <button
            type="button"
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              borderRadius: 30,
              width: 30,
              height: 30
            }}
            onClick={() => setGameState("ingame")}
          >
            X
          </button>
        </animated.div>
      )}
    </div>
  );
};

export default SoccerLogo;
