import { CheckOutlined } from "@ant-design/icons";
import cx from "classnames";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";

import { receivePreContractualInfoState } from "@states/summary.state";
import type OnboardingChapter from "smavesto.core/lib/types/dto/onboarding/OnboardingChapter";

import { ThemeContext } from "@pages/_app";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import Image from "next/image";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import Hamburger from "../HamburgerMenu";
import { StyledLink, StyledMenu, StyledNav } from "./MobileMenu.styled";
import styles from "./styles.module.less";

type OnboardingMobileMenuProps = {
  completedChapters: OnboardingChapter[];
  disabledChapters: OnboardingChapter[];
  hiddenChapters: OnboardingChapter[];
  publicRoute?: boolean;
  disabled?: boolean;
};

const OnboardingMobileMenu: React.FunctionComponent<
  OnboardingMobileMenuProps
> = ({
  completedChapters,
  disabledChapters,
  hiddenChapters,
  publicRoute,
  disabled
}) => {
  const { push, route, query } = useRouter();

  const { theme } = useContext(ThemeContext);

  const [, receivePreContractualInfo] = useAsyncSelector(
    receivePreContractualInfoState
  );

  const items = [
    {
      to: {
        pathname: publicRoute ? "/registration" : "/onboarding/investment",
        query
      },
      text: "Anlagewunsch",
      complete: completedChapters?.includes("ANLAGEWUNSCH"),
      disabled: disabledChapters?.includes("ANLAGEWUNSCH"),
      hidden: hiddenChapters?.includes("ANLAGEWUNSCH") || false,
      order: "1"
    },
    {
      to: { pathname: "/registration/submit", query },
      text: "Zugangsdaten",
      complete: completedChapters?.includes("ZUGANGSDATEN"),
      disabled: disabledChapters?.includes("ZUGANGSDATEN"),
      hidden: hiddenChapters?.includes("ZUGANGSDATEN") || false,
      order: "2"
    },
    {
      to: { pathname: "/onboarding/personal-data", query },
      text: "Persönliche Angaben",
      complete: completedChapters?.includes("PERSOENLICHE_ANGABEN"),
      disabled: !completedChapters?.includes("ZUGANGSDATEN"),
      hidden: hiddenChapters?.includes("PERSOENLICHE_ANGABEN") || false,
      order: "3"
    },
    {
      to: { pathname: "/onboarding/financial-situation", query },
      text: "Finanzielle Verhältnisse",
      complete: completedChapters?.includes("FINANZIELLE_LAGE"),
      disabled: !completedChapters?.includes("PERSOENLICHE_ANGABEN"),
      hidden: hiddenChapters?.includes("FINANZIELLE_LAGE") || false,
      order: "4"
    },
    {
      to: { pathname: "/onboarding/knowledge", query },
      text: "Kenntnisse",
      complete: completedChapters?.includes("KENNTNISSE"),
      disabled: !completedChapters?.includes("FINANZIELLE_LAGE"),
      hidden: hiddenChapters?.includes("KENNTNISSE") || false,
      order: "5"
    },
    {
      to: { pathname: "/onboarding/bank-details", query },
      text: "Bankverbindung",
      complete: completedChapters?.includes("BANKVERBINDUNG"),
      disabled: !completedChapters?.includes("KENNTNISSE"),
      hidden: hiddenChapters?.includes("BANKVERBINDUNG") || false,
      order: "6"
    },
    {
      to: { pathname: "/onboarding/summary", query },
      text: "Zusammenfassung",
      complete:
        completedChapters?.includes("BANKVERBINDUNG") &&
        !!receivePreContractualInfo,
      disabled: !completedChapters?.includes("BANKVERBINDUNG"),
      hidden: hiddenChapters?.includes("SUMMARY") || false,
      order: "7"
    },
    {
      to: { pathname: "/onboarding/summary2", query },
      text: "Dokumente und Legitimation",
      complete: completedChapters?.includes("SUMMARY2"),
      disabled:
        !completedChapters?.includes("BANKVERBINDUNG") ||
        !receivePreContractualInfo,
      hidden: hiddenChapters?.includes("SUMMARY2") || false,
      order: "8"
    }
  ];

  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  useOnClickOutside(node, () => setOpen(false));

  const handleClick = (path: any) => {
    push(path.to);
    setOpen(false);
  };

  return (
    <div ref={node}>
      <StyledMenu open={open}>
        <div className="wrap-main-logo">
          {theme.assets.logoImage && (
            <Image
              style={{
                objectFit: "contain",
                objectPosition: "top"
              }}
              width={200}
              height={200}
              alt="SMAVESTO - Einfach anlegen"
              src={theme.assets.logoImage}
            />
          )}
        </div>
        <StyledNav>
          {items.map(nav => (
            <StyledLink
              key={nav.order}
              onClick={() => !disabled && !nav.disabled && handleClick(nav)}
              style={{
                boxShadow:
                  route === nav.to.pathname
                    ? `0 0 4px 2px ${theme.palette.primary}`
                    : "none",
                borderRadius: "20px 0px 0px 20px"
              }}
              className={cx({
                [styles.exact]: nav.complete,
                [styles.disabled]: !disabled && nav.disabled
              })}
            >
              <span style={{ marginRight: 20 }}>
                {nav.complete ? <CheckOutlined /> : nav.order}
              </span>

              <span style={{ marginLeft: 10 }}>{nav.text}</span>
            </StyledLink>
          ))}
        </StyledNav>
      </StyledMenu>

      <Hamburger open={open} setOpen={setOpen} />
    </div>
  );
};

export default OnboardingMobileMenu;
