import { useContext } from "react";
import { ThemeContext } from "@pages/_app";

interface CustomModalContentProps {
  children: React.ReactNode;
}

const CustomModalContent: React.FunctionComponent<CustomModalContentProps> = ({
  children
}) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  return (
    <div
      className="ant-modal-content"
      style={{
        background:
          appModeTheme?.modalBackgroundColor ||
          theme.palette.secondLayerBackgroundColor,
        padding: "64px 48px 0px 32px",
        color: appModeTheme?.secondLayerTextColor,
        borderRadius: 30
      }}
    >
      {children}
    </div>
  );
};

export default CustomModalContent;
