const staticSoccerLogoAchievements = [
  {
    points: 0,
    name: "Guter Start",
    emoji: "⚽️"
  },
  {
    points: 1,
    name: "Erstes Tor",
    emoji: "⚽️"
  },
  {
    points: 2,
    name: "Doppelschlag",
    emoji: "⚽️⚽️"
  },
  {
    points: 3,
    name: "Kreisliga",
    emoji: "🥉"
  },
  {
    points: 4,
    name: "Viererpack",
    emoji: "⚽️⚽️⚽️⚽️"
  },
  {
    points: 5,
    name: "Fünf Treffer",
    emoji: "🥈"
  },
  {
    points: 6,
    name: "Profi",
    emoji: "6️⃣"
  },
  {
    points: 7,
    name: "Sieben auf einen Streich",
    emoji: "🏅"
  },
  {
    points: 8,
    name: "Torfestival",
    emoji: "🎉"
  },
  {
    points: 9,
    name: "Neunerpack",
    emoji: "⚽️⚽️⚽️⚽️⚽️⚽️⚽️⚽️⚽️"
  },
  {
    points: 10,
    name: "Bundesliga-Spieler",
    emoji: "🏆"
  },
  {
    points: 11,
    name: "Elfmeterschütze",
    emoji: "1️⃣1️⃣"
  },
  {
    points: 12,
    name: "Dutzendmeister",
    emoji: "1️⃣2️⃣"
  },
  {
    points: 13,
    name: "Glückszahl",
    emoji: "1️⃣3️⃣"
  },
  {
    points: 14,
    name: "Torfabrik",
    emoji: "🏭"
  },
  {
    points: 15,
    name: "Torgarant",
    emoji: "🔒"
  },
  {
    points: 16,
    name: "Sechzehn Treffer",
    emoji: "1️⃣6️⃣"
  },
  {
    points: 17,
    name: "Torheld",
    emoji: "🦸"
  },
  {
    points: 18,
    name: "Achtzehnter",
    emoji: "1️⃣8️⃣"
  },
  {
    points: 19,
    name: "Torwunder",
    emoji: "🌟"
  },
  {
    points: 20,
    name: "Zwanzig Tore",
    emoji: "2️⃣0️⃣"
  },
  {
    points: 21,
    name: "Tormaschine",
    emoji: "🤖"
  },
  {
    points: 22,
    name: "Torsturm",
    emoji: "🌪️"
  },
  {
    points: 23,
    name: "Dreiundzwanzig Treffer",
    emoji: "2️⃣3️⃣"
  },
  {
    points: 24,
    name: "Torjäger",
    emoji: "🏹"
  },
  {
    points: 25,
    name: "Torgott",
    emoji: "🙏"
  },
  {
    points: 26,
    name: "Sechsundzwanzig Tore",
    emoji: "2️⃣6️⃣"
  },
  {
    points: 27,
    name: "Dreiundzwanzig plus vier",
    emoji: "2️⃣7️⃣"
  },
  {
    points: 28,
    name: "Torflut",
    emoji: "🌊"
  },
  {
    points: 29,
    name: "Neunundzwanzig Treffer",
    emoji: "2️⃣9️⃣"
  },
  {
    points: 30,
    name: "Durchgespielt",
    emoji: "🏅"
  }
];

export default staticSoccerLogoAchievements;
