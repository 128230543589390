const staticGoalMessages = [
  "Tooor!",
  "Juuuubel!",
  "Smart angelegt!",
  "Zack!",
  "Abgezogen!",
  "Knaller!",
  "Eingedengelt!",
  "Volltreffer!",
  "Toll!",
  "Investiert!",
  "Sauber!",
  "Macht den Sack zu!",
  "Kopfballtor!",
  "Schuss in die Maschen!",
  "Flachschuss!",
  "Hammerhart!",
  "Versenkt!",
  "Hackentrick!",
  "Ferse rein!",
  "Geschenkt!",
  "Spiel gedreht!",
  "Auf geht's!"
];

export default staticGoalMessages;
