import type WorkflowStep from "../engine/models/WorkflowStep";

const onboardingStepsRegularDepot = (
  publicRoute: boolean,
  isAdditionalDepot: boolean
): WorkflowStep[] => [
  {
    order: 1,
    to: publicRoute
      ? `/registration${isAdditionalDepot ? "?depotType=additional" : ""}`
      : "/onboarding/investment",
    text: "Anlagewunsch",
    chapterName: "ANLAGEWUNSCH"
  },
  {
    order: 2,
    to: "/registration/submit",
    text: "Zugangsdaten",
    chapterName: "ZUGANGSDATEN"
  },
  {
    order: 3,
    to: "/onboarding/personal-data",
    text: "Persönliche Angaben",
    chapterName: "PERSOENLICHE_ANGABEN"
  },
  {
    order: 4,
    to: "/onboarding/financial-situation",
    text: "Finanzielle Verhältnisse",
    chapterName: "FINANZIELLE_LAGE"
  },
  {
    order: 5,
    to: "/onboarding/knowledge",
    text: "Kenntnisse",
    chapterName: "KENNTNISSE"
  },
  {
    order: 6,
    to: "/onboarding/bank-details",
    text: "Bankverbindung",
    chapterName: "BANKVERBINDUNG"
  },
  {
    order: 7,
    to: "/onboarding/summary",
    text: "Zusammenfassung",
    chapterName: "SUMMARY"
  },
  {
    order: 8,
    to: "/onboarding/summary2",
    text: "Dokumente und Legitimation",
    chapterName: "SUMMARY2"
  }
];

export default onboardingStepsRegularDepot;
