import { atom, selectorFamily } from "recoil";

import type InvestmentSettingsDto from "smavesto.core/lib/types/dto/user/InvestmentSettingsDto";
import { userUriState } from "@states/auth.state";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import { getRequestState, useRefreshState } from "./request.state";

export const localInvestmentSettingsState = atom<
  InvestmentSettingsDto | undefined
>({
  key: "localInvestmentSettings",
  default: undefined
});

export const investmentSettingsState = selectorFamily({
  key: "investmentSettings",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      if (!userUri) return undefined;

      get(getRequestState("investmentSettings", userUri));

      return get(investmentSettingsByUserUriState(userUri));
    }
});

export const investmentSettingsByUserUriState = selectorFamily({
  key: "investmentSettingsByUserUri",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("investmentSettings", userUri));

      return successOrUndefined(
        await smavestoCore.services.user.getInvestmentSettings(userUri)
      );
    }
});

export const useRefreshInvestmentSettings = (type: StateEnvironmentUser) =>
  useRefreshState("investmentSettings", type);
