// import { CgSpinner as Spinner } from 'react-icons/cg';
import { LoadingOutlined } from "@ant-design/icons";
import type { SpinProps } from "antd/lib/spin";
import cx from "classnames";
import React from "react";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";

import styles from "./styles.module.less";

interface IProps extends ICompBaseProps, SpinProps {
  fullscreen?: boolean;
  lazy?: boolean;
}

export const LoadingSpinner: React.FC<IProps> = ({
  alwaysDarkMode,
  fullscreen,
  lazy,
  className,
  style
}) => {
  LoadingSpinner.displayName = "LoadingSpinner";

  return (
    <div
      className={cx(
        styles["comp-wrapper"],
        {
          [styles["comp-wrapper--alwaysDarkMode"]]: alwaysDarkMode,
          [styles["comp-wrapper--fullscreen"]]: !!fullscreen,
          [styles["comp-wrapper--lazy"]]: !!lazy
        },
        className,
        `g-comp--${LoadingSpinner.displayName}`
      )}
      style={style}
    >
      <LoadingOutlined style={{ fontSize: 32 }} spin />
    </div>
  );
};
