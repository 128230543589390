import { atom, atomFamily, selector, selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import getWorkflowStatus from "smavesto.core/lib/business-logic/workflow/getWorkflowStatus";
import type SavingAmountDto from "smavesto.core/lib/types/dto/depot/SavingAmountDto";
import type CustodyDto from "smavesto.core/lib/types/dto/user/Custody";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { isDepotNotAccessible } from "smavesto.core/lib/utils/typeguards/isDepotNotAccessible";
import {
  currentDepotIsUnderageState,
  depotForUserUriState,
  depotsState
} from "./depot.state";
import { getCategories } from "./inbox.state.helpers";
import { transactionsFilterState } from "./investment-plan.state";
import { loggedInUserOnboardingState } from "./onboarding.state";
import { getRequestState, useRefreshState } from "./request.state";
/* These states contain data of the currently selected depot */

export const currentDepotIDState = atom({
  key: "currentDepotID",
  default: selector({
    key: "currentDepotID/Default",

    get: ({ get }) => {
      const depots = get(depotsState);

      const ownDepot = depots.find(
        d => d.customerDepotRelationshipType === "Owner"
      );

      return ownDepot?.id;
    }
  })
});

export const currentDepotState = selector({
  key: "currentDepot",
  get: ({ get }) => {
    const id = get(currentDepotIDState);

    get(getRequestState("depot", id));

    const depots = get(depotsState);

    // If there is no depot selected, choose the first one
    const currentDepot = id ? depots.find(d => d.id === id) : depots?.[0];

    return currentDepot;
  }
});

export const currentDepotIsAccessibleState = selector({
  key: "currentDepotIsAccessibleState",
  get: ({ get }) => {
    const depot = get(currentDepotState);
    const depotOnboarding = get(currentDepotOnboardingState);
    const loggedInUserOnboading = get(loggedInUserOnboardingState);

    if (!depot || !depotOnboarding || !loggedInUserOnboading) return false;

    const depotStatus = getWorkflowStatus(
      loggedInUserOnboading,
      depotOnboarding,
      depot
    );

    return !isDepotNotAccessible(depotStatus);
  }
});

export const depotForUserUriIsAccessibleState = selectorFamily({
  key: "depotForUserUriIsAccessibleState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      const depot = get(depotForUserUriState(userUri));

      const depotOnboarding = get(currentDepotOnboardingState);
      const loggedInUserOnboading = get(loggedInUserOnboardingState);

      if (!depot || !depotOnboarding || !loggedInUserOnboading) return false;

      const depotStatus = getWorkflowStatus(
        loggedInUserOnboading,
        depotOnboarding,
        depot
      );

      return !isDepotNotAccessible(depotStatus);
    }
});

export const currentDepotUserUriState = selector({
  key: "currentDepotUserUriState",
  get: ({ get }) => {
    const depot = get(currentDepotState);

    return depot?.userUri;
  }
});

export const currentDepotInvestmentPlanState = selector({
  key: "currentDepotInvestmentPlanState",

  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    get(getRequestState("investmentPlan", userUri));

    if (!userUri) return undefined;

    const investmentPlanHistoryResult =
      await smavestoCore.services.investmentPlan.getHistory(userUri);

    if (isApiErrorResponse(investmentPlanHistoryResult)) return undefined;

    return investmentPlanHistoryResult;
  }
});

export const currentDepotOnboardingState = selector({
  key: "currentDepotOnboardingState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    if (!userUri) return undefined;

    get(getRequestState("onboarding", userUri));

    const result = await smavestoCore.services.onboarding.getOnboardingData(
      userUri
    );

    if (isApiErrorResponse(result)) return undefined;

    return result;
  }
});

export const currentDepotfilteredTransactionsState = selector({
  key: "currentDepotfilteredTransactionsState",
  get: async ({ get }) => {
    const filter = get(transactionsFilterState);

    const investmentPlan = get(currentDepotInvestmentPlanState);

    if (!investmentPlan) return [];

    const { investmentPlanHistory } = investmentPlan;

    switch (filter) {
      case "ALL":
        return investmentPlanHistory;

      case "EINZAHLUNG":
        return investmentPlanHistory.filter(
          t =>
            t.investmentType === filter ||
            t.investmentType === "EINMALEINZAHLUNG"
        );
      case "SPARPLAN":
        return investmentPlanHistory.filter(
          t =>
            t.investmentType === "SPARPLAN_AENDERUNG" ||
            t.investmentType === "SPARPLAN_EINZAHLUNG"
        );

      default:
        return investmentPlanHistory.filter(t => t.investmentType === filter);
    }
  }
});

export const firstTransactionState = selector({
  key: "firstTransactionState",
  get: async ({ get }) => {
    const investmentPlan = get(currentDepotInvestmentPlanState);

    if (!investmentPlan) return undefined;

    const { investmentPlanHistory } = investmentPlan;

    const [firstTransaction] = investmentPlanHistory
      .slice()
      .sort((a, b) => (a.startDate > b.startDate ? 1 : -1));

    return firstTransaction;
  }
});

export const currentDepotRiskYieldState = selector({
  key: "currentDepotRiskYieldState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);
    return userUri
      ? successOrUndefined(
          await smavestoCore.services.user.getRiskYield(userUri)
        )
      : undefined;
  }
});

export const currentDepotInvestmentSettingState = selector({
  key: "currentDepotInvestmentSettingState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    if (!userUri) return undefined;

    get(getRequestState("investmentSettings", userUri));

    return successOrUndefined(
      await smavestoCore.services.user.getInvestmentSettings(userUri)
    );
  }
});

export const currentDepotProfileState = selector({
  key: "currentDepotProfileState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    if (!userUri) return undefined;

    return successOrUndefined(
      await smavestoCore.services.user.getProfile(userUri)
    );
  }
});

export const currentDepotFinancialPositionState = selector({
  key: "currentDepotFinancialPositionState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    get(getRequestState("financialPosition", userUri));

    return userUri
      ? successOrUndefined(
          await smavestoCore.services.user.getFinancialSituation(userUri)
        )
      : undefined;
  }
});

export const currentDepotSavingAmountState = selector({
  key: "currentDepotSavingAmount",
  get: async ({ get }) => {
    const currentDepot = get(currentDepotState);

    if (!currentDepot) return undefined;

    get(getRequestState("investmentSettings", currentDepot.userUri));

    return successOrUndefined<SavingAmountDto>(
      await smavestoCore.services.depot.getMonthlyAmount(currentDepot.id)
    );
  }
});

export const currentDepotRiskyieldState = selector({
  key: "currentDepotRiskyieldState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    if (!userUri) return undefined;

    get(getRequestState("riskyield", userUri));

    return successOrUndefined(
      await smavestoCore.services.user.getRiskYield(userUri)
    );
  }
});

export const useRefreshcurrentDepotRiskYield = (
  environment: StateEnvironmentUser
) => useRefreshState("riskyield", environment);

export const currentDepotInboxToConfirmState = selector({
  key: "currentDepotInboxToConfirmState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    if (!userUri) return undefined;

    get(getRequestState("inbox", userUri));

    return userUri
      ? successOrUndefined(
          await smavestoCore.services.inbox.getInboxToConfirm(userUri)
        ) ?? []
      : [];
  }
});

export const currentDepotCategoriesFilterState = atomFamily<number, number>({
  key: "currentDepotCategoriesFilterState",
  default: () =>
    selector({
      key: "currentDepotCategoriesFilterState/Default",
      get: ({ get }) => {
        const inboxToConfirm = get(currentDepotInboxToConfirmState);

        if (!inboxToConfirm) return 0;

        return inboxToConfirm.length > 0 ? 6 : 0;
      }
    })
});

export const currentDepotInboxCategoriesState = selector({
  key: "currentDepotInboxCategoriesState",
  get: async ({ get }) => {
    const inbox = get(currentDepotInboxState);
    const inboxToConfirm = get(currentDepotInboxToConfirmState);

    return getCategories(inbox, inboxToConfirm);
  }
});

export const currentDepotInboxState = selector({
  key: "currentDepotInboxState",
  get: async ({ get }) => {
    const userUri = get(currentDepotUserUriState);

    if (!userUri) return undefined;

    get(getRequestState("inbox", userUri));

    return userUri
      ? successOrUndefined(
          await smavestoCore.services.inbox.getInbox(userUri)
        ) ?? []
      : [];
  }
});

export const currentDepotCurrentCategoryState = selector({
  key: "currentDepotCurrentCategoryState",
  get: async ({ get }) => {
    const filter = get(currentDepotCategoriesFilterState(0));

    const categories = get(currentDepotInboxCategoriesState);

    return categories[filter];
  }
});

export const currentDepotCustodyState = selector<CustodyDto | undefined>({
  key: "currentDepotCustodyState",
  get: async ({ get }) => {
    const isUnderage = get(currentDepotIsUnderageState);
    const userUri = get(currentDepotUserUriState);

    if (!userUri || !isUnderage) return undefined;

    const custodyResponse = await smavestoCore.services.user.getCustody(
      userUri
    );

    get(getRequestState("custody", userUri));

    if (isApiErrorResponse(custodyResponse)) {
      return undefined;
    }

    return custodyResponse;
  }
});
