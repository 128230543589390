import type { FormItemProps, RadioChangeEvent } from "antd";
import { Radio, Space } from "antd";

import { SRadio } from "@styled-components/form/SFormItems";

import FormItemWithInfo from "./FormItemWithInfo";

interface Props<T> extends FormItemProps {
  items: { value: T; label: string; disabled?: boolean }[];
  onChange?: (e: RadioChangeEvent) => void;
  direction?: "vertical" | "horizontal";
  defaultValue?: T;
  testId?: string;
  info?: {
    title: string;
    text: string;
  };
}

export function RadioGroup<T extends string | number | boolean>({
  items,
  testId,
  direction,
  onChange,
  defaultValue,
  ...formItemProps
}: Props<T>) {
  return (
    <FormItemWithInfo {...formItemProps}>
      <Radio.Group
        defaultValue={defaultValue}
        onChange={onChange}
        data-testid={testId}
      >
        <Space direction={direction}>
          {items.map(item => (
            <SRadio key={item.label} {...item}>
              {item.label}
            </SRadio>
          ))}
        </Space>
      </Radio.Group>
    </FormItemWithInfo>
  );
}
