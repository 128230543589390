import { Col, Row } from "antd";
import { useRouter } from "next/router";

import CancellationTooltip from "@components/form/tooltips/CancellationTooltip";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { SButton } from "@styled-components/form/SButtons";
import { currentDepotOnboardingState } from "@states/current.depot.state";

import { getNextStepToOnboarding } from "../page-container/SecureLayout/onboardingSteps";
import OuterHint from "./OuterHint";

function FinishRegularDepotMessage() {
  const { push } = useRouter();

  const [, onboarding] = useAsyncSelector(currentDepotOnboardingState);

  const underageOnboardingNextStep = getNextStepToOnboarding(
    onboarding?.completedChapters ?? []
  );

  if (!underageOnboardingNextStep) return null;

  return (
    <OuterHint title="Depot abschließen">
      <p>
        Bitte schließen Sie die Erstellung Ihres Depots ab, indem Sie auf
        &quot;Fortsetzen&quot; drücken.
      </p>
      <Row justify="center" gutter={12} style={{ marginTop: 12 }}>
        <Col lg={7} xs={24}>
          <CancellationTooltip>
            <SButton
              style={{ width: "100%", marginTop: 12 }}
              onClick={() => push(underageOnboardingNextStep)}
              className="reset"
            >
              Fortsetzen
            </SButton>
          </CancellationTooltip>
        </Col>
      </Row>
    </OuterHint>
  );
}

export default FinishRegularDepotMessage;
