import { selector, selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import type CustodyDto from "smavesto.core/lib/types/dto/user/Custody";
import { userUriState } from "./auth.state";
import { getRequestState, useRefreshState } from "./request.state";

// Details of the second parent user in the custody form
export const custodyState = selector<CustodyDto | undefined>({
  key: "currentDepotCustodySecondParentUserState",
  get: async ({ get }) => {
    const userUri = get(userUriState("activeOnboardingOwner"));

    if (!userUri) return undefined;

    get(getRequestState("custody", userUri));

    const custodyResponse = await smavestoCore.services.user.getCustody(
      userUri
    );

    // Return joint custody as default on error
    if (isApiErrorResponse(custodyResponse)) {
      return undefined;
    }

    return custodyResponse;
  }
});

// Details of the second parent user in the custody form
export const custodySecondGuardianState = selector<CustodyDto | undefined>({
  key: "custodySecondGuardianState",
  get: async ({ get }) => {
    const userUri = get(userUriState("activeOnboardingOwner"));

    if (!userUri) return undefined;

    get(getRequestState("custody", userUri));

    const custodyResponse = await smavestoCore.services.user.getCustody(
      userUri
    );

    // Return joint custody as default on error
    if (isApiErrorResponse(custodyResponse)) {
      return undefined;
    }

    return custodyResponse;
  }
});

export const useRefreshCustodySecondParent = () =>
  useRefreshState("custody", "activeOnboardingOwner");

export const isSingleCustodySelector = selector<boolean>({
  key: "isSingleCustodySelector",
  get: ({ get }) => {
    const custodySecondParentUser = get(custodyState);
    if (custodySecondParentUser?.custody === "SOLE_CUSTODY") return true;
    return false;
  }
});

// 'JOINT_CUSTODY' is the default option
export const isJointCustodySelector = selector<boolean>({
  key: "isJointCustodySelector",
  get: ({ get }) => {
    const custodySecondParentUser = get(custodyState);
    if (custodySecondParentUser?.custody === "SOLE_CUSTODY") return false;
    return true;
  }
});

export const custodyForUserUriState = selectorFamily({
  key: "customerSupplierForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("custody", userUri));

      const custodyResponse = await smavestoCore.services.user.getCustody(
        userUri
      );

      // Return joint custody as default on error
      if (isApiErrorResponse(custodyResponse)) {
        return undefined;
      }

      return custodyResponse;
    }
});
