import type WorkflowStepGroup from "../models/WorkflowStepGroup";

/* This method returns all previous steps of a workflow or an empty array if the workflow is finished */
const getAllPreviousSteps = (
  activeChapter: string,
  onboardingStepGroup: WorkflowStepGroup[]
): string[] => {
  const steps = onboardingStepGroup
    .flatMap(group => group.items)
    .flatMap(onboardingStep => onboardingStep.chapterName);

  const indexOfStep = steps.findIndex(step => step === activeChapter);

  return steps.slice(0, indexOfStep);
};

export default getAllPreviousSteps;
