import { Progress } from "antd";
import cx from "classnames";
import { useRouter } from "next/router";

import { ActionButtonComponent } from "@components/form/buttons/ActionButtonComponent";
import { MobileUnderageMenu } from "@components/layout/navigation/OnboardingNavigation/MobileUnderageMenu";
import { OnboardingUnderageNavigation } from "@components/layout/navigation/OnboardingNavigation/OnboardingUnderageNavigation";
import { ThemeContext } from "@pages/_app";
import type { ILayoutBaseProps } from "@src/interfaces/jsx.interface";
import { useContext, useState } from "react";

import { depotsState } from "@src/state/depot.state";
import LightModeSwitch from "../../lightmode/LightModeSwitch";
import SecondLayerWrapper from "../../lightmode/SecondLayerWrapper";
import { FooterIntern } from "../../page/footer/FooterIntern";
import styles from "../OnboardingLayout/styles.module.less";
import BasicPageLayout from "../layout/BasicPageLayout";
import { useUnderageOnboardingLayout } from "./useUnderageOnboardingLayout";

export interface IProps extends ILayoutBaseProps {
  disableHeader?: boolean;
  disableFooter?: boolean;
}

export const UnderageOnboardingLayout: React.FunctionComponent<
  IProps
> = props => {
  const { push } = useRouter();

  const gotoDashboard = () => {
    push("/dashboard/depot");
  };

  const [, depots] = useState(depotsState);

  const userHasMoreThanOneDepot = depots?.length || 0 > 1;

  const { logout, completedChapters, progress, directAccess } =
    useUnderageOnboardingLayout();

  // const { onDeleteOnboarding, contextHolder } = useDeleteOnboarding();

  const { theme } = useContext(ThemeContext);

  return (
    <BasicPageLayout>
      <SecondLayerWrapper paddingTop>
        <div className={styles["main-layout-container"]}>
          <div className={styles["bar-wrapper"]}>
            <OnboardingUnderageNavigation
              completedChapters={completedChapters}
            />
          </div>

          <div className={styles["mobile-bar-wrapper"]}>
            <MobileUnderageMenu completedChapters={completedChapters} />
          </div>

          <div
            className={cx(
              styles["layout-container"],
              styles["wrapper-container"],
              "g-layout-container--master"
            )}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "93%",
                flexWrap: "wrap",
                marginBottom: 8
              }}
            >
              <Progress
                format={percent => (
                  <span
                    style={{
                      color: theme.palette.primary,
                      fontWeight: "bold"
                    }}
                  >
                    {percent}%
                  </span>
                )}
                width={50}
                trailColor="#80808057"
                strokeColor={theme.palette.primary}
                type="circle"
                percent={progress}
              />
              {/*      <ActionButtonComponent
              style={{
                margin: 0,
                marginLeft: 20,
                justifyContent: "flex-end"
              }}
              title="Löschen"
              htmlType="action"
              onClick={onDeleteOnboarding}
            /> */}
              {userHasMoreThanOneDepot && (
                <ActionButtonComponent
                  style={{
                    margin: 0,
                    marginLeft: 20,
                    justifyContent: "flex-end",
                    marginRight: 20
                  }}
                  title="Zurück zum Dashboard"
                  htmlType="action"
                  onClick={gotoDashboard}
                />
              )}
              <ActionButtonComponent
                style={{
                  margin: 0,
                  marginLeft: 20,
                  justifyContent: "flex-end",
                  marginRight: 20
                }}
                title="Abmelden"
                htmlType="action"
                onClick={logout}
              />

              <LightModeSwitch />
            </div>

            <props.mainComp
              router={props.routeProps}
              pageProps={props.pageProps}
            />
          </div>
        </div>
      </SecondLayerWrapper>
      {props?.disableFooter ? null : (
        <div className={cx(styles["layout-footer"], "g-layout-footer--master")}>
          <FooterIntern />
        </div>
      )}
    </BasicPageLayout>
  );
};
