import { Row } from "antd";
import styled from "styled-components";

import theme from "../../../styles/theme";

export const StyledMobileDepotNavigation = styled(Row)`
  width: 100%;
  border-top: 1px solid ${theme.primary};
  border-bottom: 1px solid ${theme.primary};

  border-top: 1px solid ${theme.primary};
  flex-direction: column;

  .ant-list-items {
    display: flex;
    flex-direction: column;

    .ant-list-item {
      border: 0;
      color: #fff;
      cursor: pointer;
    }

    .ant-list-item.active,
    .ant-list-item:hover {
      color: ${theme.primary};
    }
  }

  .wrap-icon-logout {
    width: 40px;
    height: 40px;
    background: ${theme.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    span {
      font-size: 25px;
      color: #002d2f;
    }
  }

  .wrap-icon-logout {
    width: 30px;
    height: 30px;

    span {
      font-size: 20px;
      color: #002d2f;
    }
  }

  .wrap-icon-logout:hover {
    background: var(--ant-primary-color-hover);
    cursor: pointer;
  }

  .ant-list-lg .ant-list-item {
    padding: 6px 16px;
  }

  .ant-list-item {
    padding: 16px 5px;
    font-size: 18px;
    font-weight: 300;
  }
`;

export const StyledDepotNavigation = styled(Row)`
  width: 100%;
  border-top: 1px solid ${theme.primary};
  border-bottom: 1px solid ${theme.primary};
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;

  .ant-list-items {
    display: flex;
    flex-direction: row;
    .ant-list-item {
      border: 0;
      color: #fff;
      cursor: pointer;
    }

    .ant-list-item.active,
    .ant-list-item:hover {
      color: ${theme.primary};
    }
  }

  .wrap-icon-logout {
    width: 40px;
    height: 40px;
    background: ${theme.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    span {
      font-size: 25px;
      color: #002d2f;
    }
  }

  .wrap-icon-logout:hover {
    background: var(--ant-primary-color-hover);
    cursor: pointer;
  }

  @media (max-width: 610px) {
    border-top: 1px solid ${theme.primary};
  }

  .ant-list-lg .ant-list-item {
    padding: 16px 22px;
  }

  @media (max-width: 1125px) {
    .ant-list-lg .ant-list-item {
      padding: 16px 6px;
    }
  }

  @media (max-width: 650px) {
    .wrap-icon-logout {
      width: 30px;
      height: 30px;

      span {
        font-size: 20px;
        color: #002d2f;
      }
    }
  }

  @media (max-width: 500px) {
    .wrap-icon-logout {
      width: 25px;
      height: 25px;

      span {
        font-size: 17px;
        color: #002d2f;
      }
    }
  }

  @media (max-width: 440px) {
    .ant-list-item {
      padding: 16px 5px;
      font-size: 12px;
    }
  }
`;
