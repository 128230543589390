import { atom, atomFamily, selector, selectorFamily } from "recoil";

import { IBAN_DICTIONARY } from "@models/input/masks/bank-details.ibans";
import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { userUriState } from "@states/auth.state";
import type BankDetailsDto from "smavesto.core/lib/types/dto/user/BankDetailsDto";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { getRequestState, useRefreshState } from "./request.state";

export const placeOrderState = atom({
  key: "PlaceOrder",
  default: false
});

export const showExemptionOrderInfoModalState = atom({
  key: "showExemptionOrderInfoModal",
  default: false
});

export const bankDetailsState = selectorFamily({
  key: "bankDetails",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      get(getRequestState("bankDetails", userUri));

      if (!userUri) return undefined;

      return successOrUndefined<BankDetailsDto>(
        await smavestoCore.services.user.getBankDetails(userUri)
      );
    }
});

export const underageAccountHoldersListStateForUserUri = selectorFamily({
  key: "underageAccountHoldersList",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("bankDetails", userUri));

      const underageAccountHoldersListResponse =
        await smavestoCore.services.underage.getUnderageAccountHolderList(
          userUri
        );

      if (isApiErrorResponse(underageAccountHoldersListResponse))
        return undefined;

      return underageAccountHoldersListResponse;
    }
});

export const bankDetailsStateForUserUri = selectorFamily({
  key: "bankDetailsStateForUserUri",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("bankDetails", userUri));

      return successOrUndefined<BankDetailsDto>(
        await smavestoCore.services.user.getBankDetails(userUri)
      );
    }
});

export const useRefreshBankDetails = (type: StateEnvironmentUser) =>
  useRefreshState("bankDetails", type);

export const taxResidencesState = atom<string[]>({
  key: "TaxResidences",
  default: []
});

export const ibanState = atomFamily({
  key: "iban",
  default: (type: StateEnvironmentUser) =>
    selector({
      key: "iban/Default",
      get: ({ get }) => {
        const bankDetails = get(bankDetailsState(type));

        return bankDetails?.payment.bankAccount.iban ?? "";
      }
    })
});

export const ibanMaskState = selectorFamily({
  key: "ibanMaskState",
  get:
    (type: StateEnvironmentUser) =>
    ({ get }) => {
      const iban = get(ibanState(type));

      const countryCode = iban
        .slice(0, 2)
        .toUpperCase() as keyof typeof IBAN_DICTIONARY;

      return IBAN_DICTIONARY[countryCode]?.mask ?? IBAN_DICTIONARY.DE.mask;
    }
});
