import React, { useContext } from "react";
import type { SwitchProps } from "antd";
import { Switch } from "antd";
import styled from "styled-components";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import { ThemeContext } from "@pages/_app";

export const SSwitchComponent = (theme: AppTheme) => styled(Switch)`
  background: ${theme.palette.primary};
  span {
    color: ${theme.palette.primaryTextColor};
  }
`;

const SSwitch: React.FunctionComponent<SwitchProps> = props => {
  const { theme } = useContext(ThemeContext);

  const RenderComponent = SSwitchComponent(theme);

  return <RenderComponent {...props} />;
};

export default SSwitch;
