import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import { atom, selectorFamily } from "recoil";
import { currentDepotUserUriState } from "./current.depot.state";
import {
  activeOnboardingDepotState,
  notJuristicDepotState
} from "./depot.state";

export const loggedInUserUriState = atom<string>({
  key: "loggedInUserUri",
  default: undefined
});

export const expiresState = atom({
  key: "expires",
  default: ""
});

export const refreshLoadingState = atom({
  key: "refreshLoading",
  default: false
});

export const isSessionActiveState = atom({
  key: "isSessionActive",
  default: false
});

export const userUriState = selectorFamily({
  key: "userUri",
  get:
    (type: StateEnvironmentUser) =>
    ({ get }) => {
      switch (type) {
        case "loggedInUser":
          return get(loggedInUserUriState);

        case "activeOnboardingOwner":
          return get(activeOnboardingDepotState)?.userUri;

        case "notJuristicOnboardingOwner":
          return get(notJuristicDepotState)?.userUri;

        case "currentDepotOwner":
          return get(currentDepotUserUriState);

        default:
          return get(loggedInUserUriState);
      }
    }
});
