import styled from "styled-components";

export const StyledCheckbox = styled.div`
  margin: 15px 0;
  color: inherit !important;

  .ant-checkbox {
    color: inherit;
  }

  && .title {
    font-weight: bold;
  }

  && .addition {
    font-size: 11px;
    color: gray;
    margin-top: 5px;
  }

  && .wrap-icon-info {
    margin-left: -2px;
    span {
      color: inherit !important;
      font-size: 16px !important;
    }
  }

  && label {
    color: inherit !important;
    font-size: 16px !important;
  }

  && .checkbox label span:nth-child(1) span::after {
    border-color: white;
  }

  && .checkbox {
    color: inherit;
    display: flex;
    flex-direction: column;
  }

  && .wrap-checkbox-option {
    display: flex;
    align-items: center;
  }
`;
