import { Tooltip } from "antd";
import type { TooltipPlacement } from "antd/es/tooltip";
import type { CSSProperties } from "react";
import React, { cloneElement } from "react";

export type CustomTooltipProps = {
  title?: string;
  placement?: TooltipPlacement;
  disabled?: boolean;
  style?: CSSProperties;
  children: JSX.Element;
};

const CustomTooltip: React.FunctionComponent<CustomTooltipProps> = ({
  placement,
  disabled,
  title,
  style,
  children
}) => {
  return (
    <Tooltip title={title} color="red" placement={placement} style={style}>
      {cloneElement(children, { ...children.props, disabled, title })}
    </Tooltip>
  );
};

export default CustomTooltip;
