/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, List } from "antd";
import { useRouter } from "next/router";
import { IoMegaphoneOutline } from "react-icons/io5";

import {
  FolderOpenOutlined,
  LineChartOutlined,
  UserOutlined,
  WalletOutlined
} from "@ant-design/icons";
import { useSafeLogout } from "@src/page-components/onboarding/hooks";
import { ThemeContext } from "@src/pages/_app";
import useIsMobile from "@src/theme/hook/useIsMobile";
import { useContext } from "react";
import { MdSupportAgent } from "react-icons/md";
import LightModeSwitch from "../lightmode/LightModeSwitch";
import {
  StyledDepotNavigation,
  StyledMobileDepotNavigation
} from "./StyledDepotNavigation";

const items = [
  { to: "/dashboard/depot", text: "Depot", icon: <LineChartOutlined /> },
  {
    to: "/dashboard/transactions",
    text: "Transaktionen",
    icon: <WalletOutlined />
  },
  { to: "/dashboard/inbox", text: "Dokumente", icon: <FolderOpenOutlined /> },
  { to: "/dashboard/profile", text: "Profil", icon: <UserOutlined /> },
  {
    to: "/dashboard/support",
    text: "Support",
    icon: <MdSupportAgent />
  },
  {
    to: "/dashboard/blog",
    text: "Neuigkeiten",
    icon: <IoMegaphoneOutline />
  }
];

const DepotNavigation: React.FunctionComponent = () => {
  const { push, route } = useRouter();

  const isMobile = useIsMobile();

  const onLogout = useSafeLogout();

  const { theme } = useContext(ThemeContext);

  if (isMobile)
    return (
      <StyledMobileDepotNavigation>
        <List
          size="large"
          dataSource={items}
          renderItem={({ to, text, icon }) => (
            <List.Item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
              onClick={() => push(to)}
              className={
                route === to || (route === "/" && to === "/dashboard/depot")
                  ? "active"
                  : ""
              }
            >
              {icon}&nbsp;&nbsp;{text}
            </List.Item>
          )}
        />
        <Button
          type="primary"
          style={{
            borderRadius: 50,
            border: 0,
            outline: 0,
            fontSize: 12,
            margin: "10px auto",
            width: 300,
            padding: "3px 24px",
            color: theme.palette.primaryTextColor
          }}
          onClick={onLogout}
        >
          Logout
        </Button>
      </StyledMobileDepotNavigation>
    );

  return (
    <StyledDepotNavigation>
      <List
        size="large"
        dataSource={items}
        renderItem={({ to, text }) => (
          <List.Item
            onClick={() => push(to)}
            className={
              route === to || (route === "/" && to === "/dashboard/depot")
                ? "active"
                : ""
            }
          >
            {text}
          </List.Item>
        )}
      />
      {!isMobile && (
        <div
          style={{
            marginBottom: isMobile ? 14 : 0,
            marginLeft: isMobile ? "auto" : 0
          }}
        >
          <LightModeSwitch />
        </div>
      )}
    </StyledDepotNavigation>
  );
};

export default DepotNavigation;
