import { useEffect, useState } from "react";

export const useAsyncMethod = <T extends NonNullable<unknown>>(
  method: () => Promise<T>
): [T | undefined, () => void, boolean] => {
  const [result, setResult] = useState<T>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reload = async (): Promise<void> => {
    setIsLoading(true);
    return method().then(a => {
      setResult(a);
      setIsLoading(false);
    });
  };

  /* initial load  */
  useEffect(() => {
    reload();
  }, []);

  return [result, reload, isLoading];
};
