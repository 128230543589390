import { useContext, useState } from "react";

import { WarningFilled } from "@ant-design/icons";
import EditCustodyModalForm from "@components/entities/custody/modal/EditCustodyModalForm";
import { LockedDepotMessage } from "@page-components/dashboard/Depot/locked-message/LockedDepotMessage";
import { SIconCircle } from "@page-components/public/registration/activation/styled";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { profileState } from "@src/state/profile.state";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";
import { SP } from "@styled-components/STags";
import { ThemeContext } from "@src/pages/_app";

const WaitingForAnotherSecondGuardianInviteMessage: React.FunctionComponent =
  () => {
    const { theme } = useContext(ThemeContext);

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const [, personalData] = useAsyncSelector(
      profileState("currentDepotOwner")
    );

    const openEditModal = () => {
      setModalOpen(true);
    };

    return (
      <>
        <LockedDepotMessage
          actions={
            <SActionButtonsWrapper style={{ marginTop: 30 }}>
              <SButton
                onClick={() => openEditModal()}
                className="reset"
                style={{ marginRight: "auto" }}
              >
                Daten erneut eingeben
              </SButton>
            </SActionButtonsWrapper>
          }
          icon={
            <SIconCircle style={{ background: "red" }}>
              <WarningFilled />
            </SIconCircle>
          }
        >
          <SP fontWeight={600} fontSize="20px">
            Hinweis
          </SP>
          <SP
            style={{ color: theme.palette.primary }}
            fontWeight={400}
            fontSize="16px"
          >
            Die letzte gesendete Einladung an den weiteren gesetzlichen
            Vertreter Ihres Kindes {personalData?.firstName} ist abgelaufen.
          </SP>

          <SP fontWeight={400} fontSize="16px">
            Bitte geben Sie die Daten erneut ein, damit der Onboarding-Prozess
            abgeschlossen werden kann.
          </SP>
        </LockedDepotMessage>
        <EditCustodyModalForm
          onCancel={() => setModalOpen(false)}
          isOpen={isModalOpen}
        />
      </>
    );
  };

export default WaitingForAnotherSecondGuardianInviteMessage;
