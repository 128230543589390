import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { currentDepotState } from "@src/state/current.depot.state";
import { useRefreshState } from "@src/state/request.state";
import { customerSupplierState } from "@src/state/settings.state";
import { setCookie } from "@src/utils/cookies";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import type { CustomModalAction } from "./useCustomModal";
import useCustomModal from "./useCustomModal";

function useCloneSupplierForNewDepot() {
  const refreshThemeDepotOwner = useRefreshState("theme", "currentDepotOwner");
  const refreshThemeActiveOnboardingOwner = useRefreshState(
    "theme",
    "activeOnboardingOwner"
  );

  const { openModal, contextHolder } = useCustomModal();

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const [, currentDepotSupplier] = useAsyncSelector(
    customerSupplierState("currentDepotOwner")
  );

  const supplierName = currentDepotSupplier?.name;

  const currentDepotIsUnderage = currentDepot
    ? isJuniorDepot(currentDepot)
    : false;

  const hasSupplier = !!currentDepotSupplier;

  const routeToDepotCreation = (
    depotType: "default" | "underage",
    passSupplierId: boolean
  ) => {
    if (
      passSupplierId &&
      currentDepotSupplier?.supplierHashFromCustomerRegistration
    ) {
      setCookie(
        "supplierID",
        currentDepotSupplier?.supplierHashFromCustomerRegistration,
        7
      );
    } else {
      setCookie("supplierID", "", -1);
    }

    refreshThemeDepotOwner();
    refreshThemeActiveOnboardingOwner();

    if (depotType === "underage") {
      window.location.href = `/registration?depotType=underage${
        passSupplierId &&
        currentDepotSupplier &&
        currentDepotSupplier.supplierHashFromCustomerRegistration
          ? `&refreshTheme=1&supplierID=${currentDepotSupplier.supplierHashFromCustomerRegistration}`
          : "&refreshTheme=1"
      }`;
    }
    if (depotType === "default") {
      window.location.href = `/registration?depotType=additional&${
        passSupplierId &&
        currentDepotSupplier &&
        currentDepotSupplier.supplierHashFromCustomerRegistration
          ? `refreshTheme=1&supplierID=${currentDepotSupplier.supplierHashFromCustomerRegistration}`
          : "refreshTheme=1"
      }`;
    }
  };

  const createNewDepot = (
    depotType: "default" | "underage",
    themeKey: string
  ) => {
    /* Always pass the supplierID for anceka (Ticket: WEB-1204) */
    if (!hasSupplier || depotType === "default" || themeKey === "anceka") {
      routeToDepotCreation(depotType, true);
      return;
    }

    const customActions = (
      type: "underage" | "default"
    ): CustomModalAction[] => {
      return [
        {
          label: `Ja, weiter mit ${supplierName}`,
          type: "submit",
          action: () => {
            routeToDepotCreation(type, true);
          }
        },
        {
          label: `Nein, weiter ohne ${supplierName}`,
          type: "default",
          action: () => {
            routeToDepotCreation(type, false);
          }
        }
      ] as CustomModalAction[];
    };

    if (currentDepotIsUnderage) {
      /*  if (depotType === "default") {
        openModal({
          title: "Vermittler übernehmen?",
          content: `Möchten Sie Ihr Depot ebenfalls mit dem Vermittler Ihres Kindes ${supplierName} verknüpfen? Dieses beinhaltet 
          ggf. eine andere Kostenstruktur (betrifft nur Smavesto Premium Kunden), weitere Serviceleistungen und ein individuelles 
          Design für Ihr Depot.`,
          actions: customActions(depotType)
        });
        return;
      } */

      if (depotType === "underage") {
        openModal({
          title: "Vermittler übernehmen?",
          content: `Möchten Sie das neue Depot Ihres weiteren Kindes ebenfalls mit dem Vermittler Ihres Kindes ${supplierName} verknüpfen? 
          Dieses beinhaltet ggf. eine andere Kostenstruktur (betrifft nur Smavesto Premium Kunden), weitere Serviceleistungen und ein 
          individuelles Design für das Depot Ihres Kindes.`,
          actions: customActions(depotType)
        });
      }
    } else {
      if (depotType === "underage") {
        openModal({
          title: "Vermittler übernehmen?",
          content: `Möchten Sie das Depot Ihres Kindes ebenfalls mit Ihrem Vermittler ${supplierName} verknüpfen? Dieses beinhaltet ggf. 
          eine andere Kostenstruktur (betrifft nur Smavesto Premium Kunden), weitere Serviceleistungen und ein individuelles Design für 
          das Depot Ihres Kindes.`,
          actions: customActions(depotType)
        });
        return;
      }
      if (depotType === "default") {
        openModal({
          title: "Vermittler übernehmen?",
          content: `Möchten Sie Ihr Depot ebenfalls mit dem Vermittler ${supplierName} verknüpfen? Dieses beinhaltet ggf. eine andere 
          Kostenstruktur (betrifft nur Smavesto Premium Kunden), weitere Serviceleistungen und ein individuelles Design für Ihr Depot.`,
          actions: customActions(depotType)
        });
      }
    }
  };

  return { contextHolder, createNewDepot };
}

export default useCloneSupplierForNewDepot;
