import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useContext } from "react";

import { ThemeContext } from "@pages/_app";
import type { ICompBaseProps } from "@src/interfaces/jsx.interface";

interface IProps extends ICompBaseProps {
  title: string;
  order: string;
  complete?: boolean;
  disabled?: boolean;
  hidden: boolean;
  onClick: any;
  hideOrderCircle?: boolean;
}

export const MenuButton: React.FC<IProps> = ({
  complete,
  disabled,
  hidden,
  order,
  title,
  onClick,
  hideOrderCircle,
  ...props
}) => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const buttonStyles = hideOrderCircle
    ? {
        borderWidth: 4,
        ...props.style
      }
    : undefined;

  return hidden ? null : (
    <div
      style={{
        position: "relative",
        minWidth: 260,
        height: 58,
        opacity: disabled ? 0.5 : 1
      }}
    >
      {!hideOrderCircle && (
        <Button
          icon={
            complete ? (
              <CheckOutlined
                style={{ color: theme.palette.secondLayerBackgroundColor }}
              />
            ) : null
          }
          type="primary"
          shape="circle"
          style={{
            height: 58,
            width: 58,
            position: "absolute",
            left: 0,
            zIndex: 2,
            fontWeight: "bold",
            borderWidth: 4,
            color: theme.palette.primaryTextColor,
            background: theme.palette.primary,
            borderColor: theme.palette.backgroundColor,
            ...props.style
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {!complete && order}
        </Button>
      )}
      <Button
        type="primary"
        style={{
          borderRadius: 19,
          width: hideOrderCircle ? 220 : 260,
          textAlign: "start",
          color: theme.palette.primaryTextColor,
          fontWeight: "bold",
          position: "absolute",
          height: 48,
          background: theme.palette.primary,
          left: hideOrderCircle ? 50 : 10,
          top: 4,
          fontSize: 13,
          paddingLeft: hideOrderCircle ? 20 : 55,
          boxShadow: "3px 3px 3px rgba(0,0,0,0.2)",
          ...buttonStyles
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {title}
      </Button>
    </div>
  );
};
