import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { useRouter } from "next/router";
import Script from "next/script";

interface UpprAffiliateTrackingNodeProps {}

const UpprAffiliateTrackingNode: React.FunctionComponent<
  UpprAffiliateTrackingNodeProps
> = () => {
  const router = useRouter();

  const pathname = router?.pathname;

  const activationHash = router.query?.activationHash;

  const isFeatureFlagged = useFeatureFlag("UPPR");

  if (isFeatureFlagged) return null;

  const getPageType = (pathname: string): string | undefined => {
    if (pathname === "/registration") return "produkt";
    if (pathname === "/registration/submit") return "register";
    if (pathname === "/registration/activate" && activationHash)
      return "conversion";
    return undefined;
  };

  if (!pathname) return null;

  const pagetype = getPageType(pathname);

  if (pagetype === undefined) return null;

  smavestoCore.debugLog("info", "Send Uppr Page Request", {
    pagetype,
    pathname
  });

  return (
    <Script
      id="uppr-script"
      type="text/javascript"
      strategy="afterInteractive"
      src={`https://trck.smavesto.de/trck/etms/eatms.js?campaign_id=237&consent=1&pagetype=${pagetype}`}
    />
  );
};

export default UpprAffiliateTrackingNode;
