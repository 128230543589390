import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import type { TRIGGER_ID } from "@src/utils/tracking/uppr/sendUpprAffiliateConversion";
import sendUpprAffiliateConversion from "@src/utils/tracking/uppr/sendUpprAffiliateConversion";
import { getCookie, setCookie } from "@utils/cookies";
import generateGuid from "@utils/encryption/generateGuid";
import type AffiliateNetwork from "smavesto.core/lib/types/dto/affiliate/AffiliateNetwork";
import isFeatureFlagged from "@src/utils/development/isFeatureFlagged";
import * as Sentry from "@sentry/react";

export type ClientAffiliateData = {
  supplierID: string | undefined;
  externalCustomerUUID: string | undefined;
  consultantID: string | undefined;
  utm_source: string | undefined;
  utm_medium: string | undefined;
  utm_campaign: string | undefined;
  utm_term: string | undefined;
  utm_content: string | undefined;
  s_id: string | undefined;
  orderID: string | undefined;
  affiliateNetworkCustomerData: {
    networkID: string;
    affiliateNetwork: AffiliateNetwork;
  } | null;
};

/* This function transforms the url param utm_source into an valid affiliate network */
export const getAffiliateNetworkByUtmSource = (
  utmSource: string | undefined
): AffiliateNetwork | undefined => {
  if (!utmSource) return undefined;

  const parsedUtmSource = utmSource.trim().toLowerCase();

  if (parsedUtmSource === "financeads") return "financeAds";
  if (parsedUtmSource === "uppr") return "uppr";

  return undefined;
};

export const useSupplierAndAffiliateOrigin = (persist: boolean) => {
  const router = useRouter();
  const { query } = router;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [clientAffiliateData, setClientAffiliateData] =
    useState<ClientAffiliateData>();

  const setCookieIfContentIsDifferent = (cookieKey: string, value?: string) => {
    const cookieContent = getCookie(cookieKey, document.cookie);

    if (value && value !== cookieContent) setCookie(cookieKey, value, 90);
  };

  const setCookieIfNoCookieExists = (cookieKey: string, value?: string) => {
    const cookieContent = getCookie(cookieKey, document.cookie);
    if (!cookieContent && value) setCookie(cookieKey, value, 90);
  };

  const persistInCookies = (affiliateData: ClientAffiliateData) => {
    setCookieIfContentIsDifferent("supplierID", affiliateData?.supplierID);
    setCookieIfContentIsDifferent("consultantID", affiliateData?.consultantID);
    setCookieIfContentIsDifferent("utm_source", affiliateData?.utm_source);
    setCookieIfContentIsDifferent("utm_medium", affiliateData?.utm_medium);
    setCookieIfContentIsDifferent("utm_campaign", affiliateData?.utm_campaign);
    setCookieIfContentIsDifferent("utm_term", affiliateData?.utm_term);
    setCookieIfContentIsDifferent("utm_content", affiliateData?.utm_content);
    setCookieIfContentIsDifferent("s_id", affiliateData?.s_id);
    setCookieIfContentIsDifferent(
      "externalCustomerUUID",
      affiliateData?.externalCustomerUUID
    );
    setCookieIfNoCookieExists("orderID", affiliateData?.orderID);

    Sentry.setTag("orderID", affiliateData?.orderID);
    Sentry.setTag("supplierID", affiliateData?.supplierID);
    Sentry.setTag("utm_source", affiliateData?.utm_source);
    Sentry.setTag("s_id", affiliateData?.s_id);
  };

  const getAffiliateDataFromQueryString = (query: any): ClientAffiliateData => {
    const guid = generateGuid();
    const orderIDFromCookie = getCookie("orderID", document.cookie);

    const affiliateNetwork = getAffiliateNetworkByUtmSource(
      query.utm_source as string | undefined
    );

    return {
      supplierID: query.supplierID,
      consultantID: query.consultantID,
      externalCustomerUUID: query.externalCustomerUUID,
      utm_source: query.utm_source,
      utm_medium: query.utm_medium,
      utm_campaign: query.utm_campaign,
      utm_term: query.utm_term,
      utm_content: query.utm_content,
      s_id: query.s_id,
      orderID: orderIDFromCookie || guid,
      affiliateNetworkCustomerData: affiliateNetwork
        ? {
            networkID: orderIDFromCookie || guid,
            affiliateNetwork
          }
        : null
    };
  };

  const getAffiliateDataFromCookies = (): ClientAffiliateData => {
    const sIdCookie = getCookie("s_id", document.cookie);
    const utmSourceCookie = getCookie("utm_source", document.cookie);
    const affiliateNetwork = getAffiliateNetworkByUtmSource(utmSourceCookie);
    const orderIDByCookie = getCookie("orderID", document.cookie);

    return {
      consultantID: getCookie("consultantID", document.cookie),
      supplierID: getCookie("supplierID", document.cookie),
      utm_source: utmSourceCookie,
      externalCustomerUUID: getCookie("externalCustomerUUID", document.cookie),
      utm_medium: getCookie("utm_medium", document.cookie),
      utm_campaign: getCookie("utm_campaign", document.cookie),
      utm_term: getCookie("utm_term", document.cookie),
      utm_content: getCookie("utm_content", document.cookie),
      orderID: getCookie("orderID", document.cookie),
      s_id: sIdCookie,
      affiliateNetworkCustomerData:
        !!affiliateNetwork && orderIDByCookie
          ? {
              networkID: orderIDByCookie,
              affiliateNetwork
            }
          : null
    };
  };

  useEffect(() => {
    const queryState = getAffiliateDataFromQueryString(query);
    const cookieState = getAffiliateDataFromCookies();

    if (persist) {
      /* old link - utm_source is missing */
      if (query.supplierID !== undefined && query.utm_source === undefined) {
        query.utm_source = "partner";
        router.push(router);
      }
      persistInCookies(queryState);
    }

    if (queryState.supplierID || queryState.utm_source) {
      // query contains affiliate data
      setClientAffiliateData(queryState);
    } else {
      // cookies contain affiliate data
      setClientAffiliateData(cookieState);
    }

    setIsLoading(false);
  }, [query]);

  const affiliateData = clientAffiliateData;
  const affiliateDataIsLoading = isLoading;

  /**
   * Sends a conversion to Uppr affiliate network if it's triggered through utm_source.
   *
   * @param {TRIGGER_ID} stage - The ID of the stage that triggered the conversion.
   */
  const sendUpprConversionIfRequired = async (stage: TRIGGER_ID) => {
    // Get the affiliate network based on the utm_source parameter in affiliateData.
    const affiliateNetwork = getAffiliateNetworkByUtmSource(
      affiliateData?.utm_source
    );

    // Determine whether to send a Uppr conversion.
    const shouldSendUpprConversion =
      affiliateNetwork === "uppr" && !isFeatureFlagged("UPPR");

    // Check if all the necessary parameters are available to send a Uppr conversion.
    if (shouldSendUpprConversion && affiliateData?.orderID) {
      // Send the Uppr conversion.
      await sendUpprAffiliateConversion(stage, affiliateData.orderID);
    }
  };

  return {
    affiliateData,
    affiliateDataIsLoading,
    sendUpprConversionIfRequired
  };
};
