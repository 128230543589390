import { ThemeContext } from "@src/pages/_app";
import {
  devThemeConfiguationWindowState,
  localThemeOverWriteState
} from "@src/state/all.states";
import rgbToHex from "@src/utils/format/convertToHex";
import { Divider } from "antd";
import type { ChangeEvent } from "react";
import { useContext, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import type { AppTheme } from "smavesto.core";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";

interface ThemeEditorProps {}
const ThemeEditor: React.FunctionComponent<ThemeEditorProps> = () => {
  const { theme } = useContext(ThemeContext);

  const [currentTheme, setTheme] = useState<AppTheme>(() => theme);

  const setDisplayDevThemeConfiguration = useSetRecoilState(
    devThemeConfiguationWindowState
  );

  const baseKeys = Object.keys(currentTheme.palette);

  const lightModeKeys = currentTheme.lightMode
    ? Object.keys(currentTheme.lightMode)
    : undefined;

  const assetKeys = currentTheme.assets
    ? Object.keys(currentTheme.assets)
    : undefined;

  const darkModeKeys = currentTheme.darkMode
    ? Object.keys(currentTheme.darkMode)
    : undefined;

  const setLocalThemeOverwrite = useSetRecoilState(localThemeOverWriteState);

  useEffect(() => {
    setLocalThemeOverwrite(currentTheme);
  }, [currentTheme]);

  const onChangeAttribute = (
    e: ChangeEvent<HTMLInputElement>,
    parameter: string,
    parent: "lightMode" | "darkMode" | "palette" | "assets"
  ) => {
    // eslint-disable-next-line prefer-const
    let changedThemeObject: any = JSON.parse(JSON.stringify(currentTheme));

    changedThemeObject[parent][parameter] = e.target.value;

    setTheme(changedThemeObject);
  };

  return (
    <>
      <div
        style={{ display: "flex", gap: "1rem", justifyContent: "space-evenly" }}
      >
        <button
          style={{ width: "100%" }}
          type="button"
          onClick={() => {
            const winUrl = URL.createObjectURL(
              new Blob([JSON.stringify(currentTheme)], { type: "text/json " })
            );
            window.open(winUrl);
          }}
        >
          Export
        </button>
        <button
          style={{ width: "100%" }}
          type="button"
          onClick={() => {
            try {
              const json = prompt("Bitte füge hier den JSON-Code ein");
              if (!json) return;
              const themeObject = JSON.parse(json);
              setTheme(themeObject);
            } catch (ex) {
              setTheme(defaultTheme);
            }
          }}
        >
          Import
        </button>
        <button
          style={{ width: "100%" }}
          type="button"
          onClick={() => {
            setTheme(defaultTheme);
          }}
        >
          Reset
        </button>
        <button
          style={{ width: "100%" }}
          type="button"
          onClick={() => {
            setDisplayDevThemeConfiguration(false);
          }}
        >
          Close
        </button>
      </div>
      <Divider />
      <table>
        <tr>
          <td colSpan={2}>
            <b>Basis Theme</b>
          </td>
        </tr>
        {baseKeys.map(parameter => (
          <tr key={parameter}>
            <td>
              <input
                style={{ marginRight: 10 }}
                onChange={e => {
                  onChangeAttribute(e, parameter, "palette");
                }}
                value={rgbToHex((currentTheme.palette as any)[parameter])}
                type="color"
              />
            </td>
            <td>{parameter}</td>
          </tr>
        ))}
        <tr>
          <td colSpan={2}>
            <b>Lightmode Theme</b>
          </td>
        </tr>
        {lightModeKeys?.map(parameter => (
          <tr key={`lightmode_${parameter}`}>
            <td>
              <input
                style={{ marginRight: 10 }}
                value={rgbToHex((currentTheme.lightMode as any)[parameter])}
                type="color"
                onChange={e => {
                  onChangeAttribute(e, parameter, "lightMode");
                }}
              />
            </td>
            <td>{parameter}</td>
          </tr>
        ))}
        <tr>
          <td colSpan={2}>
            <b>Darkmode Theme</b>
          </td>
        </tr>
        {darkModeKeys?.map(parameter => (
          <tr key={`darkmode_${parameter}`}>
            <td>
              <input
                style={{ marginRight: 10 }}
                value={rgbToHex((currentTheme.darkMode as any)[parameter])}
                type="color"
                onChange={e => {
                  onChangeAttribute(e, parameter, "darkMode");
                }}
              />
            </td>
            <td>{parameter}</td>
          </tr>
        ))}
        <tr>
          <td colSpan={2}>
            <b>Assets</b>
          </td>
        </tr>
        {assetKeys?.map(parameter => (
          <tr key={`darkmode_${parameter}`}>
            <td>
              <input
                style={{ marginRight: 10 }}
                value={(currentTheme.assets as any)[parameter]}
                type="text"
                onChange={e => {
                  onChangeAttribute(e, parameter, "assets");
                }}
              />
            </td>
            <td>{parameter}</td>
          </tr>
        ))}
      </table>
    </>
  );
};

export default ThemeEditor;
