import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { useEffect, useState } from "react";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

interface KeyValueStoreValue {
  userUri: string;
  keyName: string;
  transform: (value: string) => string;
}

const KeyValueStoreValue: React.FunctionComponent<KeyValueStoreValue> = ({
  keyName,
  userUri,
  transform
}) => {
  const [value, setValue] = useState<string>("loading");

  useEffect(() => {
    smavestoCore.services.keyValueStore
      .getValueByKey(userUri, keyName)
      .then(result =>
        setValue(isApiErrorResponse(result) ? "empty" : result.value)
      );
  }, []);

  return (
    <p>
      {keyName} = {transform ? transform(value || "empty") : value}
    </p>
  );
};

export default KeyValueStoreValue;
