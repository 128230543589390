import { onboardingByEnvironmentState } from "@states/onboarding.state";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { useSafeLogout } from "@page-components/onboarding/hooks";

export const useUnderageOnboardingLayout = () => {
  const [, onboarding] = useAsyncSelector(
    onboardingByEnvironmentState("activeOnboardingOwner")
  );

  const logout = useSafeLogout("/login?hide=app-notification");

  const completedChapters = onboarding?.completedChapters || [
    "ANLAGEWUNSCH",
    "ZUGANGSDATEN"
  ];
  const disabledChapters = ["ZUGANGSDATEN"];
  const progress = onboarding?.progressInPercent
    ? Math.round(onboarding.progressInPercent)
    : 0;
  const directAccess = onboarding?.directAccess ?? false;

  return {
    logout,
    completedChapters,
    disabledChapters,
    progress,
    directAccess
  };
};
