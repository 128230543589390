import UNDERAGE_URL_PARAM from "@utils/constants/Urls";
import type WorkflowStepGroup from "@src/workflows/engine/models/WorkflowStepGroup";

const underageOnboardingStepsFirstGuardian: (
  isInActiveOnboardingState: boolean
) => WorkflowStepGroup[] = (isInActiveOnboardingState: boolean) => [
  {
    order: 1,
    items: [
      {
        order: 1,
        to: isInActiveOnboardingState
          ? `/onboarding/child-investment?${UNDERAGE_URL_PARAM}`
          : `/registration?${UNDERAGE_URL_PARAM}`,
        text: "Anlagewunsch",
        chapterName: "ANLAGEWUNSCH"
      },
      {
        order: 2,
        to: `/registration/submit`,
        text: "Zugangsdaten",
        chapterName: "ZUGANGSDATEN"
      }
    ]
  },
  {
    order: 2,
    title: "Angaben zum Kind",
    items: [
      {
        order: 3,
        to: `/onboarding/child-personal-data?${UNDERAGE_URL_PARAM}`,
        text: "Persönliche Angaben",
        chapterName: "PERSOENLICHE_ANGABEN"
      },
      {
        order: 4,
        to: `/onboarding/child-financial-situation?${UNDERAGE_URL_PARAM}`,
        text: "Anlageziele und Finanzielle Verhältnisse",
        text_short: "Anlageziele",
        chapterName: "FINANZIELLE_LAGE"
      }
    ]
  },
  {
    order: 3,
    title: "Angaben gesetzliche Vertreter",
    items: [
      {
        order: 5,
        to: `/onboarding/personal-data?${UNDERAGE_URL_PARAM}`,
        text: "Persönliche Angaben",
        chapterName: "PERSOENLICHE_ANGABEN_SORGEBERECHTIGTER"
      },
      {
        order: 6,
        to: `/onboarding/knowledge?${UNDERAGE_URL_PARAM}`,
        text: "Kenntnisse",
        chapterName: "KENNTNISSE_SORGEBERECHTIGTER"
      },
      {
        order: 7,
        to: `/onboarding/custody?${UNDERAGE_URL_PARAM}`,
        text: "Sorgeberechtigung",
        chapterName: "SORGEBERECHTIGUNG"
      },
      {
        order: 8,
        to: `/onboarding/bank-details?${UNDERAGE_URL_PARAM}`,
        text: "Bankverbindung",
        chapterName: "BANKVERBINDUNG"
      },
      {
        order: 9,
        to: `/onboarding/summary-child?${UNDERAGE_URL_PARAM}`,
        text: "Zusammenfassung",
        chapterName: "SUMMARY",
        subSteps: [
          {
            order: 10,
            to: `/onboarding/summary-child?${UNDERAGE_URL_PARAM}`,
            text: "Kind",
            chapterName: "SUMMARY"
          },
          {
            order: 11,
            to: `/onboarding/summary-representative?${UNDERAGE_URL_PARAM}`,
            text: "Gesetzlicher Vertreter",
            chapterName: "SUMMARY"
          }
        ]
      },
      {
        order: 12,
        to: `/onboarding/summary2?${UNDERAGE_URL_PARAM}`,
        text: "Dokumente und Legitimation",
        text_short: "Dokumente",
        chapterName: "DOKUMENTE_GESETZLICHER_VERTRETER_1"
      }
    ]
  }
];

export default underageOnboardingStepsFirstGuardian;
