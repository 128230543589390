interface JsonViewerProps {
  value: any;
}

const JsonViewer: React.FunctionComponent<JsonViewerProps> = ({ value }) => {
  const objectAsText = JSON.stringify(value, null, 2);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
        background: "rgba(0,0,0,.03)",
        whiteSpace: "break-spaces",
        fontStyle: "italic",
        padding: 4
      }}
    >
      {objectAsText}
    </div>
  );
};

export default JsonViewer;
