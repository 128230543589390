import { ThemeContext } from "@src/pages/_app";
import { useContext } from "react";

const useIsSmavestoPremium = (): boolean => {
  const { theme } = useContext(ThemeContext);

  return theme.premiumOnly;
};

export default useIsSmavestoPremium;
