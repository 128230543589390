import SmavestoCore from "smavesto.core/lib/SmavestoCore";

const smavestoCore = new SmavestoCore(
  {
    baseUrl: "/api/dser/",
    appendSessionCookies: false,
    debug: process.env.NEXT_PUBLIC_USE_DEBUG === "true",
    useWebClientMode: true,
    baseUrlContentManagement: "https://www.smavesto.de/rest"
  },
  process.env.NEXT_PUBLIC_CLIENT_ID as any
);

smavestoCore.axiosClient.defaults.withCredentials = true;

export default smavestoCore;
