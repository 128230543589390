function rgbToHex(cssColor: string) {
  // Vordefinierte Farbnamen
  const colorNames = {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "transparent"
  };

  // Überprüfen, ob die Farbe ein vordefinierter Farbname ist
  if ((colorNames as any)[cssColor.toLowerCase()]) {
    return (colorNames as any)[cssColor.toLowerCase()];
  }

  // Regular expressions, um die Bestandteile der CSS-Farbe zu matchen
  const rgbRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/;
  const rgbaMatch = cssColor.match(rgbRegex);

  if (!rgbaMatch) {
    // Ungültige Eingabe
    return cssColor;
  }

  // Extrahieren der Komponenten
  const red = parseInt(rgbaMatch[1], 10);
  const green = parseInt(rgbaMatch[2], 10);
  const blue = parseInt(rgbaMatch[3], 10);
  const alpha = rgbaMatch[4] ? parseFloat(rgbaMatch[4]) : 1;

  // Konvertierung in Hexadezimalwerte
  const redHex = red.toString(16).padStart(2, "0");
  const greenHex = green.toString(16).padStart(2, "0");
  const blueHex = blue.toString(16).padStart(2, "0");
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, "0");

  // Zusammenstellen des Hexadezimalwerts
  let hexColor;
  if (alpha === 1) {
    hexColor = `#${redHex}${greenHex}${blueHex}`;
  } else {
    hexColor = `#${redHex}${greenHex}${blueHex}${alphaHex}`;
  }

  return hexColor.toUpperCase(); // Zur Konsistenz in Großbuchstaben umwandeln
}

export default rgbToHex;
