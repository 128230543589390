import { StyledEntityPanel } from "@components/form/panels/StyledEntityPanel";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { parseRiskNumValueToLabel } from "@page-components/onboarding/FinancialPosition/strategy/helpers";
import { ThemeContext } from "@src/pages/_app";
import { customerSupplierForUserUriState } from "@src/state/settings.state";
import { cancellationDateStateForUserUri } from "@states/cancellation.state";
import { depotDetailsForDepotId } from "@states/depot.state";
import { investmentSettingsByUserUriState } from "@states/investment-settings.state";
import getDepotSettingsLabel from "@utils/labels/depot/getDepotSettingsLabel";
import getTypeLabelForDepot from "@utils/labels/depot/getTypeLabelForDepot";
import { bundledLoadingState } from "@utils/recoil/bundledLoadingState";
import { Button, Col, Row } from "antd";
import moment from "moment";
import Image from "next/image";
import { useContext, useEffect } from "react";
import { RiArrowRightCircleFill } from "react-icons/ri";
import { getAppThemeForSupplierId } from "smavesto.core/lib/business-logic/supplier/getThemeForSupplierID";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";
import type DepotDto from "smavesto.core/lib/types/dto/depot/DepotDto";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

import { useAsyncMethod } from "@src/theme/hook/useAsyncMethod";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import type { AppTheme } from "smavesto.core";
import DepotEntityItemSkeleton from "./skeleton/DepotEntityItemSkeleton";

interface DepotEntityItemProps {
  depot: DepotDto;
  onSelect: (depotId: string) => void;
}

const DepotEntityItem: React.FunctionComponent<DepotEntityItemProps> = ({
  depot,
  onSelect
}) => {
  const [, customerSupplier] = useAsyncSelector(
    customerSupplierForUserUriState(depot.userUri)
  );

  const { theme, appModeTheme } = useContext(ThemeContext);

  const [logoTheme, refreshLogoTheme] = useAsyncMethod<AppTheme>(async () =>
    customerSupplier?.supplierID
      ? getAppThemeForSupplierId(
          customerSupplier?.supplierID,
          smavestoCore,
          depot
        )
      : defaultTheme
  );

  useEffect(() => {
    refreshLogoTheme();
  }, [customerSupplier]);

  const depotTypeLabel = getTypeLabelForDepot(depot);

  const [depotDetailsLoadingState, depotDetails] = useAsyncSelector(
    depotDetailsForDepotId(depot.id)
  );

  const [cancellationDateLoadingState, cancellationDate] = useAsyncSelector(
    cancellationDateStateForUserUri(depot.userUri)
  );

  const [depotInvestmentSettingsLoadingState, depotInvestmentSettings] =
    useAsyncSelector(investmentSettingsByUserUriState(depot.userUri));

  const depotSettingsLabel =
    depotInvestmentSettings && getDepotSettingsLabel(depotInvestmentSettings);

  const riskLabel = depotDetails?.anlageRisiko
    ? `${parseRiskNumValueToLabel(
        depotDetails.anlageRisiko
      )} (${depotDetails?.anlageRisiko}) `
    : "";

  const isLoading =
    bundledLoadingState([
      depotDetailsLoadingState,
      depotInvestmentSettingsLoadingState,
      cancellationDateLoadingState
    ]) === "loading";

  if (isLoading || !logoTheme) return <DepotEntityItemSkeleton />;

  const getNameByKey = (key: string): string => {
    if (key === "premium") {
      return "Premium";
    }
    if (key === "werder") {
      return "Werder";
    }
    if (key === "anceka") {
      return "AnCeKa";
    }

    return "";
  };

  return (
    <StyledEntityPanel theme={theme} appModeTheme={appModeTheme}>
      <div style={{ position: "absolute", right: "0px", top: 0, padding: 10 }}>
        <Image
          alt="Logo"
          style={{ objectFit: "contain", objectPosition: "top center" }}
          src={logoTheme.assets.logoImage.replace(
            "logo.png",
            `${
              appModeTheme?.colorSchema === "light" ? "lightmode" : "darkmode"
            }/logo.png`
          )}
          height={90}
          width={120}
        />
      </div>
      <span style={{ marginBottom: 10, color: logoTheme.palette.primary }}>
        {getNameByKey(logoTheme.key) || <>&nbsp;</>}
      </span>
      <h2>{depotTypeLabel}</h2>
      <p>
        {depot.depotOwnerFullName !== "null null"
          ? depot.depotOwnerFullName
          : "Unbenannt"}{" "}
        &ndash; {depot.displayName}
      </p>

      {!depot.juristic && (
        <>
          <Row justify="space-between">
            <Col xs={20}>
              {" "}
              <p>Dieses Depot befindet sich noch im Onboarding.</p>
            </Col>
            <Col xs={4} style={{ textAlign: "right", display: "flex" }}>
              &nbsp;{" "}
            </Col>
          </Row>
          <Button
            className="icon-button"
            onClick={() => onSelect(depot.id)}
            type="link"
          >
            <RiArrowRightCircleFill color={theme.palette.primary} />
          </Button>
        </>
      )}

      {cancellationDate && (
        <>
          <Row justify="space-between">
            <Col xs={20}>
              {" "}
              <p>
                Sie haben das Depot am{" "}
                {moment(cancellationDate).format("DD.MM.yy")} gekündigt.
              </p>
            </Col>
            <Col xs={4} style={{ textAlign: "right", display: "flex" }}>
              &nbsp;{" "}
            </Col>
          </Row>{" "}
          <Button
            className="icon-button"
            onClick={() => onSelect(depot.id)}
            type="link"
          >
            <RiArrowRightCircleFill
              color={theme.palette.primary}
              style={{ fontSize: 48 }}
            />
          </Button>
        </>
      )}

      {depot.juristic && !cancellationDate && (
        <>
          <Row justify="space-between">
            <Col>
              <span className="value-top">&nbsp;</span>
              <span className="value">
                {parseNumberToCurrencyString(depot?.balance ?? 0, 2)}
              </span>
              <p className="label">Gesamtwert</p>
            </Col>
            <Col>
              <span className="value-top">
                {(depotDetails?.currentPerformance || 0) >= 0 ? "+" : ""}
                {(depotDetails?.currentPerformance ?? 0)
                  .toFixed(2)
                  .replace(".", ",")}{" "}
                %
              </span>
              <span className="value">
                {parseNumberToCurrencyString(depotDetails?.wertzuwachs ?? 0, 2)}
              </span>

              <p className="label">Performance</p>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col xs={20}>
              <p>
                <b className="label">Anlagepräferenz</b>
                <br />
                <span style={{ opacity: 0.5 }}>
                  {riskLabel}
                  {depotSettingsLabel ? `| ${depotSettingsLabel}` : ""}
                </span>
              </p>
            </Col>
          </Row>
          <Button
            className="icon-button"
            onClick={() => onSelect(depot.id)}
            type="link"
          >
            <RiArrowRightCircleFill
              color={theme.palette.primary}
              style={{ fontSize: 48 }}
            />
          </Button>
        </>
      )}
    </StyledEntityPanel>
  );
};

export default DepotEntityItem;
