import isUnderageDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import type DepotDto from "smavesto.core/lib/types/dto/depot/DepotDto";

import FinishJuniorDepotMessage from "./FinishJuniorDepotMessage";
import FinishRegularDepotMessage from "./FinishRegularDepotMessage";

interface FinishDepotHintSwitchProps {
  depot: DepotDto;
}

const FinishDepotHintSwitch: React.FunctionComponent<
  FinishDepotHintSwitchProps
> = ({ depot }) => {
  if (isUnderageDepot(depot)) return <FinishJuniorDepotMessage />;

  return <FinishRegularDepotMessage />;
};

export default FinishDepotHintSwitch;
