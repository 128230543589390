import { atom, selector, selectorFamily } from "recoil";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { loggedInUserUriState, userUriState } from "@states/auth.state";
import type OnboardingDto from "smavesto.core/lib/types/dto/onboarding/OnboardingDataDto";

import moment from "moment";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import isLegitimatedForDepotOpening from "smavesto.core/lib/utils/typeguards/isLegitimatedForDepotOpening";
import { bankDetailsState } from "./bank-details.state";
import {
  currentDepotOnboardingState,
  currentDepotState
} from "./current.depot.state";
import { activeOnboardingDepotState } from "./depot.state";
import { getRequestState, useRefreshState } from "./request.state";
import { riskYieldState } from "./risk-yield.state";

export const loggedInUserOnboardingState = atom({
  key: "loggedInUserOnboardingState",
  default: selector({
    key: "loggedInUserOnboardingState/Default",
    get: async ({ get }) => {
      const userUri = get(userUriState("loggedInUser"));

      if (!userUri) return undefined;

      get(getRequestState("onboarding", userUri));

      const result = await smavestoCore.services.onboarding.getOnboardingData(
        userUri
      );

      if (isApiErrorResponse(result)) return undefined;

      return result;
    }
  })
});

export const activeOnboardingState = atom({
  key: "loggedInUserOnboardingState",
  default: selector({
    key: "loggedInUserOnboardingState/Default",
    get: async ({ get }) => {
      const userUri = get(userUriState("loggedInUser"));

      if (!userUri) return undefined;

      get(getRequestState("onboarding", userUri));

      const result = await smavestoCore.services.onboarding.getOnboardingData(
        userUri
      );

      if (isApiErrorResponse(result)) return undefined;

      return result;
    }
  })
});

export const activeOnboardingIsUnderageOnboardingState = atom({
  key: "activeOnboardingIsUnderageOnboarding",
  default: selector({
    key: "activeOnboardingIsUnderageOnboarding/Default",
    get: async ({ get }) => {
      const activeOnboardingDepot = get(activeOnboardingDepotState);

      if (!activeOnboardingDepot) return false;

      return activeOnboardingDepot.customerDepotRelationshipType === "Parent";
    }
  })
});

export const displayMonthlyChargeSelectionInActiveOnboardingState = atom({
  key: "displayMonthlyChargeSelectionInActiveOnboardingState",
  default: selector({
    key: "displayMonthlyChargeSelectionInActiveOnboardingState/Default",
    get: async ({ get }) => {
      const riskYield = get(riskYieldState("activeOnboardingOwner"));

      if (!riskYield) return true;

      return riskYield.monatlicheEinzahlung > 0;
    }
  })
});

export const paymentChargeDateIsRequiredButNotEnteredState = atom({
  key: "paymentChargeDateIsRequiredButNotEnteredState",
  default: selector({
    key: "paymentChargeDateIsRequiredButNotEnteredState/Default",
    get: async ({ get }) => {
      const shouldAskForChargeDate = get(
        displayMonthlyChargeSelectionInActiveOnboardingState
      );

      const bankDetails = get(bankDetailsState("currentDepotOwner"));

      if (!bankDetails) return false;

      return bankDetails.payment.paymentChargeDate;
    }
  })
});

export const allocationHintReadStateForCurrentDepotState = atom({
  key: "allocationHintReadStateForCurrentDepotState",
  default: selector({
    key: "allocationHintReadStateForCurrentDepotState/Default",
    get: async ({ get }) => {
      const userUri = get(loggedInUserUriState);
      const currentDepot = get(currentDepotState);
      const onboardingData = get(currentDepotOnboardingState);

      if (!currentDepot || !userUri) return undefined;

      const readAllocationStatus =
        await smavestoCore.services.keyValueStore.getDepotAllocationHintReadState(
          userUri,
          currentDepot.id
        );

      if (
        !!onboardingData?.accountOpeningSendToBaader &&
        moment(onboardingData.accountOpeningSendToBaader).isBefore(
          moment(new Date()).add(-2, "weeks")
        )
      )
        return onboardingData.accountOpeningSendToBaader;

      const result = successOrUndefined(readAllocationStatus);

      if (result === "empty") return undefined;

      return successOrUndefined(readAllocationStatus);
    }
  })
});

export const onboardingState = selectorFamily({
  key: "onboarding",
  get:
    (userUri: string) =>
    async ({ get }) => {
      get(getRequestState("onboarding", userUri));

      const result = await smavestoCore.services.onboarding.getOnboardingData(
        userUri
      );

      if (isApiErrorResponse(result)) return undefined;

      return result;
    }
});

export const onboardingByEnvironmentState = selectorFamily({
  key: "onboardingByEnvironment",
  get:
    (environment: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(environment));

      if (!userUri) return undefined;

      get(getRequestState("onboarding", userUri));

      const result = await smavestoCore.services.onboarding.getOnboardingData(
        userUri
      );

      return successOrUndefined<OnboardingDto>(result);
    }
});

export const userIsLegitimatedState = selectorFamily({
  key: "userIsLegitimated",
  get:
    (environment: StateEnvironmentUser) =>
    async ({ get }) => {
      const onbordingData = get(onboardingByEnvironmentState(environment));

      if (!onbordingData) return false;

      return isLegitimatedForDepotOpening(onbordingData);
    }
});

export const onboardingDocumentsState = selectorFamily({
  key: "onboardingDocuments",
  get:
    (environment: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(environment));

      if (!userUri) return [];

      get(getRequestState("onboardingDocuments", userUri));

      const onboardingDocumentsResponse =
        await smavestoCore.services.download.getOnboardingDocuments(userUri);

      if (isApiErrorResponse(onboardingDocumentsResponse)) return [];

      return onboardingDocumentsResponse;
    }
});

export const useRefreshOnboarding = (appMode: StateEnvironmentUser) =>
  useRefreshState("onboarding", appMode);

export const useRefreshOnboardingDocuments = (appMode: StateEnvironmentUser) =>
  useRefreshState("onboardingDocuments", appMode);

export const onboardingUserCompletedSummaryPageState = atom<boolean>({
  key: "onboardingUserCompletedSummaryPage",
  default: false
});
