import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { useSafeLogout } from "@page-components/onboarding/hooks";
import { loggedInUserOnboardingState } from "@states/onboarding.state";
import type OnboardingChapter from "smavesto.core/lib/types/dto/onboarding/OnboardingChapter";

export const useOnboardingLayout = () => {
  const [, onboarding] = useAsyncSelector(loggedInUserOnboardingState);

  const logout = useSafeLogout("/login?hide=app-notification");

  const completedChapters = onboarding?.completedChapters || [
    "ANLAGEWUNSCH",
    "ZUGANGSDATEN"
  ];
  const disabledChapters: OnboardingChapter[] = ["ZUGANGSDATEN"];
  const progress = onboarding?.progressInPercent
    ? Math.round(onboarding.progressInPercent)
    : 0;

  return {
    logout,
    completedChapters,
    disabledChapters,
    progress
  };
};
