import { Col, Row } from "antd";
import { useRouter } from "next/router";

import CancellationTooltip from "@components/form/tooltips/CancellationTooltip";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { currentDepotState } from "@states/current.depot.state";
import {
  activeOnboardingDepotState,
  userIsSecondGuardianState
} from "@states/depot.state";
import { onboardingByEnvironmentState } from "@states/onboarding.state";
import { SButton } from "@styled-components/form/SButtons";

import { getNextStepToUnderageOnboarding } from "../page-container/SecureLayout/onboardingSteps";
import OuterHint from "./OuterHint";

function FinishJuniorDepotMessage() {
  const { push } = useRouter();

  const [, onboarding] = useAsyncSelector(
    onboardingByEnvironmentState("currentDepotOwner")
  );

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const [, activeOnboardingDepot] = useAsyncSelector(
    activeOnboardingDepotState
  );

  const [, userIsSecondGuardian] = useAsyncSelector(userIsSecondGuardianState);

  const underageOnboardingNextStep = getNextStepToUnderageOnboarding(
    onboarding?.completedChapters ?? [],
    userIsSecondGuardian === true,
    true
  );

  if (!underageOnboardingNextStep) return null;

  // if the user has started multiple onboarding workflows they should completed first
  const onboardingIsNotActiveOnboarding =
    activeOnboardingDepot?.id !== currentDepot?.id;

  return (
    <OuterHint title="Junior Depot abschließen">
      {onboardingIsNotActiveOnboarding ? (
        <p>
          Bitte schließen Sie erst das Onboarding für den zuletzt gestarteten
          Onboarding-Prozess ab.
        </p>
      ) : (
        <p>
          Bitte schließen Sie die Erstellung des Junior Depots ab, indem Sie auf
          &quot;Fortsetzen&quot; drücken.
        </p>
      )}
      <Row justify="center" gutter={12} style={{ marginTop: 12 }}>
        <Col lg={7} xs={24}>
          <CancellationTooltip>
            <SButton
              style={{ width: "100%", marginTop: 12 }}
              onClick={() => push(underageOnboardingNextStep)}
              className="reset"
              disabled={onboardingIsNotActiveOnboarding}
            >
              Fortsetzen
            </SButton>
          </CancellationTooltip>
        </Col>
      </Row>
    </OuterHint>
  );
}

export default FinishJuniorDepotMessage;
