import { useWindowDimensions } from "@hooks/useWindowDimensions";
import { BREAK_POINTS } from "../deviceBreakpoints";

function useIsMobile(): boolean {
  const dimensions = useWindowDimensions();

  return dimensions.width < BREAK_POINTS.lg;
}

export default useIsMobile;
