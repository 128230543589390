import { Row } from "antd";
import styled from "styled-components";

import { BREAK_POINTS } from "@theme/deviceBreakpoints";

export const SDashboardContentWrapper = styled(Row)`
  padding: 0 20px;

  @media (min-width: ${BREAK_POINTS.sm}px) {
    padding: 0 40px;
  }
`;
