import React from "react";

import { pkg } from "@src/configs/pkg.config";
import type { ICompBaseProps } from "@src/interfaces/jsx.interface";
import Head from "next/head";

interface HtmlMetaProps extends ICompBaseProps {
  title: React.ReactNode;
}

const HtmlMeta: React.FunctionComponent<HtmlMetaProps> = ({ title }) => {
  const siteName = " - SMAVESTO";

  return (
    <Head>
      <meta name="version" content={pkg.version} />
      <meta name="author" content="Smavesto GmbH" />
      <title>
        {title}
        {siteName}
      </title>
    </Head>
  );
};

export default HtmlMeta;
