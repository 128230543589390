import { Button } from "antd";
import { Suspense, useState } from "react";

import {
  currentDepotInvestmentPlanState,
  currentDepotUserUriState
} from "@states/current.depot.state";
import { useRefreshCurrentDepotInvestmentPlan } from "@states/investment-plan.state";
import {
  currentDirectDepositIDState,
  useRefreshDirectDeposits
} from "@states/simulate.state";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { useRefreshState } from "@src/state/request.state";
import { SH2, SSection } from "@styled-components/STags";
import CancellationTooltip from "../../form/tooltips/CancellationTooltip";
import { DirectDepositSelect } from "./DirectDepositSelect";

// Refine method and timing to update dependencies after simulateOrderIncome request
export function DirectDeposit() {
  const [, userUri] = useAsyncSelector(currentDepotUserUriState);

  const [, currentDirectDepositID] = useAsyncSelector(
    currentDirectDepositIDState
  );

  useAsyncSelector(currentDepotInvestmentPlanState);

  const refreshDepots = useRefreshState("depots", "loggedInUser");

  const refreshInvestmentPlan =
    useRefreshCurrentDepotInvestmentPlan("currentDepotOwner");

  const refreshDirectDeposits = useRefreshDirectDeposits("currentDepotOwner");

  const [loading, setLoading] = useState(false);

  const onSimulateOrderIncome = async () => {
    if (!userUri || !currentDirectDepositID) return;

    setLoading(true);

    await smavestoCore.services.simulate.geldEingang(
      currentDirectDepositID,
      userUri
    );

    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, 40000));

    refreshDepots();
    refreshInvestmentPlan();
    refreshDirectDeposits();

    setLoading(false);
  };

  return (
    <Suspense fallback={<div>Bitte warten - Daten werden geladen</div>}>
      <SSection>
        <SH2 fontSize="14px" fontWeight={700}>
          Einzahlung
        </SH2>
        <DirectDepositSelect />

        <CancellationTooltip>
          <Button
            style={{ width: 300 }}
            loading={loading}
            onClick={onSimulateOrderIncome}
          >
            Ordereingang simulieren
          </Button>
        </CancellationTooltip>
      </SSection>
    </Suspense>
  );
}
