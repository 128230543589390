import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";

import {
  activeOnboardingState,
  depotsState,
  userIsSecondGuardianState
} from "@states/depot.state";
import { appModeState } from "@states/global.state";
import {
  activeOnboardingIsUnderageOnboardingState,
  onboardingByEnvironmentState
} from "@states/onboarding.state";
import { DepotTypes } from "@utils/constants/DepotTypes";
import type { RecoilLoadingStateDetails } from "@utils/recoil/bundledLoadingState";
import { detailedBundledLoadingState } from "@utils/recoil/bundledLoadingState";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { loggedInUserUriState } from "@states/auth.state";
import type OnboardingDto from "smavesto.core/lib/types/dto/onboarding/OnboardingDataDto";
import {
  getNextStepToOnboarding,
  getNextStepToUnderageOnboarding
} from "./onboardingSteps";

export const useSecureLayout = (): [boolean, RecoilLoadingStateDetails] => {
  const { push, pathname, query } = useRouter();

  const setAppMode = useSetRecoilState(appModeState);

  const [loggedInUserUriLoadingState, loggedInUserUri] =
    useAsyncSelector(loggedInUserUriState);

  const [depotsLoadingState, depots] = useAsyncSelector(depotsState);

  const [
    activeOnboardingIsUnderageOnboardingLoadingState,
    activeOnboardingIsUnderageOnboarding
  ] = useAsyncSelector(activeOnboardingIsUnderageOnboardingState);

  const [onboardingUnderageStateStatus, onboardingUnderage] = useAsyncSelector(
    onboardingByEnvironmentState("activeOnboardingOwner")
  );
  const [accessToDashboard, setAccessToDashboard] = useState(false);

  const [userIsSecondGuardianLoadingState, userIsSecondGuardian] =
    useAsyncSelector(userIsSecondGuardianState);

  const bundledState = detailedBundledLoadingState([
    activeOnboardingIsUnderageOnboardingLoadingState,
    depotsLoadingState,
    onboardingUnderageStateStatus,
    userIsSecondGuardianLoadingState,
    loggedInUserUriLoadingState
  ]);

  // Check if all async states are loaded
  const statesAreLoading = bundledState.state === "loading";

  const redirect = useCallback(
    (onboardingState: OnboardingDto | undefined) => {
      if (statesAreLoading === true) return;

      const numberOfDepots = depots?.length || 0;

      // Check if the user has depots
      const hasDepots = numberOfDepots > 0;

      // Check if the user has more than one depot
      const hasMultipleDepots = numberOfDepots > 1;

      // Check if the url equals the basepath (e.g. app.smavesto.de/)
      const isBasePath = pathname === "/";

      if (isBasePath && hasDepots) {
        if (
          query.depotType === DepotTypes.UNDERAGE ||
          activeOnboardingIsUnderageOnboarding === true
        ) {
          setAppMode("underage");

          if (onboardingUnderage) {
            if (
              onboardingUnderage.onboardingCompleted ||
              (userIsSecondGuardian === false &&
                onboardingUnderage.completedChapters.indexOf(
                  "DOKUMENTE_GESETZLICHER_VERTRETER_1"
                ) > -1)
            ) {
              push("/dashboard/depot");
              setAccessToDashboard(true);
              return;
            }

            const onboardingPath = getNextStepToUnderageOnboarding(
              onboardingUnderage.completedChapters,
              userIsSecondGuardian === true,
              true
            );

            // We dont redirect the user if he has multiple depots
            // The technically required depots are not going to be counted here!
            if (!hasMultipleDepots && onboardingPath) push(onboardingPath);

            setAccessToDashboard(true);
            return;
          }
        } else {
          if (
            !onboardingState ||
            onboardingState?.onboardingCompleted ||
            !onboardingState?.directAccess
          ) {
            push("/dashboard/depot");
            setAccessToDashboard(true);
            return;
          }

          const onboardingPath = getNextStepToOnboarding(
            onboardingState.completedChapters
          );
          push(onboardingPath);
          return;
        }

        return;
      }
      if (!hasDepots && loggedInUserUri) {
        push("/dashboard/user-has-no-depot");
        return;
      }

      if (pathname.includes("/dashboard")) setAccessToDashboard(true);
    },
    [
      push,
      pathname,
      setAppMode,
      statesAreLoading,
      query,
      depots?.length,
      onboardingUnderage,
      userIsSecondGuardian,
      bundledState
    ]
  );

  useAsyncSelector(activeOnboardingState, redirect);

  return [accessToDashboard, bundledState];
};
