import { useCancelation } from "@hooks/useCancelation";
import { CANCELLATION_INFO } from "@page-components/dashboard/Profile/cancellation/constants";

import type { CustomTooltipProps } from "./CustomTooltip";
import CustomTooltip from "./CustomTooltip";

const CancellationTooltip: React.FunctionComponent<CustomTooltipProps> = ({
  placement,
  disabled,
  title,
  style,
  children
}) => {
  const { disableControl } = useCancelation("currentDepotOwner");

  const cancelationTitle = disableControl
    ? CANCELLATION_INFO.tooltipTitle
    : undefined;

  const disabledForChildren = disabled || disableControl;

  if (disabledForChildren)
    return (
      <CustomTooltip
        title={title || cancelationTitle}
        placement={placement}
        style={style}
        disabled={disabledForChildren}
      >
        {children}
      </CustomTooltip>
    );

  return children;
};

export default CancellationTooltip;
