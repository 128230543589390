import type { FC } from "react";
import { useEffect, useState } from "react";

import { FormGroup } from "@components/form/inputs/form-fields/FormGroup";
import { SFormItem } from "@styled-components/form/SFormItems";
import { SInput } from "@styled-components/form/SInputs";

import { RULES } from "../constants";

const MaxLengthFirstLastName = 70;

interface IProps {
  presetFirstName: string;
  presetLastName: string;
}

export const CustodyUsername: FC<IProps> = (props: IProps) => {
  const [firstnameLength, setFirstnameLength] = useState(0);
  const [lastnameLength, setLastnameLength] = useState(0);

  useEffect(() => {
    setFirstnameLength(props.presetFirstName?.length || 0);
    setLastnameLength(props.presetLastName?.length || 0);
  }, [props.presetFirstName, props.presetLastName]);

  return (
    <FormGroup spans={[{ md: 12 }, { md: 12 }]}>
      <div>
        <SFormItem
          name="parentUser2FirstName"
          label="Vorname des weiteren gesetzlichen Vertreters*"
          rules={RULES.firstName}
        >
          <SInput
            bordered={false}
            maxLength={MaxLengthFirstLastName - lastnameLength}
            onChange={event => setFirstnameLength(event.target.value.length)}
          />
        </SFormItem>
        <div className="field__addition">
          Geben Sie hier auch eventuelle weitere Vornamen an.
        </div>
      </div>

      <SFormItem
        name="parentUser2LastName"
        label="Nachname des weiteren gesetzlichen Vertreters*"
        rules={RULES.lastName}
      >
        <SInput
          bordered={false}
          maxLength={MaxLengthFirstLastName - firstnameLength}
          onChange={(event: any) =>
            setLastnameLength(event.target.value.length)
          }
        />
      </SFormItem>
    </FormGroup>
  );
};
