import { Col, Row } from "antd";
import React from "react";

import { SCustomDivider } from "@styled-components/SCustomDivider";

import InfoCollapseBox from "../dashboard/InfoCollapseBox";

interface BasicInfoBoxProps {
  title: string;
  icon?: React.ReactNode;
  actions: React.ReactNode[];
  text?: string | React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
}

const BasicInfoBox: React.FunctionComponent<BasicInfoBoxProps> = ({
  title,
  icon,
  text,
  actions,
  children
}) => {
  return (
    <InfoCollapseBox
      style={{ margin: 0, marginBottom: "2rem" }}
      title={title}
      icon={icon}
    >
      {text && <p>{text}</p>}
      {children}
      {actions.length > 0 ?? (
        <SCustomDivider style={{ margin: "10px auto 20px", width: "80%" }} />
      )}
      <Row justify="center" align="middle">
        {actions.map(action => (
          <Col style={{ color: "inherit", marginRight: 10 }}>{action}</Col>
        ))}
      </Row>
    </InfoCollapseBox>
  );
};

export default BasicInfoBox;
