/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PlusCircleFilled, UnorderedListOutlined } from "@ant-design/icons";
import DepotNavigation from "@components/layout/navigation/DepotNavigation";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import {
  currentDepotIDState,
  currentDepotState
} from "@states/current.depot.state";
import { depotsState } from "@states/depot.state";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";

import useNewDepotModal from "@src/hooks/dashboard/useNewDepotModal";
import { Button, Row, Skeleton } from "antd";
import { useContext, useState } from "react";
import { useSetRecoilState } from "recoil";

import LightModeSwitch from "@src/components/layout/lightmode/LightModeSwitch";
import { useSafeLogout } from "@src/page-components/onboarding/hooks";
import { ThemeContext } from "@src/pages/_app";
import useIsMobile from "@src/theme/hook/useIsMobile";
import type { DepotDto } from "smavesto.core";
import SelectDepotModal from "../modals/settings/SelectDepotModal";
import styles from "./depotTabMenuStyles.module.less";

const DepotTabMenu: React.FunctionComponent = () => {
  const { setSelectNewDepotModalOpen, multiContextHolder } = useNewDepotModal();

  const [, depots] = useAsyncSelector(depotsState);

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const setCurrentDepotID = useSetRecoilState(currentDepotIDState);

  const [isOverviewModalOpen, setOpenOverviewModal] = useState<boolean>(false);

  const hasMoreThanOneDepot = depots && depots.length > 1;

  const favoriteDepots = depots?.slice(0, 1) || [];

  const { theme } = useContext(ThemeContext);

  const isMobile = useIsMobile();

  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>();

  const onLogout = useSafeLogout();

  const formatDisplayName = (depot: DepotDto) => {
    return `${
      depot.depotOwnerFullName.trim() !== "null null"
        ? `${depot.depotOwnerFullName}`
        : ""
    } (${depot.displayName.replace("Smavesto Test-Depot", "Depot")})`;
  };

  const depotsToShow =
    currentDepot && favoriteDepots.indexOf(currentDepot) > -1
      ? favoriteDepots
      : currentDepot
      ? [currentDepot]
      : favoriteDepots;

  if (isMobile)
    return (
      <nav className={styles["wrap-main-navigation"]}>
        <Row className={styles["wrap-main-depots-navigation"]}>
          <Button
            style={{ marginLeft: 10, transform: "translateY(2px)" }}
            type="text"
            onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}
            icon={
              burgerMenuOpen ? (
                <RxCross2 size={22} color="white" />
              ) : (
                <GiHamburgerMenu size={22} color="white" />
              )
            }
          />
          {!depotsToShow.length && (
            <div key={1} className={styles["wrap-main-depot-navigation-item"]}>
              <Skeleton.Button active style={{ height: 24, width: 160 }} />
            </div>
          )}
          {depotsToShow.map(depot => (
            <div
              key={depot.id}
              className={
                depot.id === currentDepot?.id
                  ? styles["wrap-main-depot-navigation-item-active"]
                  : styles["wrap-main-depot-navigation-item"]
              }
              onClick={() => setCurrentDepotID(depot.id)}
            >
              {formatDisplayName(depot)}
            </div>
          ))}
          <div style={{ marginLeft: "auto" }}>
            <LightModeSwitch />
          </div>
        </Row>
        <div
          style={{
            maxHeight: burgerMenuOpen ? 10000 : 0,
            height: "fit-content",
            transition: "all 0.4s ease",
            overflow: "hidden",
            marginTop: 2,
            borderTop: `1px solid ${theme.palette.primary}`
          }}
        >
          <div style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
            {hasMoreThanOneDepot && (
              <Button
                icon={<UnorderedListOutlined />}
                type="text"
                style={{ color: "white" }}
                onClick={() => setOpenOverviewModal(true)}
                className={styles["wrap-main-depot-navigation-item-overview"]}
              >
                Übersicht Depots
              </Button>
            )}
            <Button
              onClick={() => setSelectNewDepotModalOpen(true)}
              icon={<PlusCircleFilled />}
              type="link"
              style={{ color: "white" }}
              className={styles["wrap-main-depot-navigation-item-overview"]}
            >
              Weiteres Depot anlegen
            </Button>
          </div>
          <DepotNavigation />
        </div>

        <SelectDepotModal
          onClose={() => setOpenOverviewModal(false)}
          isOpen={isOverviewModalOpen}
        />
        {multiContextHolder}
      </nav>
    );

  return (
    <nav className={styles["wrap-main-navigation"]}>
      <Row className={styles["wrap-main-depots-navigation"]}>
        {!depotsToShow.length && (
          <div key={1} className={styles["wrap-main-depot-navigation-item"]}>
            <Skeleton.Button active style={{ height: 24, width: 160 }} />
          </div>
        )}
        {depotsToShow.map(depot => (
          <div
            key={depot.id}
            className={
              depot.id === currentDepot?.id
                ? styles["wrap-main-depot-navigation-item-active"]
                : styles["wrap-main-depot-navigation-item"]
            }
            onClick={() => setCurrentDepotID(depot.id)}
          >
            {formatDisplayName(depot)}
          </div>
        ))}
        <Button
          onClick={() => setSelectNewDepotModalOpen(true)}
          icon={<PlusCircleFilled />}
          type="link"
          style={{ color: "white" }}
          className={styles["wrap-main-depot-navigation-item-overview"]}
        >
          Weiteres Depot anlegen
        </Button>
        {hasMoreThanOneDepot && (
          <Button
            icon={<UnorderedListOutlined />}
            type="text"
            style={{ color: "white" }}
            onClick={() => setOpenOverviewModal(true)}
            className={styles["wrap-main-depot-navigation-item-overview"]}
          >
            Übersicht Depots
          </Button>
        )}
        <div style={{ margin: "10px 28px 10px auto" }}>
          <Button
            type="primary"
            style={{
              borderRadius: 50,
              border: 0,
              outline: 0,
              fontSize: 12,
              padding: "3px 24px",
              color: theme.palette.primaryTextColor
            }}
            onClick={onLogout}
          >
            Logout
          </Button>
        </div>
      </Row>
      <DepotNavigation />
      <SelectDepotModal
        onClose={() => setOpenOverviewModal(false)}
        isOpen={isOverviewModalOpen}
      />
      {multiContextHolder}
    </nav>
  );
};

export default DepotTabMenu;
