import { atom, selector, selectorFamily } from "recoil";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type { StateEnvironmentUser } from "@src/models/types/StateEnvironment";
import type { ApiErrorResponse, DocumentTypeEndpointDto } from "smavesto.core";
import type CountryDto from "smavesto.core/lib/types/dto/common/CountryDto";
import type DocumentTypeListParent from "smavesto.core/lib/types/dto/download/DocumentTypeListParentDto";
import getDocumentsAndDownLoadLinksForSummaryStep from "smavesto.core/lib/utils/onboarding/getDocumentsAndDownLoadLinksForSummaryStep";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";

import { userUriState } from "./auth.state";
import { getRequestState } from "./request.state";

export const countriesQuery = selector({
  key: "countries",
  get: async () =>
    successOrUndefined<CountryDto[]>(
      await smavestoCore.services.common.getCountries()
    ) || []
});

export const nationalitiesQuery = selector({
  key: "nationalities",
  get: async () =>
    successOrUndefined(await smavestoCore.services.common.getNationalities()) ||
    []
});

export const docTypesQuery = selector({
  key: "docTypes",
  get: async () =>
    successOrUndefined(await smavestoCore.services.documents.getDocumentTypes())
});

export const summaryPageDocumentsState = selectorFamily({
  key: "summaryPageDocumentsState",
  get:
    (type: StateEnvironmentUser) =>
    async ({ get }) => {
      const userUri = get(userUriState(type));

      getRequestState("onboardingDocuments");

      if (!userUri) return undefined;

      return successOrUndefined<DocumentTypeEndpointDto>(
        (await smavestoCore.services.documents.getDocumentTypesByUserUri(
          userUri
        )) || []
      );
    }
});

export const summaryPageDocumentsForViewportState = selectorFamily({
  key: "summaryPageDocumentsForViewportState",
  get:
    (
      viewport:
        | "DEFAULT"
        | "JUNIOR_SOLE_CUSTODY"
        | "JUNIOR_JOINT_CUSTODY_PARENT_1"
        | "JUNIOR_JOINT_CUSTODY_PARENT_2"
    ) =>
    async ({ get }) => {
      const userUri = get(userUriState("activeOnboardingOwner"));

      getRequestState("onboardingDocuments");

      if (!userUri) return undefined;

      const downloadLinks = await getDocumentsAndDownLoadLinksForSummaryStep(
        viewport,
        userUri,
        smavestoCore
      );

      return downloadLinks;
    }
});

export const docTypesParent1Query = selector({
  key: "docTypesParent1",
  get: async () =>
    successOrUndefined(
      await smavestoCore.services.download.getDocumentTypesForParentUser1()
    )
});

export const docTypesParent2Query = selector({
  key: "docTypesParent2",
  get: async () =>
    successOrUndefined(
      await smavestoCore.services.download.getDocumentTypesForParentUser2()
    )
});

export const filteredDocTypes = selectorFamily({
  key: "filteredDocTypes",
  get:
    (filter: DocumentTypeListParent) =>
    ({ get }) => {
      const docTypes = get(docTypesQuery);

      return docTypes ? docTypes[filter] : [];
    }
});

/**
 * Returns the document types state for a specific onboarding route.
 * @constructor
 * @param {'default' | 'underage'} onboardingType
 * @param {boolean?} isSecondGuardian
 */
export const getDocumentsStateForOnboarding = (
  onboardingType: "default" | "underage",
  isSecondGuardian?: boolean
) => {
  if (onboardingType === "underage" && !isSecondGuardian)
    return docTypesParent1Query;
  if (onboardingType === "underage" && isSecondGuardian)
    return docTypesParent2Query;

  return docTypesQuery;
};

export interface GlobalErrorInfo {
  error?: boolean;
  title?: string;
  bodyText?: string;
  showDetailedError?: boolean;
  apiErrorResponse?: ApiErrorResponse;
  sentryErrorCode?: string;
}
export const globalErrorState = atom<GlobalErrorInfo | undefined>({
  key: "GlobalError",
  default: undefined
});
