import React from "react";

export const parseRiskNumValueToLabel = (value: number) => {
  switch (true) {
    case value >= 40:
      return "Spekulativ";
    case value >= 35:
      return "Spekulativ";
    case value >= 25:
      return "Risikobewusst";
    case value >= 15:
      return "Gewinnorientiert";

    case value >= 7:
      return "Konservativ";

    case value < 7:
      return "Sicherheitsorientiert";

    default:
      return "Konservativ";
  }
};

export const parseStrategyNumValueToLabel = (value: string) => {
  switch (true) {
    case value === "NO_RISK":
      return "Altersvorsorge";
    case value === "MEDIUM_RISK":
      return "Allgemeine Vermögensbildung und -optimierung";
    case value === "HIGH_RISK":
      return "Überproportionale Teilnahme an Kursveränderungen";
    default:
      throw new Error("Illegal State: Unknown risk type.");
  }
};

export const getTextForInfo = (
  title:
    | "Konservativ"
    | "Gewinnorientiert"
    | "Risikobewusst"
    | "Spekulativ"
    | "Sicherheitsorientiert",
  isUnderage: boolean
): React.ReactNode => {
  const disclaimer =
    "Der Maximum Drawdown stellt ein Instrument zur Risikomessung dar. Er gibt den Maximalverlust an, den ein Anleger innerhalb eines Betrachtungszeitraumes hätte erleiden können (historische Betrachtungsweise). Dabei wird angenommen, dass zum Höchststand gekauft und zum Tiefststand verkauft wurde. Der tatsächliche Verlust kann höher als der max. Drawdown ausfallen.";

  const labels = {
    Konservativ:
      "Sie sind bereit bei Ihrer Geldanlage geringe Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) geringe Währungsrisiken in Kauf zu nehmen.",
    Gewinnorientiert:
      "Sie stehen dem ausgeprägten Risiko und den somit überdurchschnittlichen Ertragschancen offen gegenüber. Dabei sind Sie bereit, mittlere Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) mittlere Währungsrisiken in Kauf zu nehmen.",

    Risikobewusst:
      "Sie stehen dem hohen Risiko und den somit hohen Ertragschancen offen gegenüber. Dabei sind Sie bereit, hohe Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) hohe Währungsrisiken in Kauf zu nehmen.",

    Spekulativ:
      "Sie stehen dem sehr hohen Risiko und den somit sehr hohen Ertragschancen offen gegenüber. Dabei sind Sie bereit, sehr hohe Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) sehr hohe Währungsrisiken in Kauf zu nehmen.",
    Sicherheitsorientiert:
      "Sie sind bereit bei Ihrer Geldanlage geringe Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) geringe Währungsrisiken in Kauf zu nehmen."
  };

  const labelsUnderage = {
    Konservativ:
      "Sie sind bereit bei der Geldanlage Ihres Kindes geringe Kurs-, Bonitäts- und (in Abhängigkeit der Anlagepräferenzen) geringe Währungsrisiken für Ihr Kind in Kauf zu nehmen.",
    Gewinnorientiert:
      " Sie stehen dem ausgeprägten Risiko und den somit überdurchschnittlichen Ertragschancen Ihres Kindes offen gegenüber. Dabei sind Sie bereit, mittlere Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) mittlere Währungsrisiken für Ihr Kind in Kauf zu nehmen.",

    Risikobewusst:
      "Sie stehen dem hohen Risiko und den somit hohen Ertragschancen Ihres Kindes offen gegenüber. Dabei sind Sie bereit, hohe Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) hohe Währungsrisiken für Ihr Kind in Kauf zu nehmen.",

    Spekulativ:
      "Sie stehen dem sehr hohen Risiko und den somit sehr hohen Ertragschancen Ihres Kindes offen gegenüber. Dabei sind Sie bereit, sehr hohe Kurs-, Bonitäts- und (in Abhängigkeit Ihrer Anlagepräferenzen) sehr hohe Währungsrisiken für Ihr Kind in Kauf zu nehmen.",
    Sicherheitsorientiert:
      "Sie sind bereit bei der Geldanlage Ihres Kindes geringe Kurs-, Bonitäts- und (in Abhängigkeit der Anlagepräferenzen) geringe Währungsrisiken für Ihr Kind in Kauf zu nehmen."
  };

  if (isUnderage)
    return (
      <>
        {labelsUnderage[title] ??
          "Sie sind bereit bei der Geldanlage für Ihr Kind geringe Kurs- und Bonitätsrisiken in Kauf zu nehmen."}
        <br />
        <br />
        {disclaimer}
      </>
    );
  return (
    <>
      {labels[title] ??
        "Sie sind bereit bei Ihrer Geldanlage geringe Kurs- und Bonitätsrisiken in Kauf zu nehmen."}
      <br />
      <br />
      {disclaimer}
    </>
  );
};
