import cx from "classnames";
import React from "react";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";
import HtmlMeta from "../../page-sekeleton/HtmlMeta";

interface PageWrapperProps extends ICompBaseProps {
  title: string;
  children: React.ReactNode;
}

const PageWrapper: React.FunctionComponent<PageWrapperProps> = ({
  className,
  style,
  children,
  title
}) => {
  return (
    <>
      <HtmlMeta title={title} />
      <div
        className={cx(`g-comp--${PageWrapper.displayName}`, className)}
        style={style}
      >
        <div
          style={{
            minHeight: "100vh",
            boxSizing: "border-box",
            paddingTop: 20
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default PageWrapper;
