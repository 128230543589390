import Image from "next/image";
import React, { useContext } from "react";
import styled from "styled-components";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";

import useIsSmavestoPremium from "@src/hooks/supplier/useIsSmavestoPremium";
import { ThemeContext } from "@src/pages/_app";
import { ActionButtonComponent } from "../../form/buttons/ActionButtonComponent";

interface ContactWidgetProps extends ICompBaseProps {
  title: string;
  content: string;
  disableButton?: boolean;
}
const StyledContact = styled.div`
  border-radius: 20px;

  padding: 20px 25px 0px;
  position: relative;
  /* display: flex;
  align-items: center;
  flex-direction: column; */

  button {
    margin: 30px 0;
  }

  && .title {
    width: 100%;

    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }

  && .link {
    text-decoration: underline;
    cursor: pointer;
  }

  && .link:hover {
    color: inherit;
  }

  && .content-wrapper {
    width: 80%;
    text-align: center;
  }

  && .content {
    font-size: 13px;
    line-height: 25px;
  }

  && .icon-wrapper span {
    font-size: 30px;
  }

  && .icon-title-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  && .content-image-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
  }

  && .image-wrapper {
    /* width: 50%; */
    margin-bottom: 20px;
  }

  && .image {
    border-radius: 50%;
    object-fit: cover;
  }
`;

const ContactWidget: React.FunctionComponent<ContactWidgetProps> = ({
  title,
  content,
  disableButton
}) => {
  const isPremium = useIsSmavestoPremium();

  const { appModeTheme, theme } = useContext(ThemeContext);

  return (
    <StyledContact
      style={{
        border: `0px solid ${
          appModeTheme?.colorSchema === "light"
            ? theme.palette.primary
            : appModeTheme?.secondLayerTextColor
        }`,
        color: appModeTheme?.secondLayerTextColor,
        background: appModeTheme?.secondLayerBaseBackgroundColor
      }}
    >
      <div className="content-image-wrapper">
        <div className="image-wrapper">
          <Image
            alt="contact"
            className="image"
            src="/assets/Tim_Bergmann_web.jpg"
            width={90}
            height={90}
          />
        </div>
        <div className="icon-title-wrapper">
          <div className="title">{title}</div>
        </div>
        <div className="content-wrapper">
          <div className="content">{content}</div>
        </div>
      </div>
      {disableButton ? (
        <div style={{ height: 40 }} />
      ) : (
        <a
          href={
            isPremium
              ? "https://www.smavesto.de/premium/kontakt"
              : "https://www.smavesto.de/kontakt/"
          }
          target="_blank"
          rel="noreferrer"
        >
          <ActionButtonComponent title="Kontakt" htmlType="action" />
        </a>
      )}
    </StyledContact>
  );
};

export default ContactWidget;
