import { atom } from "recoil";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import type InvestmentPlanDto from "smavesto.core/lib/types/dto/investment-plan/InvestmentPlanDto";
import { useRefreshState } from "./request.state";

export const useRefreshCurrentDepotInvestmentPlan = (
  environment: StateEnvironmentUser
) => useRefreshState("investmentPlan", environment);

export const transactionsFilterState = atom<
  | InvestmentPlanDto["investmentPlanHistory"][number]["investmentType"]
  | "ALL"
  | "SPARPLAN"
>({
  key: "transactionsFilter",
  default: "ALL"
});
