import { Select } from "antd";
import { useRecoilState, useResetRecoilState } from "recoil";

import { useEffect } from "react";
import { loggedInUserOnboardingState } from "@states/onboarding.state";
import {
  currentDirectDepositIDState,
  directDepositsState
} from "@states/simulate.state";
import { useAsyncSelector } from "@hooks/useAsyncSelector";

export function DirectDepositSelect() {
  const [, onboarding] = useAsyncSelector(loggedInUserOnboardingState);

  const [, directDeposits] = useAsyncSelector(directDepositsState);

  const resetDirectDepositId = useResetRecoilState(currentDirectDepositIDState);

  const [currentDirectDepositID, setCurrentDirectDepositID] = useRecoilState(
    currentDirectDepositIDState
  );

  useEffect(() => {
    resetDirectDepositId();
  }, [directDeposits]);

  const onDepositIDChange = (value: string) => setCurrentDirectDepositID(value);

  return (
    <Select
      onChange={onDepositIDChange}
      style={{ width: "100%", marginBottom: 12 }}
      disabled={!onboarding?.liveDepot}
      value={currentDirectDepositID}
    >
      {directDeposits?.map(d => (
        <Select.Option key={d.lastschriftId} value={d.lastschriftId}>
          {`${d.lastschriftValue} € (${d.lastschriftId})`}
        </Select.Option>
      ))}
    </Select>
  );
}
