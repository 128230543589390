import { useAsyncSelector } from "@hooks/useAsyncSelector";
import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { atomFamily, useSetRecoilState } from "recoil";
import { userUriState } from "./auth.state";

export type StateKey =
  | "inbox"
  | "riskyield"
  | "depot"
  | "onboardingDocuments"
  | "onboarding"
  | "depots"
  | "depotDetails"
  | "depotAllocations"
  | "cancellationDate"
  | "bankDetails"
  | "riskYield"
  | "knowledge"
  | "investmentSettings"
  | "custody"
  | "financialPosition"
  | "inboxToConfirm"
  | "profile"
  | "settings"
  | "customerAccreditation"
  | "customerSupplier"
  | "directDeposits"
  | "massOrdersRequest"
  | "investmentPlan"
  | "supplier"
  | "theme"
  | "giftcode"
  | "lightmode";

// This state controls when certain states are reloaded.
// The key is always the key of the state and the unique identifier (e.g. userUri, depotId, ...)
// The Key should always look like this {state.key}.{userUri}
export const requestIDState = atomFamily<number, string>({
  key: "requestStateID",
  default: 0
});

export function getStateIdentifier(
  key: StateKey,
  uniqueIdentifier?: string
): string {
  return `${key}.${uniqueIdentifier || "default"}`;
}

export function getRequestState(key: StateKey, uniqueIdentifier?: string) {
  return requestIDState(getStateIdentifier(key, uniqueIdentifier));
}

// This hook forces a reload of the state
export function useRefreshState(
  key: StateKey,
  environment: StateEnvironmentUser,
  uniqueIdentifier?: string
): () => void {
  const [, userUri] = useAsyncSelector(userUriState(environment));

  if (!userUri) {
    smavestoCore.debugLog("info", `There is no state to refresh. ${key}`);
  }

  const stateIdentifier = getStateIdentifier(
    key,
    uniqueIdentifier || userUri || environment
  );

  const setRequestID = useSetRecoilState(requestIDState(stateIdentifier));

  if (!userUri)
    smavestoCore.debugLog(
      "info",
      `could not reload state ${key} ${environment}`
    );

  const func = () => {
    smavestoCore.debugLog("info", `refresh ${stateIdentifier}`, "info");
    setRequestID(requestID => requestID + 1);
  };

  return func;
}
