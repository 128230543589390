import DepotTabMenu from "@components/dashboard/navigation/DepotTabMenu";
import type { ILayoutBaseProps } from "@src/interfaces/jsx.interface";
import DashboardSkeleton from "@src/page-components/dashboard/Depot/skeleton/DashboardSkeleton";

import useHintCollection from "../../hints/useHintCollection";
import HtmlMeta from "../../page-sekeleton/HtmlMeta";
import { FooterSecure } from "../../page/footer/FooterSecure";
import BasicPageLayout from "../layout/BasicPageLayout";
import styles from "./styles.module.less";
import { useSecureLayout } from "./useSecureLayout";

export interface SecureLayoutProps extends ILayoutBaseProps {
  disableHeader?: boolean;
  disableFooter?: boolean;
  hideDepotNavigation?: boolean;
}

const SecureLayout: React.FunctionComponent<SecureLayoutProps> = props => {
  const [accessToDashboard] = useSecureLayout();

  const [, hintsTopRight] = useHintCollection();

  if (!accessToDashboard)
    return (
      <BasicPageLayout
        headerChildren={
          accessToDashboard && (
            <div className={styles["layout-navigation-container"]}>
              {!props.hideDepotNavigation && (
                <>
                  <DepotTabMenu />
                  <div style={{ maxWidth: 900 }}>{hintsTopRight}</div>
                </>
              )}
            </div>
          )
        }
      >
        <DashboardSkeleton />
      </BasicPageLayout>
    );

  return (
    <BasicPageLayout
      headerChildren={
        accessToDashboard && (
          <div className={styles["layout-navigation-container"]}>
            {!props.hideDepotNavigation && (
              <>
                <DepotTabMenu />{" "}
                <div style={{ maxWidth: 900 }}>{hintsTopRight}</div>
              </>
            )}
          </div>
        )
      }
    >
      <HtmlMeta title="Bitte warten..." />
      <div className={styles["layout-container"]}>
        {accessToDashboard && (
          <props.mainComp
            router={props.routeProps}
            pageProps={props.pageProps}
          />
        )}
      </div>

      {accessToDashboard && <FooterSecure />}
    </BasicPageLayout>
  );
};

export default SecureLayout;
